import Dictionary from "~/ts/library/Dictionary";
import {ConfigStore} from "~/chat/ts/store/Config";
import Cookie from "~/ts/library/Cookie";
import {Intervals} from "~/ts/library/delay/Intervals";
import ApiCallback from "~/chat/ts/service/api/methods/ApiCallback";

let anyWindow = window as any;


class AnalyticCountersSender {
    private yandex: {
        counterId?: string,
        api: {
            reachGoal: (action: string) => void,
            getClientID: () => any
        }
    }[] = [];
    private ga: any[] = [];
    private fbq: any;
    private _gaq: any;
    private gtag: (e: "event", category: string, params: any) => void = null;
    private dataLayer: any[] = null;
    private finteza: (method: "event", eventId: string) => void = null;

    public init() {
        this.findObjects();
        let retry = 0;
        let interval = Intervals.set(() => {
            retry++;
            this.findObjects();
            if (retry > 10) {
                Intervals.clear(interval);
            }
        }, 1000);
    }

    public send(action: string, label?: string, alternativeAction?: string, dontAppendApiMethod?: boolean) {
        let siteParams = ConfigStore.siteParams.value;
        if (siteParams.free) {
            return;
        }
        if (!label) {
            label = action;
        }

        let name = ConfigStore.setup.value.apiMethod;

        let actions: string[] = [
            action
        ];

        if (alternativeAction) {
            actions.push(alternativeAction);
        }

        let nonSpace: string[] = actions.map(action => this.getNonSpace(action));


        if (dontAppendApiMethod) {
            for (let action of actions) {
                nonSpace.push(this.getNonSpace(action, true));
            }
        }

        for (let actionId of [...actions, ...nonSpace]) {
            ApiCallback.emit(actionId);
        }

        for (let action of actions) {
            if (this.ga) {
                try {
                    for (let i = 0; i < this.ga.length; i++) {
                        this.ga[i].send("event", name, action, label, {nonInteraction: true, eventValue: 1});
                    }
                } catch (e) {
                }
            }
            if (this._gaq) {
                try {
                    this._gaq.push(["_trackEvent", name, action, label, 1, "opt_noninteraction"]);
                } catch (e) {
                }
            }
            if (this.gtag) {
                try {
                    this.gtag('event', name, {
                        event_action: action,
                        event_category: name,
                        event_label: label,
                        value: 1,
                        non_interaction: true
                    });
                } catch (e) {
                }
            }


        }
        for (let event of nonSpace) {
            if (event != null) {
                for (let yandex of this.yandex) {
                    try {
                        yandex.api.reachGoal(event);
                    } catch (e) {
                    }
                }

                if (this.finteza) {
                    try {
                        this.finteza("event", event);
                    } catch (e) {
                    }
                }

                if (this.fbq) {
                    try {
                        this.fbq("trackCustom", event, {});
                    } catch (e) {
                    }
                }

                if (this.dataLayer) {
                    this.dataLayer.push({'event': event});
                }
            }
        }
    }

    private initGoogleAnalytics() {
        if (!this.ga.length) {
            try {
                if (anyWindow.ga != null && typeof anyWindow.ga.getAll == "function") {
                    let ga = anyWindow.ga.getAll();
                    let trackingIdList: Dictionary<boolean> = {};
                    for (let i = 0; i < ga.length; i++) {
                        let id = ga[i].get("trackingId");
                        if (trackingIdList[id] == null) {
                            trackingIdList[id] = true;
                            this.ga.push(ga[i]);
                        }
                    }
                }
            } catch (e) {
            }
        }
        if (!this.gtag) {
            if (typeof anyWindow.gtag == "function") {
                this.gtag = anyWindow.gtag;
            }
        }
    }

    private initGtm() {
        if (!this.dataLayer) {
            let dataLayer = anyWindow.dataLayer;
            if (Array.isArray(dataLayer)) {
                this.dataLayer = dataLayer;
            }
        }
    }

    private initOldGoogleAnalytics() {
        if (anyWindow._gaq != null) {
            this._gaq = anyWindow._gaq;
        }
    }

    private initYandex() {
        if (!this.yandex.length) {
            let counterId = null;
            try {
                let counters = anyWindow.Ya._metrika.getCounters();
                if (Array.isArray(counters)) {
                    for (let counter of counters) {
                        if (!counterId && counter.id) {
                            counterId = counter.id;
                        }
                        if (typeof counter.reachGoal == "function") {
                            this.yandex.push(counter);
                        }
                    }
                }
            } catch (e) {
            }
            if (!this.yandex.length) {
                for (let i in anyWindow) {
                    if (anyWindow.hasOwnProperty(i)) {
                        if (i.indexOf("yaCounter") == 0 && anyWindow[i] && typeof anyWindow[i].reachGoal == "function") {
                            let counterId = i.substring("yaCounter".length);
                            this.yandex.push({counterId, api: anyWindow[i]});
                        }
                    }
                }
            }
            if (!this.yandex.length) {
                //обработка для варианта с yclients.com
                let yWidget = anyWindow.yWidget?.counters;
                let counterId = yWidget?.yaCounterId;
                let yaCounter = yWidget?.yaCounter;
                if (counterId && yaCounter && typeof yaCounter.reachGoal == "function") {
                    this.yandex.push({counterId, api: yaCounter});
                }
            }
            if (!this.yandex.length) {
                if (typeof anyWindow.yandex?.metrika?.reachGoal == "function") {
                    this.yandex.push({
                        api: anyWindow.yandex?.metrika,
                        counterId
                    });
                }
            }
        }
    }

    private initFacebook() {
        if (anyWindow.fbq != null && typeof anyWindow.fbq == "function") {
            this.fbq = anyWindow.fbq;
        }
    }

    private initFinteza() {
        if (anyWindow.fz != null && typeof anyWindow.fz == "function") {
            this.finteza = anyWindow.fz;
        }
    }


    get yandexMetrika() {
        return this.yandex;
    }

    private findObjects() {

        let config = ConfigStore.siteParams.value.params.counters;
        if (config.googleAnalytics.enabled) {
            this.initGoogleAnalytics();
            this.initOldGoogleAnalytics();
            this.initGtm();
        }
        if (config.facebookPixel.enabled) {
            this.initFacebook();
        }
        if (config.yandexMetrika.enabled) {
            this.initYandex();
        }
        this.initFinteza();
    }

    private getNonSpace(action: string, dontAppendApiMethod?: boolean) {
        let result = action.split(" ").join("_");
        if (!dontAppendApiMethod) {
            result = ConfigStore.setup.value.apiMethod + "_" + result;
        }
        return result;
    }

    public getRoistatVisit(): string | null {
        let result = Cookie.get("roistat_visit");
        if (!result) {
            try {
                let myWindow = window as any;
                if (myWindow.roistat && typeof myWindow.roistat.getVisit == "function") {
                    result = myWindow.roistat.getVisit();
                    if (result && result.length == 0) {
                        result = null;
                    }
                }
            } catch (e) {

            }
        }
        return result;
    }

    public getDataGoClientId(): string | null {
        let cookies = Cookie.getAll();
        for (let name in cookies) {
            if (cookies.hasOwnProperty(name)) {
                if (name.startsWith("_sp_id.")) {
                    let id = cookies[name].split(".")[0];
                    if (id.length) {
                        return id;
                    }
                }
            }
        }
        return null;
    }
}

const AnalyticCounters = new AnalyticCountersSender();
(window as any).ChatAnalyticCounters = AnalyticCounters;

export default AnalyticCounters;
