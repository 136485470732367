import ChatApiRequest from "~/chat/ts/service/request/ChatApiRequest";
import MessageSenderResult from "~/chat/ts/service/messages/MessageSenderResult";
import MessageStorage from "~/chat/ts/service/messages/MessageStorage";
import IMessage, {MESSAGE_TYPE_AUTO_MESSAGE} from "~/chat/ts/data/chat/IMessage";
import Random from "~/ts/library/Random";
import {ConfigStore} from "~/chat/ts/store/Config";
import {CONTACTS_AFTER_START, CONTACTS_BEFORE_START, WIDGET_OFFLINE_MODE_FORM} from "~/chat/ts/data/ISiteParams";
import {ChatTabStore} from "~/chat/ts/store/ChatTab";
import {OperatorsStore} from "~/chat/ts/store/Operators";
import {
    ACTION_FILTER_AUTO_MESSAGE_TO_CLIENT_AT_VISIT,
    ACTION_FILTER_CLIENT_MESSAGES_COUNT_AT_ALL_TIME,
    ACTION_FILTER_CLIENT_MESSAGES_COUNT_AT_VISIT,
    ACTION_FILTER_OPERATOR_MESSAGES_COUNT_AT_VISIT
} from "~/chat/ts/data/AutoActions";
import Filters from "~/chat/ts/service/autoActions/Filters";
import {CHAT_SEND_MESSAGE_BY_CLIENT, FORM_TYPE_BEFORE_CHAT_START, FORM_TYPE_LEAD_GEN} from "~/chat/ts/Constants";
import MessageWrapper from "~/chat/ts/data/chat/MessageWrapper";
import ChatApiResult from "~/chat/ts/service/request/ChatApiResult";
import ChatDateHelper from "~/chat/ts/service/ChatDateHelper";
import AnalyticCounters from "~/chat/ts/service/AnalyticCounters";
import {__} from "~/ts/library/Translate";
import ApiCallback, {
    API_CALLBACK_CLIENT_SEND_MESSAGE,
    IApiCallbackClientSendMessagePayload
} from "~/chat/ts/service/api/methods/ApiCallback";
import {ChatEventManagerInstance} from "~/chat/ts/ChatEventManager";


export default class MessageSender {
    private static isSelectReceiversAtFirstMessageEnabled = true;
    private message: IMessage;
    private byApi = false;
    private runBotSchemeId: string;

    constructor(message: IMessage) {
        this.message = message;
    }

    setRunBotSchemeId(schemeId: string) {
        this.runBotSchemeId = schemeId;
        return this;
    }

    public static setSelectReceiversAtFirstMessageEnabled(value: boolean) {
        this.isSelectReceiversAtFirstMessageEnabled = value;
    }

    private validate(): string | void {
        let wrapper = new MessageWrapper(this.message);
        if ((wrapper.text && wrapper.text.length) || wrapper.attachments.length || wrapper.stickerUrl) {

        } else {
            return "Не задан текст сообщения";
        }
    }

    setSendByApi(value: boolean) {
        this.byApi = value;
        return this;
    }

    public static prepareNewMessage(message: IMessage) {
        message.key = Random.uid(16);
        message.dt = (new ChatDateHelper(new Date())).toMysqlFormat();
    }

    public static get isNeedToAskForOperator(): boolean {
        let result = !ChatTabStore.isDialogRunning.value && this.isSelectReceiversAtFirstMessageEnabled;
        if (OperatorsStore.isSelectOperatorsAvailable.value && OperatorsStore.balanceType.value.dontSelectOperatorOnFirstMessage) {
            result = false;
        }
        return result;
    }

    private remoteTtlFromAutoMessage(): void {
        let messageIdList = MessageStorage.messageIdList;
        for (let i = messageIdList.length - 1; i >= 0; i--) {
            let messageId = messageIdList[i];
            let message = MessageStorage.getMessage(messageId);
            if (message) {
                if (message?.messageType == MESSAGE_TYPE_AUTO_MESSAGE) {
                    if (message.other?.ttlSeconds) {
                        delete message.other.ttlSeconds;
                        MessageStorage.saveMessage(message);
                    }
                } else if (!message.messageType) {
                    break;
                }
            }
        }
    }

    private prepareAnalyticCounterSend(): () => void {
        let firstMessageOfVisit = Filters.get(ACTION_FILTER_CLIENT_MESSAGES_COUNT_AT_VISIT, 0) === 0;
        let firstMessageOfAllTime = Filters.get(ACTION_FILTER_CLIENT_MESSAGES_COUNT_AT_ALL_TIME, 0) === 0;
        let operatorMessagesCountAtVisit = Filters.get(ACTION_FILTER_OPERATOR_MESSAGES_COUNT_AT_VISIT, 0);
        let autoMessageToClientCountAtVisit = Filters.get(ACTION_FILTER_AUTO_MESSAGE_TO_CLIENT_AT_VISIT, 0);

        return () => {
            AnalyticCounters.send("Client send message", __("EVENT_CLIENT_SEND_MESSAGE"));
            if (firstMessageOfVisit) {
                let action = "Client initiate chat";
                AnalyticCounters.send("Client sent first message of visit", "Клиент отправил первое сообщение за визит");
                if (firstMessageOfAllTime) {
                    AnalyticCounters.send("Client sent first message of all time", "Клиент отправил первое сообщение за все время");
                }
                let label = __("EVENT_CLIENT_INITIATE_DIALOG");
                if (operatorMessagesCountAtVisit) {
                    action = "Client answer on operator request";
                    label = __("EVENT_CLIENT_ANSWER_TO_OPERATOR");
                } else if (autoMessageToClientCountAtVisit) {
                    action = "Client answer on automessage";
                    label = __("EVENT_CLIENT_ANSWER_ON_AUTOMESSAGE");
                }

                AnalyticCounters.send(action, label);
            }
        };
    }

    private static getVisibleGroups() {
        return OperatorsStore.visibleGroupsForCurrentMode.value.map(group => group.id);
    }

    private sendCallbackToJavascriptApi(message: MessageWrapper, resultOperatorId: string, isFirstMessage: boolean) {
        if (!this.byApi) {
            try {
                let text = message.text ? message.text : "";
                for (let attachment of message.attachments) {
                    if (text.length) {
                        text += "\n";
                    }
                    text += `${attachment.url} (${attachment.fileName})`;
                }
                let payload: IApiCallbackClientSendMessagePayload = {
                    text: text,
                    operatorId: resultOperatorId,
                    firstMessage: isFirstMessage
                };
                ApiCallback.emit(API_CALLBACK_CLIENT_SEND_MESSAGE, payload);
            } catch (e) {

            }
        }
    }

    //Прячем всех операторов, у которых есть отдел, если выбран виртуальный отдел
    //Убрал после вопроса http://cp.sms-uslugi.ru:8088/my#questions/list?id=178868_1666684582/dialog
    /*
    private static getHiddenOperators(group?: IOperatorGroup) {
        let hiddenOperators = [...OperatorsStore.invisibleOperatorsLogins];
        if (group && group.virtual) {
            if (group.id == GROUP_ID_FOR_OPERATORS_WITHOUT_GROUP) {
                for (let operator of OperatorsStore.operatorsList) {
                    if (hiddenOperators.indexOf(operator.username) === -1) {
                        if (OperatorsStore.getAllGroupsOfOperator(operator)[0] != group) {
                            hiddenOperators.push(operator.username);
                        }
                    }
                }
            }
        }
        return hiddenOperators;
    }
     */

    /*
        private static get isFirstMessageAtThisVisit(): boolean {
            return Filters.check(ACTION_FILTER_MESSAGES_COUNT_AT_VISIT, OPERATION_EQUAL, [0]);
        }
     */

    /*
    private static get params() {
        return ConfigStore.siteParams.value.params;
    }
    */

    public async send(beforeSend?: () => void) {
        MessageStorage.markAllMessagesAsReaded();
        let error = this.validate();
        if (error) {
            return new ChatApiResult({
                code: 0, descr: error
            });
        } else {
            let analyticQuerySend = this.prepareAnalyticCounterSend();

            if (!this.message.key) {
                MessageSender.prepareNewMessage(this.message);
            }


            let isInsertIntoStorage = !this.message.messageType;
            let sendEcho = isInsertIntoStorage;

            let collectContactsMode = ChatTabStore.dialogStartForm.value.collectContactsMode;
            let isFirstMessage = !ChatTabStore.isDialogRunning.value;//MessageSender.isFirstMessageAtThisVisit;
            let isNeedToAskContacts = isFirstMessage;
            let isNeedToAskForOperator = MessageSender.isNeedToAskForOperator;

            let disallowAskContactsNow = !!this.byApi;
            if (disallowAskContactsNow) {
                isNeedToAskContacts = false;
                isNeedToAskForOperator = false;
            }

            let askContactsBeforeChat = false;


            if (isNeedToAskForOperator) {
                //Сбрасываем выбранную в предыдущий визит группу, чтобы пользователь выбирал заново и мог выбрать любую категорию обращения
                OperatorsStore.deselectGroup();
                await ChatTabStore.showOperatorsSelect();
            }


            if (isNeedToAskContacts) {
                if (ChatTabStore.chatTabMode.value.allowAskContacts) {
                    let isOffline = OperatorsStore.isSelectedGroupOffline.value && ConfigStore.siteParams.value.params.leadGen.mode == WIDGET_OFFLINE_MODE_FORM;
                    if (collectContactsMode == CONTACTS_BEFORE_START || isOffline) {
                        askContactsBeforeChat = true;
                        await ChatTabStore.askForContactsBeforeStart({formType: isOffline ? FORM_TYPE_LEAD_GEN : FORM_TYPE_BEFORE_CHAT_START});
                    } else {
                        await ChatTabStore.askForQuestionCategory();
                    }
                }
            }

            if (beforeSend != null) {
                beforeSend();
            }

            this.remoteTtlFromAutoMessage();

            if (isInsertIntoStorage) {
                MessageStorage.saveMessage(this.message);
            }

            let recipient = OperatorsStore.selected.value;
            let group = recipient.group;
            //let hiddenOperators = MessageSender.getHiddenOperators(group);

            let messageWrapper = new MessageWrapper(this.message);
            ChatTabStore.setIsDialogRunning(true);
            let result = await ChatApiRequest.make({
                method: "sendMessageToOperator",
                p: {
                    text: this.message.text,
                    operatorId: recipient.operator?.id,
                    selectedGroup: (!group || group.virtual) ? null : group.id,
                    //needBalance: !recipient.operator,
                    balancerDisabled: OperatorsStore.balancerDisabled.value,
                    attachments: messageWrapper.attachments,
                    //hiddenOperators: OperatorsStore.hiddenOperatorsList.value.map(operator => operator.username),
                    hiddenOperatorIds: OperatorsStore.hiddenOperatorsList.value.map(operator => operator.id),
                    stickerId: this.message.other?.sticker?.id,
                    sendEcho: sendEcho,
                    temporaryId: this.message.id,
                    visibleGroups: MessageSender.getVisibleGroups(),
                    url: window.location.href,
                    title: document.title,
                    messageType: this.message.messageType,
                    runBotSchemeId: this.runBotSchemeId,
                    replyToMessageId: this.message.replyToMessage ? this.message.replyToMessage.id : null

                    //TODO::!!!!!!
                    /*
                            messages:$this.count,
                            operatorActivity: chat.operatorAway.getActivity() ? 1 : 0,
                            chatMode:chat.chatMode,
                            forceOfflineModeDelay: forceOfflineModeDelay,
                            systemMessage:false,
                            simulationStarted:chat.onlineSimulation.started ? 1 : 0,
                     */
                }
            });

            let senderResult = new MessageSenderResult(result.data);
            if (senderResult.isSuccess()) {
                let resultOperatorId = result.data.operatorId;
                this.sendCallbackToJavascriptApi(messageWrapper, resultOperatorId, isFirstMessage);
                analyticQuerySend();

                ChatEventManagerInstance.emit(CHAT_SEND_MESSAGE_BY_CLIENT);
                if (isInsertIntoStorage) {
                    MessageStorage.updateMessageId(this.message, senderResult.id);
                }
                if (resultOperatorId) {
                    let operator = OperatorsStore.getOperatorById(resultOperatorId);
                    if (operator) {
                        this.message.to = operator.id;
                        this.message.operatorDescr = operator.fio;

                        OperatorsStore.setSelectedOperator(operator)
                    }
                }
                if (senderResult.widgetData?.clientMessageCountAtDialog === 1) {
                    if (!disallowAskContactsNow) {
                        isNeedToAskContacts = true;
                    }
                }
                if (isNeedToAskContacts) {
                    if (!senderResult.widgetData?.isBotRunning) {
                        if (ChatTabStore.chatTabMode.value.allowAskContacts) {
                            if (collectContactsMode == CONTACTS_AFTER_START && !askContactsBeforeChat) {
                                ChatTabStore.askForContactsAfterStart();
                            }
                        }
                    }
                }
                if (!this.message.messageType) {
                    if (!senderResult.widgetData?.isBotRunning) {
                        ChatTabStore.showRateRequestAfterNewMessage(MessageStorage.lastMessageId ?? 0);
                        if (ChatTabStore.chatTabMode.value.isKeepOnlineMode) {
                            ChatTabStore.showKeepOnlineMessageInHistoryAfterMessage(MessageStorage.lastMessageId ?? 0);
                        }
                    }
                    MessageStorage.afterNewRealMessage(messageWrapper);
                }
            } else {
                MessageStorage.removeMessage(this.message.id);
            }

            return senderResult;
        }
    }
}
