import AbstractAutoAction, {IAutoActionSuccessRunResult} from "~/chat/ts/service/autoActions/actions/AbstractAutoAction";
import Destroy from "~/chat/ts/service/api/methods/Destroy";
import {AutoActionModel} from "~/chat/ts/data/AutoActions";

export default class DestroyWidget extends AbstractAutoAction<AutoActionModel> {
    async run(): Promise<IAutoActionSuccessRunResult[]> {
        Destroy();
        return [{}];
    }

    isNeedSendResultToServer(): boolean {
        return true;
    }

    get isNoize(): boolean {
        return false;
    }

    isCanRunNow(): boolean {
        return true;
    }
}