let supportPunycode = new function () {
    function l(e) {
        return e - 48 < 10 ? e - 22 : e - 65 < 26 ? e - 65 : e - 97 < 26 ? e - 97 : i
    }

    function c(e, t) {
        return e + 22 + 75 * (e < 26) - ((t != 0) << 5)
    }

    function h(e, t, n) {
        var r;
        e = n ? Math.floor(e / s) : e >> 1;
        e += Math.floor(e / t);
        for (r = 0; e > (i - o) * u >> 1; r += i) {
            e = Math.floor(e / (i - o))
        }
        return Math.floor(r + (i - o + 1) * e / (e + a))
    }

    function p(e, t) {
        e -= (e - 97 < 26) << 5;
        return e + ((!t && e - 65 < 26) << 5)
    }

    this.utf16 = {
        decode: function (e) {
            var t = [],
                n = 0,
                r = e.length,
                i, s;
            while (n < r) {
                i = e.charCodeAt(n++);
                if ((i & 63488) === 55296) {
                    s = e.charCodeAt(n++);
                    if ((i & 64512) !== 55296 || (s & 64512) !== 56320) {
                        throw new RangeError("UTF-16(decode): Illegal UTF-16 sequence")
                    }
                    i = ((i & 1023) << 10) + (s & 1023) + 65536
                }
                t.push(i)
            }
            return t
        },
        encode: function (e) {
            var t = [],
                n = 0,
                r = e.length,
                i;
            while (n < r) {
                i = e[n++];
                if ((i & 63488) === 55296) {
                    throw new RangeError("UTF-16(encode): Illegal UTF-16 value")
                }
                if (i > 65535) {
                    i -= 65536;
                    t.push(String.fromCharCode(i >>> 10 & 1023 | 55296));
                    i = 56320 | i & 1023
                }
                t.push(String.fromCharCode(i))
            }
            return t.join("")
        }
    };
    var t = 128;
    var n = 72;
    var r = "-";
    var i = 36;
    var s = 700;
    var o = 1;
    var u = 26;
    var a = 38;
    var f = 2147483647;
    this.decode = function (e, s) {
        var a = [];
        var c = [];
        var p = e.length;
        var d, v, m, g, y, b, w, E, S, x, T, N, C;
        d = t;
        m = 0;
        g = n;
        y = e.lastIndexOf(r);
        if (y < 0) y = 0;
        for (b = 0; b < y; ++b) {
            if (s) c[a.length] = e.charCodeAt(b) - 65 < 26;
            if (e.charCodeAt(b) >= 128) {
                throw new RangeError("Illegal input >= 0x80")
            }
            a.push(e.charCodeAt(b))
        }
        for (w = y > 0 ? y + 1 : 0; w < p;) {
            for (E = m, S = 1, x = i; ; x += i) {
                if (w >= p) {
                    throw RangeError("punycode_bad_input(1)")
                }
                T = l(e.charCodeAt(w++));
                if (T >= i) {
                    throw RangeError("punycode_bad_input(2)")
                }
                if (T > Math.floor((f - m) / S)) {
                    throw RangeError("punycode_overflow(1)")
                }
                m += T * S;
                N = x <= g ? o : x >= g + u ? u : x - g;
                if (T < N) {
                    break
                }
                if (S > Math.floor(f / (i - N))) {
                    throw RangeError("punycode_overflow(2)")
                }
                S *= i - N
            }
            v = a.length + 1;
            g = h(m - E, v, E === 0);
            if (Math.floor(m / v) > f - d) {
                throw RangeError("punycode_overflow(3)")
            }
            d += Math.floor(m / v);
            m %= v;
            if (s) {
                c.splice(m, 0, e.charCodeAt(w - 1) - 65 < 26)
            }
            a.splice(m, 0, d);
            m++
        }
        if (s) {
            for (m = 0, C = a.length; m < C; m++) {
                if (c[m]) {
                    a[m] = String.fromCharCode(a[m]).toUpperCase().charCodeAt(0)
                }
            }
        }
        return this.utf16.encode(a)
    };
    this.encode = function (e, s) {
        var a, l, d, v, m, g, y, b, w, E, S, x;
        if (s) {
            x = this.utf16.decode(e)
        }
        e = this.utf16.decode(e.toLowerCase());
        var T = e.length;
        if (s) {
            for (g = 0; g < T; g++) {
                x[g] = e[g] != x[g]
            }
        }
        var N = [];
        a = t;
        l = 0;
        m = n;
        for (g = 0; g < T; ++g) {
            if (e[g] < 128) {
                N.push(String.fromCharCode(x ? p(e[g], x[g]) : e[g]))
            }
        }
        d = v = N.length;
        if (v > 0) N.push(r);
        while (d < T) {
            for (y = f, g = 0; g < T; ++g) {
                S = e[g];
                if (S >= a && S < y) y = S
            }
            if (y - a > Math.floor((f - l) / (d + 1))) {
                throw RangeError("punycode_overflow (1)")
            }
            l += (y - a) * (d + 1);
            a = y;
            for (g = 0; g < T; ++g) {
                S = e[g];
                if (S < a) {
                    if (++l > f) return Error("punycode_overflow(2)")
                }
                if (S == a) {
                    for (b = l, w = i; ; w += i) {
                        E = w <= m ? o : w >= m + u ? u : w - m;
                        if (b < E) break;
                        N.push(String.fromCharCode(c(E + (b - E) % (i - E), 0)));
                        b = Math.floor((b - E) / (i - E))
                    }
                    N.push(String.fromCharCode(c(b, s && x[g] ? 1 : 0)));
                    m = h(l, d + 1, d == v);
                    l = 0;
                    ++d
                }
            }
            ++l, ++a
        }
        return N.join("")
    };
    this.ToASCII = function (e) {
        var t = e.split(".");
        var n = [];
        for (var r = 0; r < t.length; ++r) {
            var i = t[r];
            n.push(i.match(/[^A-Za-z0-9-]/) ? "xn--" + supportPunycode.encode(i) : i)
        }
        return n.join(".")
    };
    this.ToUnicode = function (e) {
        var t = e.split(".");
        var n = [];
        for (var r = 0; r < t.length; ++r) {
            var i = t[r];
            n.push(i.match(/^xn--/) ? supportPunycode.decode(i.slice(4)) : i)
        }
        return n.join(".")
    }
};

export default {
    toAscii: supportPunycode.ToASCII,
    toUnicode: supportPunycode.ToUnicode
};