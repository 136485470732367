import punycode from "./PunycodeLibrary.js";

class Punycode {
    public static toAscii(value: string): string {
        return punycode.toAscii(value);
    }

    public static toUnicode(value: string): string {
        return punycode.toUnicode(value);
    }
}

export default Punycode;