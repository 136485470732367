import GetRequest from "../../xhr/GetRequest";
import ListenerMessage from "../ListenerMessage";
import RealChannel from "./RealChannel";

class LongPollingChannel extends RealChannel {

    get typeId(): string {
        return "longPolling";
    }

    async startListen(): Promise<void> {
        let url = this.url;
        let random = (new Date()).getTime().toString();
        let time = this.lastMessageTimestamp.toString();

        url += ((url.indexOf("?") === -1) ? "?" : "&") + `time=${time}&r=${random}`;

        try {
            let result = await (new GetRequest(url))
                .setTimeout(30000)
                .send();

            let data = result.getRawResponse();
            let xhr = result.getXhr();
            let previousTime: any = xhr.getResponseHeader("X-Previous-Time");
            let lastModified = parseInt(xhr.getResponseHeader("Last-Modified"));

            previousTime = previousTime ? parseInt(previousTime) : null;
            if (data != null && data.length) {
                let lines = data.split("\n");
                let newData: any[] = [];
                for (let i = 0; i < lines.length; i++) {
                    let line = lines[i];
                    try {
                        if (line.indexOf('[') === 0) {
                            newData = newData.concat(JSON.parse(line));
                        } else {
                            newData.push(JSON.parse(line));
                        }
                    } catch (e) {
                        /*
                        try {
                            //TODO: это для дебага. После отлова баги - желательно не забыть удалить этот мусор из кода
                            ChatSentryLogger.captureMessage(`Cant parse line from long polling: ${JSON.stringify(line)}. ${e}`);
                        } catch (e) {

                        }
                         */
                    }
                }

                let length = newData.length;
                if (length) {
                    let serverTime: any = xhr.getResponseHeader("X-Server-Time");
                    if (lastModified) {
                        this.setLastMessageTimeStamp(this.uid, lastModified, data, previousTime);
                    }
                    for (let i = 0; i < length; i++) {
                        let text = newData[i];
                        this.receiveMessage(
                            new ListenerMessage(
                                text,
                                parseInt(text.time),
                                previousTime,
                                serverTime ? parseInt(serverTime) : null
                            )
                        );
                    }
                }
            } else {
                this.checkPreviousMessageTimeStamp(this.uid, previousTime, lastModified, null);
            }
            this.close(false);
        } catch (e) {
            this.close(true);
            throw e;
        }

    }

}

export default LongPollingChannel;