import {ConfigStore} from "~/chat/ts/store/Config";
import AbstractListenerAction from "~/ts/library/listen/AbstractListenerAction";

export default class RedirectListener extends AbstractListenerAction {
    public getAction(): string {
        return "redirect";
    }

    public onAction(payload: { url: string, widgetInstanceId: string }): void {
        if (payload.widgetInstanceId == ConfigStore.instanceId.value) {
            window.location.href = payload.url;
        }
    }
}