import AbstractAutoAction, {
    IAutoActionSuccessRunResult
} from "~/chat/ts/service/autoActions/actions/AbstractAutoAction";
import {AutoActionModel} from "~/chat/ts/data/AutoActions";
import {ConfigStore} from "~/chat/ts/store/Config";
import {IWidgetTabParams} from "~/chat/ts/data/ITab";

export default abstract class AbstractTabAction extends AbstractAutoAction<IAbstractTabActionParam>{
    async run(): Promise<IAutoActionSuccessRunResult[]> {
        for (let tab of this.tabsToChange) {
            ConfigStore.TOGGLE_TAB_HIDDEN({
                tabId: tab.id,
                value: this.value
            })
        }
        return [{}];
    }

    get isNoize(): boolean {
        return false;
    }

    private get tabsToChange(): IWidgetTabParams[] {
        let result = [];
        for (let tabId of this.params.tabID) {
            let tab = ConfigStore.tabsDictionary.value[tabId];
            if (tab) {
                let hidden = !tab.visible;
                if (hidden != this.value) {
                    result.push(tab);
                }
            }
        }
        return result;
    }

    isCanRunNow(): boolean {
        return this.tabsToChange.length > 0;
    }

    protected abstract get value(): boolean;
}

type IAbstractTabActionParam = AutoActionModel & { tabID: string[] }