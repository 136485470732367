import Dictionary from "~/ts/library/Dictionary";
import {
    StartDialogFormConfigInterface,
    WIDGET_OFFLINE_MODE_BOT,
    WIDGET_OFFLINE_MODE_FORM,
    WIDGET_OFFLINE_MODE_HIDE,
    WIDGET_OFFLINE_MODE_HTML,
    WIDGET_OFFLINE_MODE_KEEP_ONLINE,
    WIDGET_OFFLINE_MODE_REPLACE_TAB,
    WIDGET_OFFLINE_MODE_SIMULATION,
    WIDGET_ONLINE_BOT_MODE,
    WIDGET_ONLINE_MODE
} from "~/chat/ts/data/ISiteParams";
import {ConfigStore} from "~/chat/ts/store/Config";

export const ChatTabModes: Dictionary<IChatTabMode> = {
    [WIDGET_ONLINE_MODE]: {
        allowSelectOperatorsInSideMenu: true,
        allowExportDialog: true,
        allowFileDragOverlay: true,
        allowAskContacts: true
    },
    [WIDGET_OFFLINE_MODE_HTML]: {
        useOfflineTabTitle: true,
        showOfflineHtmlContentInsteadOfChat: true,
        allowAskContacts: true
    },
    [WIDGET_OFFLINE_MODE_HIDE]: {
        hideWidget: true,
        hideChatTab: true,
        allowAskContacts: true
    },
    [WIDGET_OFFLINE_MODE_FORM]: {
        useOfflineTabTitle: true,
        allowExportDialog: true,
        allowAskContacts: true
    },
    [WIDGET_OFFLINE_MODE_SIMULATION]: {
        allowExportDialog: true,
        allowFileDragOverlay: true,
        showFirstOperatorWhenNoneIsSelected: true,
        allowAskContacts: true
    },
    [WIDGET_ONLINE_BOT_MODE]: {
        allowSelectOperatorsInSideMenu: true,
        allowExportDialog: true,
        allowFileDragOverlay: true,
        allowAskContacts: false
    },
    [WIDGET_OFFLINE_MODE_BOT]: {
        allowExportDialog: true,
        allowFileDragOverlay: true,
        showFirstOperatorWhenNoneIsSelected: true,
        allowAskContacts: false,
        bot: true
    },
    [WIDGET_OFFLINE_MODE_REPLACE_TAB]: {
        replaceChatTab: true,
        useOfflineTabTitle: true,
        allowAskContacts: true
    },
    [WIDGET_OFFLINE_MODE_KEEP_ONLINE]: {
        allowExportDialog: true,
        allowFileDragOverlay: true,
        allowAskContacts: true,
        useOfflineTabTitle: true,
        getStartDialogForm: () => {
            return ConfigStore.siteParams.value.params.leadGen.startDialogFormConfig;
        },
        isKeepOnlineMode: true
    }
};


export interface IChatTabMode {
    allowAskContacts: boolean,
    hideWidget?: boolean,
    useOfflineTabTitle?: boolean,
    hideChatTab?: boolean,
    allowSelectOperatorsInSideMenu?: boolean,
    allowExportDialog?: boolean,
    showOfflineHtmlContentInsteadOfChat?: boolean,
    allowFileDragOverlay?: boolean,
    showFirstOperatorWhenNoneIsSelected?: boolean,
    replaceChatTab?: boolean,
    bot?: boolean,
    getStartDialogForm?: () => StartDialogFormConfigInterface,
    isKeepOnlineMode?: boolean
}