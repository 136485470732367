import AbstractListenChannel from "../AbstractListenChannel";
import ListenerMessage from "../ListenerMessage";
import CrossTabMessage from "../../crossTabCommunication/talk/CrossTabMessage";
import CrossTabSender from "../../crossTabCommunication/talk/CrossTabSender";

const COMET_CACHE_KEY = "CacheChannel";

let started = false;
let currentTimestamp = 0;

class CacheChannel extends AbstractListenChannel {
    private crossTabMessageCallback: (payload: { originalMessage: any, time: number }) => void;


    get typeId(): string {
        return "cache";
    }

    get cometCacheKey() {
        return CacheChannel.getCometCacheKey(this.uid);
    }

    constructor(uid: string) {
        super(uid);
        this.crossTabMessageCallback = (payload: ListenerMessage) => {
            if (!payload.time || payload.time > currentTimestamp) {
                this.receiveMessage(
                    new ListenerMessage(
                        payload.originalMessage,
                        payload.time,
                        payload.previousTime,
                        payload.serverTime
                    ));
            }
        };

        CrossTabSender.setMessageCallback(this.cometCacheKey, this.crossTabMessageCallback);
    }

    protected setLastMessageTimeStamp(uid: string, timestamp: number, message: any, previousTimestamp: number): void {
        super.setLastMessageTimeStamp(uid, timestamp, message, previousTimestamp);
        if (timestamp) {
            currentTimestamp = timestamp;
        }
    }

    async startListen(): Promise<void> {
        started = true;
    }

    public close(error: boolean) {
        CrossTabSender.removeMessageCallback(this.cometCacheKey, this.crossTabMessageCallback);
        super.close(error);
    }

    private static getCometCacheKey(uid: string) {
        return COMET_CACHE_KEY + "/" + uid;
    }

    public static writeMessageToCache(uid: string, message: ListenerMessage) {
        let newMessage = new ListenerMessage(
            message.originalMessage,
            message.time,// + 1, а зачем тут + 1? :(
            message.previousTime,
            message.serverTime
        );
        CrossTabSender.send(new CrossTabMessage(CacheChannel.getCometCacheKey(uid), newMessage));
    }


}

export default CacheChannel;