import AbstractAutoAction, {
    IAutoActionSuccessRunResult
} from "~/chat/ts/service/autoActions/actions/AbstractAutoAction";
import {AutoActionModel} from "~/chat/ts/data/AutoActions";
import startBot from "~/chat/ts/service/api/methods/StartBot";


export default class StartBot extends AbstractAutoAction<StartBotParams> {
    get isNoize(): boolean {
        return true;
    }

    isNeedSendResultToServer(): boolean {
        return true;
    }

    isCanRunNow(): boolean {
        return true;
    }

    async run(): Promise<IAutoActionSuccessRunResult[]> {
        let result: IAutoActionSuccessRunResult[] = [];
        if (this.params.botId) {
            let startResult = await startBot(this.params.botId, false);
            if (startResult.isSuccess()) {
                result.push({})
            }
        }
        return result;
    }
}

type StartBotParams = AutoActionModel & {
    botId: string;
}
