import {ClientStore} from "~/chat/ts/store/Client";
import {ConfigStore} from "~/chat/ts/store/Config";

export default function getClientIdentifier(callback: (data: any) => void) {
    let siteParams = ConfigStore.siteParams.value
    callback({
        siteId: siteParams.id,
        orgId: siteParams.orgID,
        talkId: ClientStore.id.value
    });
}