import {ConfigStore} from "~/chat/ts/store/Config";
import showTab from "~/chat/ts/service/api/methods/ShowTab";
import AbstractListenerAction from "~/ts/library/listen/AbstractListenerAction";

export default class OpenWidgetTabListener extends AbstractListenerAction {
    public getAction(): string {
        return "openWidgetTab";
    }

    public onAction(payload: {
        tabId: string,
        widgetInstanceId?: string,
        additionalParams: any
    }): void {
        let instanceId = payload.widgetInstanceId;
        if (!instanceId || instanceId == ConfigStore.instanceId.value) {
            showTab({id: payload.tabId, ...payload.additionalParams});
        }
    }
}