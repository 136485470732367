import {OperatorsStore} from "~/chat/ts/store/Operators";
import {computed, watch} from "vue";

export default function listenOperatorsStatus(callback: (logins: string[]) => void) {
    let sendOperators = () => {
        if (OperatorsStore.isStatusInitialized.value) {
            callback(OperatorsStore.visibleOperators.value.map(operator => operator.id));
        }
    };
    watch(computed(() => {
        return OperatorsStore.isStatusInitialized.value && OperatorsStore.visibleOperators.value;
    }), sendOperators);

    sendOperators();
}