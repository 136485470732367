import AbstractAutoAction, {
    IAutoActionSuccessRunResult
} from "~/chat/ts/service/autoActions/actions/AbstractAutoAction";
import {AutoActionModel} from "~/chat/ts/data/AutoActions";
import {OperatorsStore} from "~/chat/ts/store/Operators";
import {ConfigStore} from "~/chat/ts/store/Config";
import {IOperatorGroup} from "~/chat/ts/data/IOperator";

export default abstract class AbstractOperatorGroupAction extends AbstractAutoAction<IAbstractOperatorGroupActionParam> {
    async run(): Promise<IAutoActionSuccessRunResult[]> {
        for (let group of this.groupsToChange) {
            ConfigStore.TOGGLE_OPERATORS_GROUP_HIDDEN({
                groupId: group.id,
                value: this.value
            });
        }
        return [{}];
    }

    get isNoize(): boolean {
        return false;
    }

    private get groupsToChange(): IOperatorGroup[] {
        let result = [];
        for (let groupId of this.params.groupID) {
            let group = OperatorsStore.groups.value[groupId];
            if (group && group.hidden != this.value) {
                result.push(group);
            }
        }
        return result;
    }

    isCanRunNow(): boolean {
        return this.groupsToChange.length > 0;
    }

    protected abstract get value(): boolean;
}

type IAbstractOperatorGroupActionParam = AutoActionModel & { groupID: string[] }