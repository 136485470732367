import {OperatorsStore} from "~/chat/ts/store/Operators";
import MessageSender from "~/chat/ts/service/messages/MessageSender";

export default function setOperator(operatorLoginOrId?: string, groupId?: string) {
    let operator = operatorLoginOrId ? OperatorsStore.getOperatorByIdOrLogin(operatorLoginOrId) : null;
    let group = OperatorsStore.groups.value[groupId];
    if (operator || group) {
        OperatorsStore.setSelectedOperator(operator, group);
        if (operator) {
            if (OperatorsStore.isOperatorOnline(operator)) {
                OperatorsStore.SET_BALANCER_DISABLED(true);
            }
        }
        MessageSender.setSelectReceiversAtFirstMessageEnabled(false);
    }
}