import {
    AUTO_ACTION_REPEAT_AFTER_TIME,
    AUTO_ACTION_REPEAT_NEVER,
    AUTO_ACTION_REPEAT_NEW_PAGE,
    AUTO_ACTION_REPEAT_NEW_VISIT,
    AutoActionModel,
    IActionSubfilter,
    IAutoActionRepeatTypeConfig,
    IAutoActionType
} from "~/chat/ts/data/AutoActions";
import AutoActionPreparedFilters from "~/chat/ts/service/autoActions/AutoActionPreparedFilters";
import Filters, {AutoActionPreparedFilter} from "~/chat/ts/service/autoActions/Filters";
import AutoActionRunner from "~/chat/ts/service/autoActions/AutoActionRunner";
import {StorageInstance} from "~/ts/library/storage/StorageFactory";
import {ConfigStore} from "~/chat/ts/store/Config";
import Random from "~/ts/library/Random";
import AbstractAutoAction, {
    IAutoActionSuccessRunResult
} from "~/chat/ts/service/autoActions/actions/AbstractAutoAction";
import AutoActionTypeMapper from "~/chat/ts/service/autoActions/actions/AutoActionTypeMapper";
import LocalStorage from "~/ts/library/storage/LocalStorage";
import FiltersCheckResult from "~/chat/ts/service/autoActions/FiltersCheckResult";

export default class AutoActionGroup {
    private actionTypeId: string;
    private actionType: IAutoActionType;
    private filters: AutoActionPreparedFilters;
    private isRunAtThisPage = false;
    private actions: AbstractAutoAction<AutoActionModel>[];
    private subFilter: IActionSubfilter;

    constructor(subFilter: IActionSubfilter, actionTypeId: string, actionType: IAutoActionType, filters: AutoActionPreparedFilters) {
        this.subFilter = subFilter;
        this.actions = [];
        this.actionTypeId = actionTypeId;

        let autoActionClass = AutoActionTypeMapper.find(actionTypeId);
        if (autoActionClass) {
            this.actionType = actionType;
            this.filters = filters;
            for (let action of Random.shuffle(this.actionType.actions)) {
                this.actions.push(new autoActionClass(action));
            }
            this.startListen();
            this.addToRunner();
        }
    }

    getActionTypeId(): string {
        return this.actionTypeId;
    }

    private startListen() {
        for (let filterId of this.filters.getFilterIdList()) {
            Filters.addFilterListener(filterId, () => {
                this.addToRunner();
            });
        }
    }

    private isCanRunNow(runId?: string): boolean {
        return this.checkRepeat() && this.checkFilters(runId);
    }

    private getActionsAvailableToRun() {
        return this.actions.filter(action => {
            if (action.isCanRunNow()) {
                if ((!StorageInstance.isErrorOnSet() && StorageInstance instanceof LocalStorage) || !action.isNoize) {
                    return true;
                }
            }
            return false;
        });
    }

    private addToRunner() {
        if (this.isCanRunNow(null)) {
            if (this.getActionsAvailableToRun().length) {
                AutoActionRunner.add(this);
            }
        }
    }

    public async run(runId: string): Promise<IAutoActionGroupSuccessRunResult | null> {
        if (this.isCanRunNow(runId)) {
            for (let action of this.getActionsAvailableToRun()) {
                let result = await action.run();
                if (result.length) {
                    this.setLastRun();
                    if (action.isNeedSendResultToServer()) {
                        return {
                            actionId: action.id,
                            actionType: this.actionTypeId,
                            results: result
                        };
                    } else {
                        return null;
                    }
                }
            }
        }
        return null;
    }

    private checkFilters(runId?: string): boolean {
        if (typeof runId == "string" && this.subFilter.lastSuccessfulFilterCheckRunId === runId) {
            return true;
        }
        let result = false;
        if (AutoActionGroup.isMatch(this.filters.mustMatch).isSuccess) {
            result = true;
            for (let mustNotMatch of this.filters.mustNotMatch) {
                let mustNotMatchResult = AutoActionGroup.isMatch(mustNotMatch);
                if (mustNotMatchResult.isSuccess || mustNotMatchResult.isEmptyFilterError) {
                    result = false;
                    break;
                }
            }
        }
        if (result && typeof runId == "string") {
            this.subFilter.lastSuccessfulFilterCheckRunId = runId;
        }
        return result;
    }

    public static isMatch(filters: AutoActionPreparedFilter[]): FiltersCheckResult {
        let result = filters.length == 0;
        if (!result) {
            result = true;
            for (let filter of filters) {
                let filterCheckResult = filter.check();
                if (!filterCheckResult.isSuccess) {
                    return filterCheckResult;
                }
            }
        }
        return new FiltersCheckResult(result);
    }


    private checkRepeat(): boolean {
        let result: boolean;

        let repeat = this.actionType.repeat;
        if (repeat) {
            let repeatType = repeat[0];
            let repeatParams = repeat[1];
            let lastRun = this.getLastRun();
            let checkRepeatNewPage = this.checkRepeatNewPage();
            if (repeatType == AUTO_ACTION_REPEAT_NEW_PAGE) {
                result = checkRepeatNewPage;
            } else if (!checkRepeatNewPage && [AUTO_ACTION_REPEAT_NEW_VISIT, AUTO_ACTION_REPEAT_NEVER].indexOf(repeatType) > -1) {
                result = checkRepeatNewPage;
            } else {
                result = lastRun == null;
                if (!result) {
                    if (repeatType == AUTO_ACTION_REPEAT_AFTER_TIME) {
                        result = this.checkRepeatAfterTime(lastRun, repeatParams);
                    } else if (repeatType == AUTO_ACTION_REPEAT_NEW_VISIT) {
                        result = this.checkRepeatNewVisit(lastRun);
                    }
                }
            }
        } else {
            result = true;
        }
        return result;
    }

    private checkRepeatAfterTime(lastRun: IAutoActionLastRun, repeatParams: IAutoActionRepeatTypeConfig): boolean {
        let result = false;

        if (repeatParams && repeatParams.repeatSeconds) {
            let seconds = Filters.secondsToInt(repeatParams.repeatSeconds);
            result = lastRun.time + seconds * 1000 < Date.now();
        }
        return result;
    }

    private checkRepeatNewPage(): boolean {
        return !this.isRunAtThisPage;
    }

    private checkRepeatNewVisit(lastRun: IAutoActionLastRun): boolean {
        return ConfigStore.visitId.value != lastRun.visitId/* && !this.checkRepeatNewPage()*/;
    }

    private getLastRun(): IAutoActionLastRun | null {
        let result = StorageInstance.get(this.getLastRunKey(), true);
        if (typeof result == "object") {
            return result;
        }
        return null;
    }

    private setLastRun() {
        let lastRun: IAutoActionLastRun = {
            time: Date.now(),
            visitId: ConfigStore.visitId.value
        };
        this.isRunAtThisPage = true;
        StorageInstance.set(this.getLastRunKey(), lastRun);
    }

    private getLastRunKey(): string {
        return "autoAction/run/" + this.actionType.id;
    }
}

interface IAutoActionLastRun {
    time: number,
    visitId: string
}

export interface IAutoActionGroupSuccessRunResult {
    actionId: string,
    actionType: string,
    results: IAutoActionSuccessRunResult[]
}