import {AutoActionModel} from "~/chat/ts/data/AutoActions";
import {IAutoActionSuccessRunResult} from "~/chat/ts/service/autoActions/actions/AbstractAutoAction";
import {IMessageButton} from "~/chat/ts/data/chat/IMessage";
import {DEVICE_DESKTOP, DEVICE_MOBILE,} from "~/ts/library/Device";

export type SendTextMessageToClientParamsNotify = "silentDesktop" | "silentMobile" | "noBadgeDesktop" | "noBadgeMobile";

export type ISendTextMessageToClientParams = AutoActionModel & {
    notify?: SendTextMessageToClientParamsNotify[],
    senderType: "any" | "onlyOperator" | "priorityOperator" | "onlyGroup" | "priorityGroup" | "virtual",
    sender?: string,
    senderId?: string,
    text: TextItem[],
    typing: 0 | 1,
    blockTextInputWithSuggests?: boolean,
    isPlaySound?: { [DEVICE_DESKTOP]: boolean, [DEVICE_MOBILE]: boolean },
    suggests: IMessageButton[],
    hiddenText?: boolean,
    removePreviousNotAnsweredAutoMessages: boolean,
    stopSendingIfClientAnswers?: boolean,
    ttlEnabled?: boolean,
    ttlSeconds?: number
}

export type IShowSuggestsParams = AutoActionModel & {
    suggests: IMessageButton[],
    descr: string
}

export type IStartBotParams = AutoActionModel & {
    botId: string;
}

export type IFireCustomEventParams = AutoActionModel & {
    eventId: string;
}


export const TEXT_ITEM_TYPE_TEXT = "text";
export const TEXT_ITEM_TYPE_HTML = "html";

export interface TextItem {
    delay: number,
    text: string,
    type: "text" | "html",
    notInBubble?: 0 | 1
}

export interface ISendTextMessageToClientSuccess extends IAutoActionSuccessRunResult {
    operator: string;
    operatorUid?: string;
    text: string;
    clientSideId: string;
}