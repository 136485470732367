import AbstractStringOperation from "~/chat/ts/service/autoActions/operations/AbstractStringOperation";

export default class OperationLikeInAnyOrder extends AbstractStringOperation {
    protected checkItem(item: string, currentValue: string): boolean {
        let like = 0;
        let checkValueSplit = item.split(" ");
        for (let splitItem of checkValueSplit) {
            if (currentValue.indexOf(splitItem) != -1) {
                like++;
            }
        }
        return like === checkValueSplit.length;
    }

}