import ChatApiResult from "~/chat/ts/service/request/ChatApiResult";

export default class MessageSenderResult extends ChatApiResult
{
    get id(): number {
        return this.getParam("id");
    }

    get operator(): string | null {
        let result = this.getParam("operator");
        return result ? result : null;
    }
}