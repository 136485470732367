import {ConfigStore} from "~/chat/ts/store/Config";
import {WidgetStore} from "~/chat/ts/store/Widget";
import AbstractListenerAction from "~/ts/library/listen/AbstractListenerAction";

export default class CloseWidget extends AbstractListenerAction {
    public getAction(): string {
        return "closeWidget";
    }

    public onAction(payload: { widgetInstanceId: string }): void {
        if (payload.widgetInstanceId == ConfigStore.instanceId.value) {
            WidgetStore.close();
        }
    }
}