import ChatApiRequest from "~/chat/ts/service/request/ChatApiRequest";
import {ConfigStore} from "~/chat/ts/store/Config";
import {OperatorsStore} from "~/chat/ts/store/Operators";

export default function attachToOperator(loginOrId: string) {
    let operator = OperatorsStore.getOperatorByIdOrLogin(loginOrId);
    if (operator) {
        if (!ConfigStore.isAttachedToOperator(operator.id)) {
            ChatApiRequest.make({
                method: "attachToOperator",
                p: {
                    operatorId: operator.id
                }
            });
        }
    }
}