import {TriggerStore} from "~/chat/ts/store/Trigger";
import {
    TRIGGER_OFFSET_SIDE_PRIMARY,
    TRIGGER_OFFSET_SIDE_SECONDARY,
    TRIGGER_SIDE_BOTTOM,
    TRIGGER_SIDE_RIGHT,
    TRIGGER_SIDE_TOP
} from "~/chat/ts/data/ISiteParams";

export default function setTriggerPosition(side: "top" | "bottom" | "left" | "right", value: number, setAsNewPrimarySide: boolean = false) {
    let offset = TriggerStore.triggerParams.value.offset[TRIGGER_OFFSET_SIDE_PRIMARY];
    let offsetSide = offset.side;
    let isSideTopBottom = side == TRIGGER_SIDE_TOP || side == TRIGGER_SIDE_BOTTOM;
    let isPrimaryTopBottom = offsetSide == TRIGGER_SIDE_TOP || offsetSide == TRIGGER_SIDE_BOTTOM;
    let isPrimary = isPrimaryTopBottom == isSideTopBottom;
    if (!isPrimary && setAsNewPrimarySide) {
        isPrimary = true;
        let newSecondarySide: any = isSideTopBottom ? TRIGGER_SIDE_RIGHT : TRIGGER_SIDE_TOP;
        TriggerStore.SET_TRIGGER_OFFSET({
            offsetType: TRIGGER_OFFSET_SIDE_SECONDARY,
            value: {
                value: TriggerStore.triggerParams.value.offset[TRIGGER_OFFSET_SIDE_SECONDARY].value,
                side: newSecondarySide
            }
        });
    }
    let offsetType: "primary" | "secondary" = isPrimary ? TRIGGER_OFFSET_SIDE_PRIMARY : TRIGGER_OFFSET_SIDE_SECONDARY;
    TriggerStore.SET_TRIGGER_OFFSET({
        offsetType,
        value: {
            value,
            side
        }
    });
}