class StorageItemWithExpiration {
    ttl: number;
    value: any;

    constructor(value: any, ttl: number, addCurrentTime = true) {
        this.ttl = ttl;
        if (addCurrentTime) {
            this.ttl += (new Date()).getTime();
        }
        this.value = value;
    }

    public isAlive(): boolean {
        return this.getCurrentTtl() > 0;
    }

    public getValue(): any {
        return this.value;
    }

    public getCurrentTtl() {
        return this.ttl - (new Date()).getTime();
    }

    public getTtl() {
        return this.ttl;
    }

    /*
    public getValue(): any | null {
        return StorageItemWithExpiration.getValue(this);
    }

     */
}

export default StorageItemWithExpiration;