import AbstractAutoAction, {
    IAutoActionSuccessRunResult
} from "~/chat/ts/service/autoActions/actions/AbstractAutoAction";
import {AutoActionModel} from "~/chat/ts/data/AutoActions";
import {OperatorsStore} from "~/chat/ts/store/Operators";
import {IOperator} from "~/chat/ts/data/IOperator";

export default abstract class AbstractOperatorAction extends AbstractAutoAction<IAbstractOperatorActionParam> {
    async run(): Promise<IAutoActionSuccessRunResult[]> {
        for (let operator of this.operatorsToChange) {
            OperatorsStore.TOGGLE_OPERATOR_HIDDEN({
                operatorId: operator.id,
                value: this.value
            });
        }
        return [{}];
    }

    get isNoize(): boolean {
        return false;
    }

    private get operatorsToChange(): IOperator[] {
        let result = [];

        for (let login of this.params.operator) {
            let operator = OperatorsStore.getOperatorByLogin(login);
            if (operator && operator.hidden != this.value) {
                result.push(operator);
            }
        }
        return result;
    }

    isCanRunNow(): boolean {
        return this.operatorsToChange.length > 0;
    }

    protected abstract get value(): boolean;
}

type IAbstractOperatorActionParam = AutoActionModel & { operator: string[] };
