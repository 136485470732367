import Dictionary from "~/ts/library/Dictionary";
import ClientActivityInstance from "~/chat/ts/service/ClientActivity";
import {ConfigStore} from "~/chat/ts/store/Config";
import ICobrowseEvents from "~/chat/ts/service/cobrowse/ICobrowseEvents";
import {CobrowseOuterStore} from "~/chat/ts/store/CobrowseOuter";
import AbstractListenerAction from "~/ts/library/listen/AbstractListenerAction";

let CobrowseEvents: any;
async function getCobrowseEvents(): Promise<ICobrowseEvents> {
    if (!CobrowseEvents) {
        CobrowseEvents =  (await(() => import(/* webpackChunkName: "cobrowse" */ '~/chat/ts/service/cobrowse/CobrowseEvents'))()).default;
    }
    return CobrowseEvents;
}

function isCobrowseControlAvailable() {
    return ConfigStore.siteParams.value.params.siteChatOptions.cobrowse.controlEnabled;
}

let actions: Dictionary<(data: any) => void> = {
    keepBrowsingAlive: function(data){
        CobrowseOuterStore.addOperator(data.channel, data.hash, data.forceLoad);
    },
    stopBrowsing:function(data){
        CobrowseOuterStore.removeOperator(data.channel);
    },
    click:async function(data: { target: string, offsetX: number, offsetY: number }){
        if (isCobrowseControlAvailable()) {
            (await getCobrowseEvents()).events.click.receive(data);
        }
    },
    mousemove:async function(data: {target: string, offsetX: number, offsetY: number, operator: string}){
        if (isCobrowseControlAvailable()) {
            (await getCobrowseEvents()).events.mousemove.receive(data);
        }
    },
    forms: async function(data: { target: string, value: any }){
        if (isCobrowseControlAvailable()) {
            (await getCobrowseEvents()).events.forms.receive(data);
        }
    },
    pointer: async function(data: {text: string, target: string, operator: string}){
        (await getCobrowseEvents()).events.pointer.receive(data);
    }/*,
    sendChanges:function(){
        //CobrowseSender.initSendChangesTimeout();
    }*/
};

export default class CobrowseListener extends AbstractListenerAction {
    public getAction(): string {
        return "browser";
    }

    public onAction(payload: { data: { action: string, data: any } }): void {
        if (ClientActivityInstance.isCurrentPage) {
            let action = payload.data.action;
            if (actions[action] != null) {
                actions[action](payload.data);
            }
        }
    }
}
