import MessageSender from "~/chat/ts/service/messages/MessageSender";
import {MESSAGE_TYPE} from "~/chat/ts/data/chat/IMessage";

interface SendMessagePayloadInterface {
    text: string;
    messageTypeId?: MESSAGE_TYPE;
    callback: (err?: string) => {};
}

export default async function sendMessage(p: SendMessagePayloadInterface | string, ...args: any[]) {
    if (typeof p != "object") {
        p = {
            text: p,
            messageTypeId: args[0],
            callback: undefined
        };
    }

    let result = await (new MessageSender({
        text: p.text,
        messageType: p.messageTypeId
    })).setSendByApi(true).send();
    if (p.callback) {
        p.callback(!result.isSuccess() ? result.getDescr() : null);
    }
}