import AbstractAutoAction, {IAutoActionSuccessRunResult} from "~/chat/ts/service/autoActions/actions/AbstractAutoAction";
import {AutoActionModel} from "~/chat/ts/data/AutoActions";
import TargetClient from "~/chat/ts/service/client/TargetClient";

export default class SetTarget extends AbstractAutoAction<ISetTargetParams> {
    async run(): Promise<IAutoActionSuccessRunResult[]> {
        return TargetClient.send(this.reason, false, this.params.notifyDestination, this.params.repeatOnNewVisit) ? [{}] : [];
    }

    private get reason() {
        return this.params.reason;
    }

    get isNoize(): boolean {
        return false;
    }

    isCanRunNow(): boolean {
        return TargetClient.send(this.reason, true);
    }

    isNeedSendResultToServer(): boolean {
        return true;
    }
}

type ISetTargetParams = AutoActionModel & {
    reason: string;
    notifyDestination: string[];
    repeatOnNewVisit: boolean;
}