import {CHAT_INLINE_COMPONENT_CLASS} from "~/chat/ts/Constants";

export default class ChatModalHelper {
    public static isMustToBeInBody(el: HTMLElement) {
        let result = false;
        let parent = el.parentElement;
        while (parent) {
            if (parent.classList.contains(CHAT_INLINE_COMPONENT_CLASS)) {
                result = true;
                break;
            }
            parent = parent.parentElement;
        }
        return result;
    }
}