function __(str: string) {
    return str;
}

function capitalize(txt: string): string {
    return txt.charAt(0).toUpperCase() + txt.slice(1);
}

export default {
    START: __("Старт"),
    ERROR: __("Ошибка"),
    TRY_TO_REFRESH_PAGE: __("Попробуйте обновить страницу"),
    SEND_MESSAGE: __("Отправить сообщение"),
    OPERATOR: __("Оператор"),
    SEARCH: __("Поиск"),
    UPLOAD_FILE: __("Загрузить файл"),
    UPLOAD_FILE_ERROR: __("Ошибка загрузки файла"),
    UPLOAD_FILE_SIZE_ERROR: __("Размер файла превышает допустимые значения в %s мегабайт"),
    CONTINUE: __("Продолжить"),
    INTRODUCE_YOURSELF: __("Представьтесь, пожалуйста:"),
    OPERATOR_NOT_ONLINE: __("Оператор сейчас не в сети"),
    SELECT_OPERATORS_GROUP_FOR_CONVERSTATION: __("Выберите отдел"),
    SELECT_OPERATOR_FOR_CONVERSATION: __("Выберите оператора"),
    FILE_N: __("Файл %d"),
    CHAT_PROVIDED_BY: __("Чат предоставлен"),
    TYPING_ANSWER: __("Печатает сообщение") + "...",
    REQUEST_ERROR: __("Ошибка при получении данных от сервера"),
    ATTACH_FILES: __("Загрузить файл"),
    SYSTEM_MESSAGE: __("Системное сообщение"),
    CANCEL: __("Отмена"),
    REFRESH: __("Обновить"),
    CLOSE: __("Закрыть"),
    HIDE: __("Скрыть"),
    REPLY: __("Ответить"),
    ANSWER: __("Ответ"),
    DEFAULT_COBROWSE_POINTER: __("Обратите внимание сюда"),
    CHANGE_OPERATOR: capitalize(__("сменить оператора")),
    LOAD_MORE: __("Загрузить ещё"),
    REVIEWS_LOADING: __("Отзывы загружаются..."),
    SELECT_ACTION: __("Выберите действие"),
    SAVE_CHAT: __("Сохранить чат"),
    ENTER_YOUR_EMAIL: __("Укажите Ваш Email"),
    SEND: __("Отправить"),
    ASK_QUESTION: __("Задать вопрос"),
    BACK: __("Назад"),
    MORE_D: __("еще %d"),
    FORWARD: __("Вперёд"),
    OUR_VISITORS_RATE_1: __("Наши посетители оставили уже %d отзыв."),
    OUR_VISITORS_RATE_2: __("Наши посетители оставили уже %d отзыва."),
    OUR_VISITORS_RATE_3: __("Наши посетители оставили уже %d отзывов."),
    JOIN_THEM_AND_LEAVE_YOUR_RATE_TOO: __("Присоединяйтесь к ним, %1sоставьте свой отзыв или пожелание%2s!"),
    NO_RATES_NOW: __("Пока что наши посетители не оставили ни одного отзыва."),
    BE_FIRST: __("Вы можете %sстать первым%s!"),
    ENTER_PAYMENT_SUM: __("Введите сумму платежа"),
    PAYMENT_SUM: __("Сумма платежа"),
    EVENT_RECEIVE_AUTO_MESSAGE: __("Посетитель получил автоприветствие"),
    EVENT_OPERATOR_SEND_TAB: __("Оператор отправил вкладку"),
    EVENT_SHOW_FORM_BEFORE_DIALOG: __("Отображена форма контактов перед диалогом"),
    EVENT_FILL_FORM_BEFORE_DIALOG: __("Заполнена форма контактов перед диалогом"),
    EVENT_SHOW_FORM_AFTER_DIALOG_START: __("Запрос контактов во время диалога"),
    EVENT_FILL_FORM_AFTER_DIALOG_START: __("Заполнена форма контактов после начала диалога"),
    EVENT_CLIENT_INITIATE_DIALOG: __("Посетитель инициировал диалог"),
    EVENT_CLIENT_SEND_MESSAGE: __("Посетитель отправил сообщение"),
    EVENT_CLIENT_CLICK_ON_WIDGET_BUTTON: __("Посетитель кликнул на кнопку виджета"),
    EVENT_CLIENT_ANSWER_TO_OPERATOR: __("Оператор инициировал диалог и посетитель ответил на него"),
    EVENT_CLIENT_ANSWER_ON_AUTOMESSAGE: __("Посетитель ответил на автоприветствие"),
    EVENT_SEND_TO_OFFLINE_FORM: __("Посетитель отправил сообщение в Offline-форму"),
    EVENT_SEND_RATE: __("Посетитель отправил отзыв"),
    EVENT_SEND_FORM: __("Посетитель заполнил форму [formName]").split("[formName]").join("%s"),
    EVENT_CLIENT_UPDATE_CONTACTS: __("Посетитель обновил контактные данные"),
    MONTH_1: __("Январь"),
    MONTH_2: __("Февраль"),
    MONTH_3: __("Март"),
    MONTH_4: __("Апрель"),
    MONTH_5: __("Май"),
    MONTH_6: __("Июнь"),
    MONTH_7: __("Июль"),
    MONTH_8: __("Август"),
    MONTH_9: __("Сентябрь"),
    MONTH_10: __("Октябрь"),
    MONTH_11: __("Ноябрь"),
    MONTH_12: __("Декабрь"),
    RATE_OUR_WORK: __("Оцените нашу работу"),
    NEW_MESSAGE_TO_YOU: __("Вам новое сообщение!").split("!").join(""),
    NEED_EULA: __("Требуется согласие на обработку персональных данных"),
    NEED_CAPTCHA: __("Подтвердите, что вы не робот"),
    REQUIRED_FOR_FILL: __("Обязательно для заполнения"),
    TO_CHAT: __("К чату"),
    TODAY: __("Сегодня"),
    YESTERDAY: __("Вчера"),
    FILES: __("Файлы"),
    YOU: __("Вы"),
    EMPTY_TEXT: __("Пустой текст"),
    READ_MORE: __("Читать далее"),
    AVERAGE_RATE: __("Средняя оценка"),
    RATE_COUNT: __("Количество отзывов"),
    S_FROM_S: __("%1$s из %2$s"),
    DAY_1: __("Пн"),
    DAY_2: __("Вт"),
    DAY_3: __("Ср"),
    DAY_4: __("Чт"),
    DAY_5: __("Пт"),
    DAY_6: __("Сб"),
    DAY_7: __("Вс"),
    COPY: __("Копировать"),
    COPY_SUCCESS: __("Скопировано в буфер обмена"),
    COPY_ERROR: __("Не удалось скопировать в буфер обмена")
} as any;

//export const momentLocale = MomentLocaleFacade.getLocale();