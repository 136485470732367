import OpenByType from "~/chat/ts/service/api/methods/OpenByType";
import {TAB_TYPE_CHAT} from "~/chat/ts/data/ITab";
import SetMessage from "~/chat/ts/service/api/methods/SetMessage";

export default function openSupport(payload?: { text?: string }) {
    OpenByType(TAB_TYPE_CHAT);
    if (payload) {
        if (payload.text) {
            SetMessage(payload.text);
        }
    }
}