import {ConfigStore} from "~/chat/ts/store/Config";
import {IActionBlock, IActionSubfilter} from "~/chat/ts/data/AutoActions";
import Filters, {AutoActionPreparedFilter} from "~/chat/ts/service/autoActions/Filters";
import AutoActionPreparedFilters from "~/chat/ts/service/autoActions/AutoActionPreparedFilters";
import AutoActionGroup from "~/chat/ts/service/autoActions/AutoActionGroup";
import AutoActionTypeMapper from "~/chat/ts/service/autoActions/actions/AutoActionTypeMapper";

export default class AutoActions {
    public static async init() {
        await this.listenFilters();
    }

    private static async listenFilters() {
        for (let actionBlock of ConfigStore.siteParams.value.params.actionBlocks) {
            for (let subFilter of actionBlock.subFilters) {
                let filters = this.getPreparedFilters(actionBlock, subFilter);
                for (let actionTypeId in subFilter.actionTypes) {
                    if (subFilter.actionTypes.hasOwnProperty(actionTypeId)) {
                        (new AutoActionGroup(subFilter, actionTypeId, subFilter.actionTypes[actionTypeId], filters));
                        let actionTypeClass = AutoActionTypeMapper.find(actionTypeId);
                        if (actionTypeClass) {
                            for (let action of subFilter.actionTypes[actionTypeId].actions) {
                                (new actionTypeClass(action)).initParams();
                            }
                        }
                    }
                }
            }
        }
    }

    private static getPreparedFilters(actionBlock: IActionBlock, subFilter: IActionSubfilter): AutoActionPreparedFilters {
        let mustMatch: AutoActionPreparedFilter[] = [];
        let mustNotMatch: AutoActionPreparedFilter[][] = [];
        if (actionBlock.filters) {
            mustMatch.push(...Filters.getPreparedFilterList(actionBlock.filters));
        }
        if (subFilter.filters) {
            mustMatch.push(...Filters.getPreparedFilterList(subFilter.filters));
        } else {
            for (let item of actionBlock.subFilters) {
                if (item.filters) {
                    mustNotMatch.push(Filters.getPreparedFilterList(item.filters));
                }
            }
        }
        return new AutoActionPreparedFilters(mustMatch, mustNotMatch);
    }
}
