import Dictionary from "../Dictionary";
import RequestResult from "./RequestResult";
import Url from "../Url";
import {Timeouts} from "~/ts/library/delay/Timeouts";
import EventManager from "~/ts/library/EventManager";

export const EVENT_SUCCESS = "success";
export const EVENT_ERROR = "error";

export interface IRequestEvent {
    xhr: XMLHttpRequest,
    request: Request
}

class Request {
    public readonly url: string;
    public readonly method: string;
    public data: string = "";
    public headers: Dictionary<string> = {};
    private headersExist: boolean = false;


    private responseType: string = "html";
    private timeout?: number;

    public static readonly events = new EventManager();

    constructor(method: string, url: string) {
        this.url = url;
        this.method = method;
    }

    public setData(data: string | Dictionary<string>) {
        this.data = typeof data == "object" ? Url.toQueryString(data) : data;
        return this;
    }

    public setResponseType(type: string) {
        this.responseType = type;
        return this;
    }

    public addHeader(name: string, value: string) {
        this.headers[name] = value;
        this.headersExist = true;
        return this;
    }

    public addHeaders(headers: Dictionary<string>) {
        for (let name in headers) {
            if (headers.hasOwnProperty(name)) {
                this.addHeader(name, headers[name]);
            }
        }
        return this;
    }

    public setTimeout(timeout: number) {
        this.timeout = timeout;
        return this;
    }

    public send(): Promise<RequestResult> {
        let that = this;
        return new Promise((resolve, reject) => {
            let xhrTimeout: number;
            let xhr = new XMLHttpRequest();
            let url = that.url;
            let method = that.method;

            if (that.headersExist) {
                let headersDictionary: Dictionary<string> = {};
                let metalkStart = "x-metalk-";
                for (let key in that.headers) {
                    if (that.headers.hasOwnProperty(key)) {
                        let keyLowerCase = key.toLowerCase();
                        if (keyLowerCase.indexOf(metalkStart) == 0) {
                            headersDictionary[keyLowerCase.split(metalkStart).join("")] = that.headers[key];
                            delete that.headers[key];
                        }
                    }
                }
                let headers = Url.toQueryString(headersDictionary);
                if (headers.length) {
                    if (url.indexOf("?") === -1) {
                        url += "?";
                    } else {
                        url += "&";
                    }
                    url += headers;
                }
            }

            xhr.open(method, url, true);

            let eventPayload: IRequestEvent = {
                xhr,
                request: this
            };

            let success = () => {
                Request.events.emit(EVENT_SUCCESS, eventPayload);
                resolve(new RequestResult(xhr));
            }

            let error = (descr: string) => {
                Request.events.emit(EVENT_ERROR, eventPayload);
                reject([descr, xhr]);
            }

            for (let key in that.headers) {
                if (that.headers.hasOwnProperty(key)) {
                    xhr.setRequestHeader(key, that.headers[key]);
                }
            }

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    Timeouts.clear(xhrTimeout);

                    if (xhr.status === 200) {
                        success();
                    } else {
                        error(xhr.statusText);
                    }
                }
            };

            xhr.onabort = function() {
                error("XHR abort");
            };

            xhr.onerror = function() {
                error("XHR error");
            };

            xhr.send(that.data);
            if (that.timeout) {
                xhrTimeout = Timeouts.set(function () {
                    xhr.abort();
                    error("XHR timeout");
                }, that.timeout);
            }
        });
    }
}

export default Request;