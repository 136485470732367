import AbstractAutoAction, {
    IAutoActionSuccessRunResult
} from "~/chat/ts/service/autoActions/actions/AbstractAutoAction";
import {AutoActionModel} from "~/chat/ts/data/AutoActions";
import {StorageInstance} from "~/ts/library/storage/StorageFactory";
import fireCustomEvent from "~/chat/ts/service/api/methods/FireCustomEvent";


export default class FireCustomEvent extends AbstractAutoAction<FireCustomEventParams> {
    get isNoize(): boolean {
        return true;
    }

    isNeedSendResultToServer(): boolean {
        return true;
    }

    isCanRunNow(): boolean {
        return !StorageInstance.getex(this.params.eventId)
    }

    private get cacheKey() {
        return `fireCustomEvent_${this.params.eventId}`;
    }

    async run(): Promise<IAutoActionSuccessRunResult[]> {
        let result: IAutoActionSuccessRunResult[] = [];
        if (this.params.eventId) {
            let startResult = await fireCustomEvent(this.params.eventId);
            if (startResult.isSuccess()) {
                StorageInstance.setex(this.cacheKey, 1, 600000);
                result.push({})
            }
        }
        return result;
    }
}

type FireCustomEventParams = AutoActionModel & {
    eventId: string;
}
