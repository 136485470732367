import Listener from "~/ts/library/listen/Listener";
import CrossTabSender from "~/ts/library/crossTabCommunication/talk/CrossTabSender";
import CrossTabMessage from "~/ts/library/crossTabCommunication/talk/CrossTabMessage";
import NodeRequest from "~/ts/library/listen/NodeRequest";
import {ConfigStore} from "~/chat/ts/store/Config";

export const ListenerInstance = (new Listener())
    .setCacheChannelAvailable(!ConfigStore.isMobile.value);


CrossTabSender.setMessageCallback("websocketAjax", (data, responseCallback) => {
    NodeRequest.sendTextToSocket(ListenerInstance, data).then((sended) => {
        if (sended) {
            responseCallback(true);
        }
    });
});

CrossTabSender.setMessageCallback("websocketAjaxResponse", (data) => {
    NodeRequest.eventManager.emit(data.callbackId, data.data);
});


ListenerInstance.onAction("ajaxResponse", (listenMessage) => {
    let originalMessage = listenMessage.originalMessage;
    let data = listenMessage.getData();
    if (!NodeRequest.eventManager.emit(originalMessage.callbackId, data)) {
        let message = new CrossTabMessage("websocketAjaxResponse", originalMessage);
        CrossTabSender.send(message);
    }
});
