import Dictionary from "~/ts/library/Dictionary";
import AbstractAutoAction from "~/chat/ts/service/autoActions/actions/AbstractAutoAction";
import {
    ACTION_TYPE_DESTROY_WIDGET,
    ACTION_TYPE_FIRE_CUSTOM_EVENT,
    ACTION_TYPE_HIDE_OPERATOR,
    ACTION_TYPE_HIDE_OPERATORS_GROUP,
    ACTION_TYPE_HIDE_TRIGGER,
    ACTION_TYPE_RUN_JAVASCRIPT,
    ACTION_TYPE_SEND_TEXT_MESSAGE_TO_CLIENT,
    ACTION_TYPE_SEND_TO_ANALYTICS,
    ACTION_TYPE_SEND_WEBHOOK,
    ACTION_TYPE_SET_FLAG,
    ACTION_TYPE_SET_TAB_INVISIBLE,
    ACTION_TYPE_SET_TAB_VISIBLE,
    ACTION_TYPE_SET_TARGET,
    ACTION_TYPE_SHOW_OPERATOR,
    ACTION_TYPE_SHOW_OPERATORS_GROUP,
    ACTION_TYPE_SHOW_SUGGESTS,
    ACTION_TYPE_SHOW_TAB,
    ACTION_TYPE_SHOW_TRIGGER,
    ACTION_TYPE_SHOW_TRIGGER_MENU,
    ACTION_TYPE_START_BOT,
    AutoActionModel
} from "~/chat/ts/data/AutoActions";
import ShowTab from "~/chat/ts/service/autoActions/actions/ShowTab";
import DestroyWidget from "~/chat/ts/service/autoActions/actions/DestroyWidget";
import HideOperator from "~/chat/ts/service/autoActions/actions/HideOperator";
import ShowOperator from "~/chat/ts/service/autoActions/actions/ShowOperator";
import OpenTriggerMenu from "~/chat/ts/service/autoActions/actions/OpenTriggerMenu";
import ShowOperatorsGroup from "~/chat/ts/service/autoActions/actions/ShowOperatorsGroup";
import HideOperatorsGroup from "~/chat/ts/service/autoActions/actions/HideOperatorsGroup";
import SetTabVisible from "~/chat/ts/service/autoActions/actions/SetTabVisible";
import SetTabInvisible from "~/chat/ts/service/autoActions/actions/SetTabInvisible";
import ShowTrigger from "~/chat/ts/service/autoActions/actions/ShowTrigger";
import HideTrigger from "~/chat/ts/service/autoActions/actions/HideTrigger";
import SetTarget from "~/chat/ts/service/autoActions/actions/SetTarget";
import RunJavascript from "~/chat/ts/service/autoActions/actions/RunJavascript";
import Webhook from "~/chat/ts/service/autoActions/actions/Webhook";
import SendTextMessageToClient from "~/chat/ts/service/autoActions/actions/SendTextMessageToClient";
import SetFlag from "~/chat/ts/service/autoActions/actions/SetFlag";
import ShowSuggests from "~/chat/ts/service/autoActions/actions/ShowSuggests";
import SendToAnalytics from "~/chat/ts/service/autoActions/actions/SendToAnalytics";
import StartBot from "~/chat/ts/service/autoActions/actions/StartBot";
import FireCustomEvent from "~/chat/ts/service/autoActions/actions/FireCustomEvent";

let list: Dictionary<new(params: AutoActionModel) => AbstractAutoAction<AutoActionModel>> = {
    [ACTION_TYPE_SHOW_TAB]: ShowTab,
    [ACTION_TYPE_DESTROY_WIDGET]: DestroyWidget,
    [ACTION_TYPE_HIDE_OPERATOR]: HideOperator,
    [ACTION_TYPE_SHOW_OPERATOR]: ShowOperator,
    [ACTION_TYPE_SHOW_TRIGGER_MENU]: OpenTriggerMenu,
    [ACTION_TYPE_SHOW_OPERATORS_GROUP]: ShowOperatorsGroup,
    [ACTION_TYPE_HIDE_OPERATORS_GROUP]: HideOperatorsGroup,
    [ACTION_TYPE_SET_TAB_VISIBLE]: SetTabVisible,
    [ACTION_TYPE_SET_TAB_INVISIBLE]: SetTabInvisible,
    [ACTION_TYPE_SHOW_TRIGGER]: ShowTrigger,
    [ACTION_TYPE_HIDE_TRIGGER]: HideTrigger,
    [ACTION_TYPE_SET_TARGET]: SetTarget,
    [ACTION_TYPE_RUN_JAVASCRIPT]: RunJavascript,
    [ACTION_TYPE_SEND_WEBHOOK]: Webhook,
    [ACTION_TYPE_SEND_TEXT_MESSAGE_TO_CLIENT]: SendTextMessageToClient,
    [ACTION_TYPE_SHOW_SUGGESTS]: ShowSuggests,
    [ACTION_TYPE_SET_FLAG]: SetFlag,
    [ACTION_TYPE_SEND_TO_ANALYTICS]: SendToAnalytics,
    [ACTION_TYPE_START_BOT]: StartBot,
    [ACTION_TYPE_FIRE_CUSTOM_EVENT]: FireCustomEvent
};

export default class AutoActionTypeMapper {
    public static find(type: string): new(params: AutoActionModel) => AbstractAutoAction<AutoActionModel> | null {
        return list[type] ? list[type] : null;
    }
}