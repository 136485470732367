export default abstract class AbstractActionFilterOperation {
    public make(filterName: string, filterType: string, currentValue: any, checkValue: any[]): boolean {
        this.prepareCheckValue(filterName, checkValue);
        return this.check(filterName, filterType, currentValue, checkValue);
    }

    protected abstract check(filterName: string, filterType: string, currentValue: any, checkValue: any[]): boolean;

    protected prepareCheckValue(filterName: string, checkValue: any[]): any[] {
        return checkValue;
    }

    public get returnFalseOnFirstMismatch(): boolean {
        return false;
    }

    public get returnOnEmptyFilterValues(): boolean {
        return false;
    }
}