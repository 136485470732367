import AbstractAutoAction, {
    IAutoActionSuccessRunResult
} from "~/chat/ts/service/autoActions/actions/AbstractAutoAction";
import {AutoActionModel} from "~/chat/ts/data/AutoActions";
import {TriggerStore} from "~/chat/ts/store/Trigger";

export default class OpenTriggerMenu extends AbstractAutoAction<AutoActionModel> {
    async run(): Promise<IAutoActionSuccessRunResult[]> {
        TriggerStore.SET_TRIGGER_MENU_OPENED(true);
        return [{}];
    }

    isNeedSendResultToServer(): boolean {
        return true;
    }

    get isNoize(): boolean {
        return true;
    }

    isCanRunNow(): boolean {
        return !TriggerStore.isTriggerMenuOpened.value;
    }
}