import AbstractAutoAction, {IAutoActionSuccessRunResult} from "~/chat/ts/service/autoActions/actions/AbstractAutoAction";
import {AutoActionModel} from "~/chat/ts/data/AutoActions";
import {StorageInstance} from "~/ts/library/storage/StorageFactory";
import setClientInfo from "~/chat/ts/service/api/methods/SetClientInfo";

const CACHE_KEY = "setFlagAction";

export default class SetFlag extends AbstractAutoAction<ISetFlagParams> {
    async run(): Promise<IAutoActionSuccessRunResult[]> {
        setClientInfo({
            flag: this.params.color
        });
        StorageInstance.setex(CACHE_KEY, this.params.color, 1200000);
        return [{}];
    }

    get isNoize(): boolean {
        return false;
    }

    isCanRunNow(): boolean {
        return StorageInstance.getex(CACHE_KEY) != this.params.color;
    }

    isNeedSendResultToServer(): boolean {
        return true;
    }
}

type ISetFlagParams = AutoActionModel & {
    color: string
}