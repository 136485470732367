import WindowHelper from "~/ts/library/WindowHelper";

export default function isElementInViewport(p: {
    element: HTMLElement,
    middleOfScreen?: boolean,
    windowHeightMultiply?: number
}) {
    let el = p.element;

    let top = el.offsetTop;
    let left = el.offsetLeft;
    let width = el.offsetWidth;
    let height = el.offsetHeight;

    while (el.offsetParent) {
        el = el.offsetParent as HTMLElement;
        top += el.offsetTop;
        left += el.offsetLeft;
    }
    let windowHeight = WindowHelper.getSize().height;
    if (p.windowHeightMultiply) {
        windowHeight *= p.windowHeightMultiply;
    }

    if (p.middleOfScreen) {
        //let windowHeight = WindowHelper.getSize().height;
        if (top < window.pageYOffset) {
            top -= windowHeight / 2;
        } else if (top + height > window.pageYOffset) {
            top += windowHeight / 2;
        }
    }

    return (
        top < (window.pageYOffset + windowHeight) &&
        left < (window.pageXOffset + window.innerWidth) &&
        (top + height) > window.pageYOffset &&
        (left + width) > window.pageXOffset
    );
}