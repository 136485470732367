export const CLIENT_FIELD_DESCR = "descr";
export const CLIENT_FIELD_PHONE = "phone";
export const CLIENT_FIELD_EMAIL = "email";
export const CLIENT_FIELD_FIRST_NAME = "firstName";
export const CLIENT_FIELD_LAST_NAME = "lastName";
export const CLIENT_FIELD_SECOND_NAME = "secondName";

export const STANDART_FIELD_NAMES = [
    CLIENT_FIELD_DESCR,
    CLIENT_FIELD_EMAIL,
    CLIENT_FIELD_PHONE,
    CLIENT_FIELD_FIRST_NAME,
    CLIENT_FIELD_LAST_NAME,
    CLIENT_FIELD_SECOND_NAME
];

export const NAME_FIELDS = [
    CLIENT_FIELD_LAST_NAME,
    CLIENT_FIELD_FIRST_NAME,
    CLIENT_FIELD_SECOND_NAME
];