import WebStorage from "./WebStorage";
import Events from "~/ts/library/Events";

class LocalStorage extends WebStorage {
    constructor() {
        super();
        Events.addEventListener("storage",  window,(e: StorageEvent) => {
            if (e.key && this.isOurKey(e.key)) {
                let key = this.removeOurPrefix(e.key);
                if (LocalStorage.hasInReactiveStorage(key)) {
                    if (e.newValue !== undefined) {
                        try {
                            LocalStorage.setInReactiveStorage(key, JSON.parse(e.newValue));
                        } catch (e) {
                            LocalStorage.removeFromReactiveStorage(key);
                        }
                    } else {
                        LocalStorage.removeFromReactiveStorage(key);
                    }
                }
                LocalStorage.fireEvent(key, e);
            }
        });
    }

    protected getStorage(): Storage {
        return window.localStorage;
    }

    public isPersistent(): boolean {
        return true;
    }

    getType(): string {
        return "localStorage";
    }
}


export default LocalStorage;