import {OperatorsStore} from "~/chat/ts/store/Operators";
import Dictionary from "~/ts/library/Dictionary";
import {OperatorStatus} from "~/chat/ts/data/OperatorStatus";
import AbstractListenerAction from "~/ts/library/listen/AbstractListenerAction";

export default class OperatorsOnlineUpdatedListener extends AbstractListenerAction {
    public getAction(): string {
        return "operatorsOnlineUpdated";
    }

    public onAction(payload: { data: Dictionary<OperatorStatus> }): void {
        OperatorsStore.setStatusFromDictionary(payload.data);
    }
}