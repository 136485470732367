import Dictionary from "~/ts/library/Dictionary";
import {
    ACTION_FILTER_BOT_RUNNING,
    ACTION_FILTER_CC,
    ACTION_FILTER_CITY,
    ACTION_FILTER_CLICK_ON_ELEMENT,
    ACTION_FILTER_COL_OPERATORS_ONLINE,
    ACTION_FILTER_COL_OPERATORS_ONLINE_AND_VISIBLE,
    ACTION_FILTER_COUNTRY,
    ACTION_FILTER_COUNTRY_CODE,
    ACTION_FILTER_CURRENT_PAGE_TITLE,
    ACTION_FILTER_DATE,
    ACTION_FILTER_DAY_OF_WEEK,
    ACTION_FILTER_DEVICE,
    ACTION_FILTER_DIALOG_OPENED,
    ACTION_FILTER_ELEMENT_ON_PAGE,
    ACTION_FILTER_IP,
    ACTION_FILTER_MOUSE_LEAVE_PAGE,
    ACTION_FILTER_NEW_MESSAGE_FROM_CLIENT,
    ACTION_FILTER_NEW_MESSAGE_FROM_OPERATOR,
    ACTION_FILTER_OPERATOR_ONLINE,
    ACTION_FILTER_OPERATOR_ONLINE_AND_VISIBLE,
    ACTION_FILTER_REFERER,
    ACTION_FILTER_REGION,
    ACTION_FILTER_SCROLL_PERCENTAGE,
    ACTION_FILTER_SCROLL_PX,
    ACTION_FILTER_SCROLL_TO_ELEMENT,
    ACTION_FILTER_SEARCH_KEYWORD,
    ACTION_FILTER_TIME_ON_PAGE,
    ACTION_FILTER_TIME_RANGE,
    ACTION_FILTER_TIME_UTC,
    ACTION_FILTER_URL,
    ACTION_FILTER_WIDGET_STATE,
    ACTION_FILTER_WINDOW_HEIGHT,
    ACTION_FILTER_WINDOW_WIDTH,
    ACTION_FILTER_ZERO,
    FILTER_TYPE_DATE,
    FILTER_TYPE_INT,
    FILTER_TYPE_SECONDS,
    FILTER_TYPE_TEXT,
    FILTER_TYPE_TIMERANGE,
    IAutoActionFilters,
    IAutoActionTimeValue,
    OPERATION_BEGIN_WITH,
    OPERATION_EMPTY,
    OPERATION_END_WITH,
    OPERATION_EQUAL,
    OPERATION_GREATER_THAN,
    OPERATION_LESS_THAN,
    OPERATION_LIKE,
    OPERATION_LIKE_IN_ANY_ORDER,
    OPERATION_NOT_BEGIN_WITH,
    OPERATION_NOT_EMPTY,
    OPERATION_NOT_END_WITH,
    OPERATION_NOT_EQUAL,
    OPERATION_NOT_LIKE,
    OPERATION_REGEXP_MATCH,
    OPERATION_REGEXP_NOT_MATCH
} from "~/chat/ts/data/AutoActions";
import {ConfigStore} from "~/chat/ts/store/Config";
import {StorageInstance} from "~/ts/library/storage/StorageFactory";
import {ClientStore} from "~/chat/ts/store/Client";
import {Intervals} from "~/ts/library/delay/Intervals";
import Delay from "~/ts/library/Delay";
import AbstractActionFilterOperation from "~/chat/ts/service/autoActions/AbstractActionFilterOperation";
import OperationEqual from "~/chat/ts/service/autoActions/operations/OperationEqual";
import OperationNotEqual from "~/chat/ts/service/autoActions/operations/OperationNotEqual";
import OperationBeginWith from "~/chat/ts/service/autoActions/operations/OperationBeginWith";
import OperationEndWith from "~/chat/ts/service/autoActions/operations/OperationEndWith";
import OperationGreaterThan from "~/chat/ts/service/autoActions/operations/OperationGreaterThan";
import OperationLessThan from "~/chat/ts/service/autoActions/operations/OperationLessThan";
import OperationLike from "~/chat/ts/service/autoActions/operations/OperationLike";
import OperationNotBeginWith from "~/chat/ts/service/autoActions/operations/OperationNotBeginWith";
import OperationNotEmpty from "~/chat/ts/service/autoActions/operations/OperationNotEmpty";
import OperationEmpty from "~/chat/ts/service/autoActions/operations/OperationEmpty";
import OperationNotEndWith from "~/chat/ts/service/autoActions/operations/OperationNotEndWith";
import OperationNotLike from "~/chat/ts/service/autoActions/operations/OperationNotLike";
import OperationRegexpMatch from "~/chat/ts/service/autoActions/operations/OperationRegexpMatch";
import OperationRegexpNotMatch from "~/chat/ts/service/autoActions/operations/OperationRegexpNotMatch";
import {ChatEventManagerInstance, CLIENT_FIELD_CHANGED_EVENT} from "~/chat/ts/ChatEventManager";
import ObjectHelper from "~/ts/library/ObjectHelper";
import FiltersCheckResult from "~/chat/ts/service/autoActions/FiltersCheckResult";
import OperationLikeInAnyOrder from "~/chat/ts/service/autoActions/operations/OperationLikeInAnyOrder";

export const CLIENT_FIELD_PREFIX = "clientField_";

let localFilters: Dictionary<boolean> = {
    [ACTION_FILTER_DATE]: true,
    [ACTION_FILTER_TIME_ON_PAGE]: true,
    [ACTION_FILTER_MOUSE_LEAVE_PAGE]: true,
    [ACTION_FILTER_SCROLL_PERCENTAGE]: true,
    [ACTION_FILTER_SCROLL_PX]: true,
    [ACTION_FILTER_SCROLL_TO_ELEMENT]: true,
    [ACTION_FILTER_DEVICE]: true,
    [ACTION_FILTER_URL]: true,
    [ACTION_FILTER_CURRENT_PAGE_TITLE]: true,
    [ACTION_FILTER_SEARCH_KEYWORD]: true,
    [ACTION_FILTER_REFERER]: true,
    [ACTION_FILTER_DAY_OF_WEEK]: true,
    [ACTION_FILTER_TIME_RANGE]: true,
    [ACTION_FILTER_TIME_UTC]: true,
    [ACTION_FILTER_COL_OPERATORS_ONLINE_AND_VISIBLE]: true,
    [ACTION_FILTER_COL_OPERATORS_ONLINE]: true,
    [ACTION_FILTER_OPERATOR_ONLINE_AND_VISIBLE]: true,
    [ACTION_FILTER_OPERATOR_ONLINE]: true,
    [ACTION_FILTER_NEW_MESSAGE_FROM_CLIENT]: true,
    [ACTION_FILTER_NEW_MESSAGE_FROM_OPERATOR]: true,
    [ACTION_FILTER_WIDGET_STATE]: true,
    [ACTION_FILTER_CLICK_ON_ELEMENT]: true,
    [ACTION_FILTER_ELEMENT_ON_PAGE]: true,
    [ACTION_FILTER_WINDOW_HEIGHT]: true,
    [ACTION_FILTER_WINDOW_WIDTH]: true,
    [ACTION_FILTER_BOT_RUNNING]: true,
    [ACTION_FILTER_DIALOG_OPENED]: true
};

//let timeFilters: Dictionary<boolean> = { timeFromLastAutoMessageToClient:true, timeOnPage:true, timeFromLastMessage: true, visitStartTime: true, timeFromLastMessageFromClient: true, timeFromLastMessageFromOperator:true, closeWidget: true};
//let timeRangeFilters: Dictionary<boolean> = {"timeRange": true, "timeUTC": true};
let notEmptyFilters: Dictionary<boolean> = {
    [ACTION_FILTER_NEW_MESSAGE_FROM_CLIENT]: true,
    [ACTION_FILTER_NEW_MESSAGE_FROM_OPERATOR]: true,
    [ACTION_FILTER_IP]: true,
    [ACTION_FILTER_CITY]: true,
    [ACTION_FILTER_COUNTRY]: true,
    [ACTION_FILTER_REGION]: true,
    [ACTION_FILTER_COL_OPERATORS_ONLINE_AND_VISIBLE]: true,
    [ACTION_FILTER_WINDOW_WIDTH]: true,
    [ACTION_FILTER_WINDOW_HEIGHT]: true,
    [ACTION_FILTER_WIDGET_STATE]: true,
    [ACTION_FILTER_COL_OPERATORS_ONLINE]: true
};

//let values: Dictionary<any> = {};
let localFilterValues: Dictionary<any> = {};


let operations:Dictionary<AbstractActionFilterOperation> = {
    [OPERATION_EQUAL]: new OperationEqual(),
    [OPERATION_NOT_EQUAL]: new OperationNotEqual(),
    [OPERATION_BEGIN_WITH]: new OperationBeginWith(),
    [OPERATION_END_WITH]: new OperationEndWith(),
    [OPERATION_GREATER_THAN]: new OperationGreaterThan(),
    [OPERATION_LESS_THAN]: new OperationLessThan(),
    [OPERATION_LIKE]: new OperationLike(),
    [OPERATION_LIKE_IN_ANY_ORDER]: new OperationLikeInAnyOrder(),
    [OPERATION_NOT_BEGIN_WITH]: new OperationNotBeginWith(),
    [OPERATION_EMPTY]: new OperationEmpty(),
    [OPERATION_NOT_EMPTY]: new OperationNotEmpty(),
    [OPERATION_NOT_END_WITH]: new OperationNotEndWith(),
    [OPERATION_NOT_LIKE]: new OperationNotLike(),
    [OPERATION_REGEXP_MATCH]: new OperationRegexpMatch(),
    [OPERATION_REGEXP_NOT_MATCH]: new OperationRegexpNotMatch()
};

export default class Filters {
    public static get localFilters() {
        return localFilters;
    }

    public static init() {
        //this.getFiltersFromStorage();
        ClientStore.SET_CC(this.get(ACTION_FILTER_CC));
        ClientStore.SET_COUNTRY_CODE(this.get(ACTION_FILTER_COUNTRY_CODE));
        let callback = () => {
            this.initDayOfWeek();
            this.initDate();
            let filterTypes = ConfigStore.siteParams.value.filterTypes;
            for (let name in filterTypes) {
                if (filterTypes.hasOwnProperty(name)) {
                    if (this.isTimeFilter(name)) {
                        this.queue(name);
                    }
                }
            }
            this.queue(ACTION_FILTER_ZERO);
        };
        callback();
        Intervals.clear(this.timeEventsInterval);
        this.timeEventsInterval = Intervals.set(callback, 1000);

        ChatEventManagerInstance.addEventListener(CLIENT_FIELD_CHANGED_EVENT, fieldName => {
            this.queue(this.getFilterNameByClientFieldName(fieldName));
        });
    }

    public static checkFilters(filters: IAutoActionFilters): FiltersCheckResult {
        for (let preparedFilterList of Filters.getPreparedFilterList(filters)) {
            let filtersCheckResult = preparedFilterList.check();
            if (!filtersCheckResult.isSuccess) {
                return filtersCheckResult
            }
        }
        return new FiltersCheckResult(true);
    }

    public static getPreparedFilterList(filters: IAutoActionFilters): AutoActionPreparedFilter[] {
        let result: AutoActionPreparedFilter[] = [];
        if (filters) {
            for (let filterId in filters) {
                if (filters.hasOwnProperty(filterId)) {
                    for (let operationId in filters[filterId]) {
                        if (filters[filterId].hasOwnProperty(operationId)) {
                            result.push(new AutoActionPreparedFilter(
                                filterId,
                                operationId,
                                Filters.initialPrepareValues(filterId, ObjectHelper.jsonClone(filters[filterId][operationId]))
                            ));
                        }
                    }
                }
            }
        }
        return result;
    }

    public static check(name: string, operation: string, checkValue: any[]): FiltersCheckResult {
        if (name == ACTION_FILTER_ZERO) {
            return new FiltersCheckResult(true);
        }

        let good = 0;

        let operationObject = operations[operation];
        if (operationObject) {
            let type = this.getType(name);
            let def: any = "";
            if (type == null) {
                type = "text";
            }

            let numeric = type == FILTER_TYPE_INT || type == FILTER_TYPE_SECONDS || type == FILTER_TYPE_DATE;
            let string = type == FILTER_TYPE_TEXT;

            if (numeric) {
                def = 0;
            }

            if (this.isNotEmptyFilter(name)) {
                def = null;
            }

            let currentValue = this.get(name, def);


            let $currentValues: any[];
            if (typeof currentValue == "object" && currentValue != null) {
                $currentValues = currentValue;
            } else {
                $currentValues = [currentValue];
            }

            if (this.isNotEmptyFilter(name)) {
                for (let value of $currentValues) {
                    if (value == null) {
                        //Не выполняем эти автодействия до тех пор, пока не будут известны все необходимые значения фильтров
                        return new FiltersCheckResult(false, true);
                    }
                }
            }

            if (numeric) {
                for (let i in $currentValues) {
                    if ($currentValues.hasOwnProperty(i)) {
                        $currentValues[i] = parseInt($currentValues[i]);
                    }
                }
                for (let i in checkValue) {
                    if (checkValue.hasOwnProperty(i)) {
                        if (type == FILTER_TYPE_DATE && typeof checkValue[i] == "string") {
                            checkValue[i] = this.prepareDate(checkValue[i]);

                        } else {
                            checkValue[i] = parseInt(checkValue[i]);
                        }
                    }
                }
            } else if (string) {
                for (let i in $currentValues) {
                    if ($currentValues.hasOwnProperty(i)) {
                        if (typeof $currentValues[i] == "string") {
                            $currentValues[i] = $currentValues[i].toLowerCase().trim(); //добавили трим из-за того, что многие хуярят пробелы в настроках автодействия
                        }
                    }
                }
                for (let i in checkValue) {
                    if (checkValue.hasOwnProperty(i)) {
                        if (typeof checkValue[i] == "string") {
                            checkValue[i] = checkValue[i].toLowerCase().trim(); //добавили трим из-за того, что многие хуярят пробелы в настроках автодействия
                        }
                    }
                }
            }

            if ($currentValues.length) {
                for (let $currentValue of $currentValues) {
                    if (operationObject.make(name, type, $currentValue, checkValue)) {
                        good++;
                        if (!operationObject.returnFalseOnFirstMismatch) {
                            break;
                        }
                    } else if (operationObject.returnFalseOnFirstMismatch) {
                        good = 0;
                        break;
                    }
                }
            } else {
                return new FiltersCheckResult(operationObject.returnOnEmptyFilterValues);
            }

        }

        return new FiltersCheckResult(good > 0);
    }


    public static incr(name: string, incrBy: number = 1) {
        let v = parseInt(this.get(name, 0));
        if (!v) v = 0;
        v += incrBy;
        this.set(name, v);
        return v;
    }

    public static decr(name: string, decrBy: number = -1) {
        return this.incr(name, decrBy * -1);
    }

    public static async set(name: string, value?: any, clear?: number, clearValue?: any) {
        let clientFieldName = this.getClientFieldNameFromFilterName(name);
        if (clientFieldName) {
            ClientStore.setFieldValue(clientFieldName, value);
            return;
        }

        if (this.isTimeFilter(name)) {
            value = this.now();
        }

        if (this.isChanged(name, value)) {
            if (!this.isLocalFilter(name)) {
                StorageInstance.set(this.getStorageKey(name), value);
            } else {
                localFilterValues[name] = value;
            }
            this.queue(name);
        }

        if (clear > 0) {
            try {
                await Delay.make(clear, "clearActionFilters_" + name);
                this.set(name, clearValue);
            } catch (e) {

            }
        }
    }

    public static getFilterNameByClientFieldName(fieldName: string): string {
        return CLIENT_FIELD_PREFIX + fieldName;
    }

    public static getClientFieldNameFromFilterName(name: string): string | null {
        if (name.indexOf(CLIENT_FIELD_PREFIX) == 0) {
            name = name.split(CLIENT_FIELD_PREFIX)[1];
            return name;
        }
        return null;
    }

    public static get(name: string, def?: any) {
        let result: any;

        let clientFieldName = this.getClientFieldNameFromFilterName(name);
        if (clientFieldName) {
            result = ClientStore.getFieldValue(clientFieldName);
        } else {
            if (this.isLocalFilter(name)) {
                result = localFilterValues[name];
            } else {
                result = StorageInstance.get(this.getStorageKey(name), true);
            }
            if (result == null) {
                result = def;
            }

            if (this.isTimeFilter(name)) {
                result = this.now() - parseInt(result);
            }
        }

        return result;
    }

    private static getFilterListenerEventName(filterId: string) {
        return "FILTER_CHANGE_" + filterId;
    }

    public static addFilterListener(filterId: string, callback: () => void) {
        ChatEventManagerInstance.addEventListener(this.getFilterListenerEventName(filterId), callback);
    }


    public static secondsToInt(v: IAutoActionTimeValue) {
        return v.seconds * v.multiply;
    }

    private static getType(name: string) {
        let res = ConfigStore.siteParams.value.filterTypes[name];
        if (res == null) {
            res = FILTER_TYPE_TEXT;
        }
        return res;
    }

    private static isLocalFilter(name: string) {
        return localFilters[name] != null;
    }

    private static isTimeFilter(name: string) {
        return this.getType(name) == FILTER_TYPE_SECONDS;
    }

    private static isNotEmptyFilter(name: string) {
        return notEmptyFilters[name] != null;
    }

    private static isChanged(name: string, newValue: any) {
        let currentValue = this.get(name);
        if (typeof currentValue == "object" || typeof newValue == "object") {
            currentValue = JSON.stringify(currentValue);
            newValue = JSON.stringify(newValue);
        }
        return currentValue !== newValue;
    }

    private static getStorageKey(filterName: string) {
        return `fv::${ConfigStore.siteId.value}::${filterName}`;
    }



    private static queue(filterId: string) {
        ChatEventManagerInstance.emit(this.getFilterListenerEventName(filterId));
    }

    private static now() {
        return parseInt((Date.now() / 1000) as any);
    }

    private static prepareDate(date: string | Date) {
        let dt: (number | string)[][];
        if (typeof date == "string") {
            let dateSplit = date.split(/[-.]/);
            if (dateSplit.length == 3) {
                if (dateSplit[2].length === 4) {
                    dt = [[parseInt(dateSplit[2]), parseInt(dateSplit[1]), parseInt(dateSplit[0])]];
                } else {
                    dt = [[parseInt(dateSplit[0]), parseInt(dateSplit[1]), parseInt(dateSplit[2])]];
                }
            } else {
                return 0;
            }
        } else {
            dt = this.getTimeRangeValueFromDateObject(date);
        }
        if (dt[0][1] < 10) dt[0][1] = "0" + dt[0][1];
        if (dt[0][2] < 10) dt[0][2] = "0" + dt[0][2];
        return parseInt(dt[0][0] + "" + dt[0][1] + "" + dt[0][2])
    }

    private static nowDate() {
        return this.prepareDate(new Date());
    }


    private static getTimeRangeValueFromDateObject(date: Date) {
        return [[date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()]];
    }

    private static getUTCTimeRangeValueFromDateObject(date: Date) {
        return [[date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()]];
    }

    private static initDate() {
        this.set(ACTION_FILTER_DATE, this.nowDate());
    }

    private static initDayOfWeek() {
        let date = new Date();
        let day = date.getDay();
        if (day == 0) day = 7;
        let dayOfWeek = [day.toString()];
        if (day < 6) {
            dayOfWeek.push("12345")
        } else {
            dayOfWeek.push("67");
        }
        this.set(ACTION_FILTER_DAY_OF_WEEK, dayOfWeek);

        this.set(ACTION_FILTER_TIME_RANGE, this.getTimeRangeValueFromDateObject(date));
        this.set(ACTION_FILTER_TIME_UTC, this.getUTCTimeRangeValueFromDateObject(date));

    }

    private static timeEventsInterval: any = null;

    public static initialPrepareValues(filterId: string, values: any[]) {
        let type = this.getType(filterId);
        if (type) {
            for (let i = 0; i < values.length; i++) {
                let value = values[i];
                if (type == FILTER_TYPE_SECONDS) {
                    if (typeof value == "object") {
                        values[i] = this.secondsToInt(value);
                    }
                } else if (type == FILTER_TYPE_TIMERANGE) {
                    for (let j = 0; j < value.length; j++) {
                        let subValue = value[j];
                        if (typeof subValue == "string") {
                            let split = subValue.split(":");
                            value[j] = [parseInt(split[0]), parseInt(split[1]), parseInt(split[2])];
                        }
                    }
                }
            }
        }
        return values;
    }
}

export class AutoActionPreparedFilter {
    private _filterId: string;
    private _operationId: string;
    private _values: any[];

    constructor(filterId: string, operationId: string, values: any[]) {
        this._filterId = filterId;
        this._operationId = operationId;
        this._values = values;
    }

    get filterId() {
        return this._filterId;
    }

    check(): FiltersCheckResult {
        return Filters.check(this.filterId, this._operationId, this._values);
    }
}
