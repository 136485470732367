import ChatApiRequest from "~/chat/ts/service/request/ChatApiRequest";
import {WidgetStore} from "~/chat/ts/store/Widget";

export default async function startBot(schemeId: string, manual: boolean = true) {
    let result = await ChatApiRequest.make({
        method: "startBot",
        p: {
            schemeId,
            manual
        }
    });
    if (result.isSuccess()) {
        WidgetStore.open();
        WidgetStore.closeSideMenu();
        WidgetStore.openChatTab();
    }

    return result;

}