class ListenerMessage {
    /*
    public action: string;
    public data: any;
    */
    public time: number;
    public originalMessage: any;
    public previousTime?: number;
    public serverTime?: number;

    constructor(originalMessage: any, time: number, previousTime?: number, serverTime?: number) {
        this.serverTime = serverTime;
        this.time = time;
        this.originalMessage = originalMessage;
        this.previousTime = previousTime;
    }

    public getData(): any {
        return this.originalMessage.data;
    }

    public getAction(): string | null {
        return this.originalMessage.action;
    }
}

export default ListenerMessage;