import OperationBeginWith from "~/chat/ts/service/autoActions/operations/OperationBeginWith";

export default class OperationNotBeginWith extends OperationBeginWith {
    protected check(filterName: string, filterType: string, currentValue: any, checkValue: any[]): boolean {
        return !super.check(filterName, filterType, currentValue, checkValue);
    }

    get returnFalseOnFirstMismatch(): boolean {
        return true;
    }

    get returnOnEmptyFilterValues(): boolean {
        return true;
    }
}