
/*
function twoDigits(d: number) {
    if (0 <= d && d < 10) return "0" + d.toString();
    if (-10 < d && d < 0) return "-0" + (-1 * d).toString();
    return d.toString();
}
 */

let timeOffsetCache: number;

export default class ChatDateHelper {
    private date: Date;

    constructor(date: string | Date) {
        this.date = (typeof date == "object") ? date : ChatDateHelper.parseDate(date);
    }

    private static parseDate(str: string): Date {
        var a: any[] = str.split(/[^0-9]/);
        if (a.length == 3) {
            return new Date(a[0], parseInt(a[1])-1, a[2]);
        } else {
            return new Date(a[0], parseInt(a[1]) - 1, a[2], a[3], a[4], a[5]);
        }
    }

    public convertFromServer() {
        this.date.setHours(this.date.getHours() + this.timeOffset());
        return this;
    }

    public convertToServer() {
        this.date.setHours(this.date.getHours() - this.timeOffset());
        return this;
    }


    private addZero(value: number): string {
        return value > 9 ? value.toString() : "0" + value;
    }


    /*
    get moment() {
        return moment(this.date);
    }
     */

    toMysqlFormat() {
        //return this.moment.format("YYYY-MM-DD HH:mm:ss");
        return this.toMysqlFormatDate() + " " + this.toTimeString(true);
    };

    toMysqlFormatDate() {
        //return this.moment.format("YYYY-MM-DD");
        return this.date.getFullYear() + "-" + this.addZero(1 + this.date.getMonth()) + "-" + this.addZero(this.date.getDate());
    };

    toLocaleDateTimeString() {
        //return this.moment.format("L LT");
        return this.date.toLocaleDateString() + " " + this.toTimeString();
    }

    toTimeString(withSeconds = false) {
        return this.addZero(this.date.getHours()) + ":" + this.addZero(this.date.getMinutes()) + (withSeconds ? (":" + this.addZero(this.date.getSeconds())) : "") ;
    }

    toLocaleDateString() {
        //return this.moment.format("L");
        return this.date.toLocaleDateString();
    }

    timeOffset(){
        if (timeOffsetCache == null) {
            timeOffsetCache = (-(new Date()).getTimezoneOffset() / 60) - 3;
        }
        return timeOffsetCache;
    }


    fromNow() {
        return this.toTimeString(false);
    }

    get dateObject() {
        return this.date;
    }
}
