import Dictionary from "~/ts/library/Dictionary";

export const VK_GROUP = "vkGroup";
export const TELEGRAM = "telegram";
export const VIBER = "viber";
export const WHATSAPP = "whatsappOfficial";

export abstract class AbstractPlatform {
    abstract get linkSelectors(): string[];

    public static getById(id: string): AbstractPlatform | null {
        return this.getDictionary()[id];
    }

    public static getDictionary(): Dictionary<AbstractPlatform> {
        return {
            [VK_GROUP]: new VkGroup(),
            [TELEGRAM]: new Telegram(),
            [VIBER]: new Viber(),
            [WHATSAPP]: new Whatsapp()
        }
    }
}

class VkGroup extends AbstractPlatform {
    get linkSelectors(): string[] {
        return [
            `a[href^="https://vk.com/"]`,
            `a[href^="https://vk.me/"]`
        ];
    }
}

class Telegram extends AbstractPlatform {
    get linkSelectors(): string[] {
        return [`a[href^="https://t.me/"]`];
    }

}

class Viber extends AbstractPlatform {
    get linkSelectors(): string[] {
        return [`a[href^="viber://"]`];
    }
}

class Whatsapp extends AbstractPlatform {
    get linkSelectors(): string[] {
        return [
            'a[href*="//wa.me"]',
            'a[href*="//api.whatsapp.com/send"]',
            'a[href*="//web.whatsapp.com/send"]',
            'a[href^="whatsapp://send"]'
        ];
    }
}
