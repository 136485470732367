import {WidgetStore} from "~/chat/ts/store/Widget";
import {TAB_TYPE_REVIEWS} from "~/chat/ts/data/ITab";

export default function openReviewsTab(form: boolean = false) {
    if (form) {
        WidgetStore.openSendReview();
        if (!WidgetStore.isOpened.value) {
            WidgetStore.open();
        }
    } else {
        WidgetStore.openByType({
            type: TAB_TYPE_REVIEWS
        });
    }
}