import {WidgetStore} from "~/chat/ts/store/Widget";
import WindowHelper from "~/ts/library/WindowHelper";

export default function setPosition(x: number = null, y: number = null) {
    let windowSize = WindowHelper.getSize();

    let dWidth = windowSize.width - WidgetStore.width.value;
    let dHeight = windowSize.height - WidgetStore.height.value;
    if (x == null && y == null) {
        x = dWidth / 2;
        y = dHeight / 2;
    } else {
        if (x < 0) {
            x += dWidth;
        }
        if (y < 0) {
            y += dHeight;
        }
    }
    if (x != null) {
        WidgetStore.updatePosition({type: "left", value: x});
    }
    if (y != null) {
        WidgetStore.updatePosition({type: "top", value: y});
    }

    WidgetStore.SET_POSITION_CHANGED_MANUALY();
}