import {StorageInstance} from "../storage/StorageFactory";
import LocalStorage from "../storage/LocalStorage";
import WebSocketChannel from "./channels/WebSocketChannel";
import CrossTabMessage from "../crossTabCommunication/talk/CrossTabMessage";
import CrossTabSender from "../crossTabCommunication/talk/CrossTabSender";
import EventManager from "../EventManager";
import Request from "../xhr/Request";
import Random from "~/ts/library/Random";
import {Timeouts} from "~/ts/library/delay/Timeouts";
import Listener from "~/ts/library/listen/Listener";

let eventManager = new EventManager();

export default class NodeRequest {
    private url: string;
    private apiHost: string;
    private body: string;
    private method = "POST";
    private timeout = 60000;
    private retry: number = 0;
    private listener: Listener;

    constructor(listener: Listener, url: string, apiHost: string) {
        this.apiHost = apiHost;
        this.listener = listener
        url = `/comet${url}`;
        url += url.indexOf('?') == -1 ? '?' : '&';
        url += `requestId=${Random.uid(40)}`;
        this.url = url;

    }

    public static get eventManager(): EventManager {
        return eventManager;
    }

    setBody(body: any) {
        if (typeof body == "object") {
            body = JSON.stringify(body);
        }
        this.body = body;
        return this;
    }

    public send(waitingForAnswer: boolean = true): Promise<string> {
        this.retry = 0;
        return new Promise(async (resolve, reject) => {
            let callbackId = Random.uid(10);
            if (waitingForAnswer) {
                eventManager.once(callbackId, data => resolve(data), this.timeout, () => reject(`timeout`));
            }
            if (StorageInstance instanceof LocalStorage && WebSocketChannel.isAvailable()) {
                this.sendToSocket(callbackId).then((sended) => {
                    if (!sended) {
                        let message = new CrossTabMessage(
                            "websocketAjax",
                            this.getTextForWebsocket(callbackId),
                            2000
                        );
                        CrossTabSender.send(message).then(() => {
                        }, async () => {
                            try {
                                await this.sendByHttp(callbackId);
                                if (!waitingForAnswer) {
                                    resolve(null);
                                }
                            } catch (e) {
                                reject(e);
                            }
                        });
                    } else {
                        if (!waitingForAnswer) {
                            resolve(null);
                        }
                    }
                });
            } else {
                try {
                    await this.sendByHttp(callbackId);
                    if (!waitingForAnswer) {
                        resolve(null);
                    }
                } catch (e) {
                    reject(e);
                }
            }
        });
    }

    private async sendByHttp(callbackId: string) {
        let request = new Request(this.method, this.apiHost + this.url);
        if (this.body != null) {
            request.setData(this.body)
        }

        try {
            let requestResult = await request.send();
            eventManager.emit(callbackId, requestResult.getRawResponse());
        } catch (e) {
            throw e;
        }
    }

    private getTextForWebsocket(callbackId: string): string {
        return JSON.stringify({
            action: "ajax",
            data: {
                url: this.url,
                callbackId,
                data: this.body,
                method: this.method,
            }
        });
    }

    private sendToSocket(callbackId: string): Promise<boolean> {
        let that = this;
        return new Promise<boolean>((resolve) => {
            let text = that.getTextForWebsocket(callbackId);
            NodeRequest.sendTextToSocket(this.listener, text).then(resolve);
        });
    }

    public static sendTextToSocket(listener: Listener, text: string, retry: number = 200): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let channel = listener.getChannel();
            if (channel instanceof WebSocketChannel) {
                if (channel.send(text)) {
                    return resolve(true);
                }
                if (retry > 0) {
                    return Timeouts.set(function () {
                        NodeRequest.sendTextToSocket(listener, text, retry - 1).then(resolve);
                    }, 10);
                } else {
                    resolve(false);
                }
            } else {
                resolve(false);
            }
        });
    }
}
