import AutoActionGroup, {IAutoActionGroupSuccessRunResult} from "~/chat/ts/service/autoActions/AutoActionGroup";
import CrossTabLock from "~/ts/library/crossTabCommunication/CrossTabLock";
import Delay from "~/ts/library/Delay";
import Random from "~/ts/library/Random";
import ConsoleWrapper from "~/ts/library/Console";
import {ACTION_TYPE_DESTROY_WIDGET, ACTION_TYPE_HIDE_TRIGGER} from "~/chat/ts/data/AutoActions";
import {TriggerStore} from "~/chat/ts/store/Trigger";
import ChatNodeRequest from "~/chat/ts/service/ChatNodeRequest";
import {INSTANCE_ID} from "~/ts/library/InstanceId";
import TrackIsOnTab from "~/ts/library/crossTabCommunication/TrackIsOnTab";

let locker = new CrossTabLock("AutoAction");
locker.setOnlyOnCurrentTab(true);

const HIDE_TRIGGER_REASON = "waitForAutoAction";
let showTriggerAfterRun = false;


export default class AutoActionRunner {
    private static started: boolean = false;
    private static queue: AutoActionGroup[] = [];

    public static async add(autoActionGroup: AutoActionGroup) {
        if (this.queue.indexOf(autoActionGroup) == -1) {
            if ([ACTION_TYPE_DESTROY_WIDGET, ACTION_TYPE_HIDE_TRIGGER].indexOf(autoActionGroup.getActionTypeId()) > -1) {
                TriggerStore.hideTrigger(HIDE_TRIGGER_REASON);
                showTriggerAfterRun = true;
            }
            this.queue.push(autoActionGroup);
            this.run();
        }
    }

    private static async run() {
        if (this.queue.length) {
            if (!this.started) {
                this.started = true;
                if (await locker.lock()) {
                    let runId = Random.uid();
                    let results: IAutoActionGroupSuccessRunResult[] = [];
                    try {
                        while (this.queue.length > 0) {
                            let autoActionGroup = this.queue.shift();

                            let runResult = await autoActionGroup.run(runId);
                            if (runResult) {
                                results.push(runResult);
                            }
                        }
                    } catch (e) {
                        ConsoleWrapper.error("AutoAction run", e);
                    }
                    if (results.length) {
                        this.sendActionResultsToServer(results);
                    }

                    if (showTriggerAfterRun) {
                        TriggerStore.showTrigger(HIDE_TRIGGER_REASON);
                    }

                    await locker.unlock();
                }
                this.started = false;
                await Delay.make(Random.randomNumber(1, 100));
                this.run();
            }
        }
    }

    private static async sendActionResultsToServer(results: IAutoActionGroupSuccessRunResult[]) {
        let body: CometWidget.CometClientRegAutoActionBodyOpenApiModel = {
            ...ChatNodeRequest.getClientCredentials(),
            actions: []
        }


        for (let groupResult of results) {
            let timeout = 0;
            for (let actionResult of groupResult.results) {
                timeout += actionResult.timeout;
                body.actions.push({
                    ...actionResult,
                    ...{
                        currentUrl: window.location.href,
                        instanceId: INSTANCE_ID,
                        timestamp: (new Date()).getTime(),
                        isLocked: locker.check(),
                        isOnTab: TrackIsOnTab.isOnTab(),
                        randomUid: Random.uid()
                    },
                    type: groupResult.actionType,
                    actionID: groupResult.actionId,
                    timeout: timeout
                });
            }
        }
        try {
            await (new ChatNodeRequest("/regAutoAction"))
                .setBody(body)
                .send();
        } catch (e) {
            ConsoleWrapper.log("Erro on reg auto action", e);
        }

    }
}