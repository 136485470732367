import StorageInterface from "./StorageInterface";
import LocalStorage from "./LocalStorage";
import SessionStorage from "./SessionStorage";
import FallbackStorage from "./FallbackStorage";

let storage: StorageInterface = new LocalStorage();
if (!storage.isAvailable()) {
    storage = new SessionStorage();
    if (!storage.isAvailable()) {
        storage = new FallbackStorage();
    }
}

export const StorageInstance: StorageInterface = storage;