import AbstractReceiveOperatorMessageListener from "~/chat/ts/service/listen/AbstractReceiveOperatorMessageListener";

export default class AddToHistoryListener extends AbstractReceiveOperatorMessageListener {
    public getAction(): string {
        return "addToHistory";
    }

    protected isMarkAsUnreaded(): boolean {
        return false;
    }
}