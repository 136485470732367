import {ClientStore} from "~/chat/ts/store/Client";
import Delay from "~/ts/library/Delay";

export default function getClientSearchId(callback: (id: number) => void) {
    let make = (async () => {
        let id = ClientStore.getCc();
        if (id) {
            callback(id);
        } else {
            await Delay.make(100);
            make();
        }
    });

    make();
}