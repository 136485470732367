import {WidgetStore} from "~/chat/ts/store/Widget";
import Dictionary from "~/ts/library/Dictionary";

export default function setDimensions(width: number = null, height: number = null) {
    let values: Dictionary<number> = {width, height};
    for (let key in values) {
        if (values.hasOwnProperty(key)) {
            if (values[key] > 0) {
                WidgetStore.updateSize({
                    type: key as "width" | "height",
                    value: values[key]
                });
            }
        }
    }
}