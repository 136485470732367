import {StorageInstance} from "../storage/StorageFactory";
import EventManager from "../EventManager";
import ListenerMessage from "./ListenerMessage";
import Dictionary from "~/ts/library/Dictionary";
import CometTimestampMismatchPayloadInterface from "~/ts/library/listen/CometTimestampMismatchPayloadInterface";

const CLOSE = "close";
const MESSAGE = "message";
const PREVIOUS_TIMESTAMP_MISMATCH = "previousTimestampMismatch";

let emitTimestampErrorOnNullPreviousTimestamp: Dictionary<boolean> = {};

abstract class AbstractListenChannel {
    protected uid: string;
    //protected orgId: string;
    //protected siteId: string;
    //protected clientId: string;
    protected lastMessageTimestamp: number = 0;
    private events: EventManager;
    protected lastMessageTimestampByUid: Dictionary<number> = {};
    private lastMessage: any;

    private getTimeStampKey() {
        return "Listen/Timestamp/" + this.uid;
    }


    protected SERVER_UTC_DELTA = "serverUTCDelta";

    abstract get typeId(): string;

    constructor(uid: string, currentCometTimestamp?: number) {
        this.events = new EventManager();
        this.uid = uid;

        if (currentCometTimestamp) {
            this.setLastMessageTimeStamp(uid, currentCometTimestamp, null, null, false);
        }

        let timestamp = StorageInstance.get(this.getTimeStampKey());
        this.setLastMessageTimeStamp(uid, timestamp ? parseInt(timestamp) : 0, null, null, false);
    }

    public abstract startListen(): Promise<void>;

    protected checkPreviousMessageTimeStamp(uid: string, previousTimestamp: number, timestamp: number, message: any) {
        let lastTimestampByUid = this.lastMessageTimestampByUid[uid];

        if (
            (previousTimestamp && lastTimestampByUid && previousTimestamp != lastTimestampByUid)
            ||
            (!previousTimestamp && emitTimestampErrorOnNullPreviousTimestamp[uid])
        ) {
            let payload: CometTimestampMismatchPayloadInterface = {
                uid,
                timestamp,
                message,
                previousTimestamp,
                lastTimestamp: lastTimestampByUid,
                lastMessage: this.lastMessage
            };
            this.events.emit(PREVIOUS_TIMESTAMP_MISMATCH, payload);

        }
        emitTimestampErrorOnNullPreviousTimestamp[uid] = false;
    }

    protected setLastMessageTimeStamp(uid: string, timestamp: number, message: any, previousTimestamp?: number, check: boolean = true) {
        if (timestamp > this.lastMessageTimestamp) {
            StorageInstance.set(this.getTimeStampKey(), timestamp);
            if (check) {
                this.checkPreviousMessageTimeStamp(uid, previousTimestamp, timestamp, message);
            }
            this.lastMessage = message;
            this.lastMessageTimestamp = timestamp;
            this.lastMessageTimestampByUid[uid] = timestamp;
        }
    }

    public onMessage(callback: (message: ListenerMessage) => void) {
        this.events.addEventListener(MESSAGE, callback);
    }

    public onClose(callback: (error: boolean) => void) {
        this.events.addEventListener(CLOSE, callback);
    }

    public onPreviousTimestampMismatch(callback: (payload: CometTimestampMismatchPayloadInterface) => void) {
        this.events.addEventListener(PREVIOUS_TIMESTAMP_MISMATCH, callback);
    }

    protected close(error: boolean) {
        if (error) {
            emitTimestampErrorOnNullPreviousTimestamp[this.uid] = true;
        }
        this.events.emit(CLOSE, error);
        this.events.removeAllEventListeners();
    }

    protected receiveMessage(message: ListenerMessage) {
        this.events.emit(MESSAGE, message);
    }

    public async destroy() {

    }

    get serverNow(): number {
        let delta = StorageInstance.get(this.SERVER_UTC_DELTA);
        let now = Date.now();
        if (delta) {
            now -= delta;
        }
        return now;
    }
}

export default AbstractListenChannel;