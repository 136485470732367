
export default async function fetchReviews(payload: {page?: number, limit?: number, allSites: boolean}, callback: (result: any) => void) {
    let ReviewsFetcher = (await (() => import(/* webpackChunkName: "widget" */ '~/chat/ts/service/reviews/ReviewsFetcher'))() as any).default;

    let result = await (new ReviewsFetcher())
        .setAllSites(payload.allSites)
        .setPage(payload.page)
        .setOnPage(payload.limit)
        .fetch();

    callback(result);
}