import AudioNotification from "~/ts/library/AudioNotification";
import {WIDGET_SERVER} from "~/chat/ts/CommonConstants";

export default class ChatAudioNotification extends AudioNotification {
    public static playById(id: string, volume: number = 1) {
        if (id && id.length) {

            this.play(`${WIDGET_SERVER}/support/sounds/${id}.mp3`, volume);
        }
    }
}