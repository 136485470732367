var api = require("!../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../../node_modules/css-loader/index.js??ref--1-1!../../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../../node_modules/postcss-loader/dist/cjs.js!../../../../node_modules/string-replace-webpack-plugin/loader.js?id=ajqfqtprpdi!../../../../node_modules/sass-loader/dist/cjs.js??ref--1-4!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!../../../../node_modules/string-replace-webpack-plugin/loader.js?id=l1v2fppg28!./ResizeHelper.vue?vue&type=style&index=0&id=20bbf0c9&prod&scoped=true&lang=scss&");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {"injectType":"singletonStyleTag","attributes":{"id":"online-chat-root-styles"}};

options.insert = function insertAtTop(element) {
                                    var parent = document.querySelector('head');
                                    if (parent) {
                                        var firstElement = parent.firstElementChild;
                                        if (firstElement) {
                                            parent.insertBefore(element, firstElement);
                                        }
                                        else {
                                            parent.appendChild(element);
                                        }
                                    }
                                };
options.singleton = true;

var update = api(content, options);

var exported = content.locals ? content.locals : {};



module.exports = exported;