export default class FiltersCheckResult {
    private success: boolean;
    private emptyFilterError: boolean;

    constructor(success: boolean, emptyFilterError: boolean = false) {
        this.success = success;
        this.emptyFilterError = emptyFilterError;
    }

    get isSuccess(): boolean {
        return this.success;
    }

    get isEmptyFilterError(): boolean {
        return this.emptyFilterError;
    }
}