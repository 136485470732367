import {Timeouts} from "~/ts/library/delay/Timeouts";
import {ChatEventManagerInstance} from "~/chat/ts/ChatEventManager";
import {CHAT_EVENT_MOUSE_MOVE_ON_WIDGET_INNER_ROOT} from "~/chat/ts/Constants";
import {WidgetStore} from "~/chat/ts/store/Widget";

export default class WidgetAutoClose {
    private static autoCloseTimeout: number;

    public static init(delay: number) {
        this.clearAutoClose();
        this.autoCloseTimeout = Timeouts.set(() => {
            WidgetStore.close();
        }, delay);
    }

    public static clearAutoClose() {
        if (this.autoCloseTimeout) {
            Timeouts.clear(this.autoCloseTimeout);
            this.autoCloseTimeout = null;
        }
    }

}

ChatEventManagerInstance.addEventListener(CHAT_EVENT_MOUSE_MOVE_ON_WIDGET_INNER_ROOT, () => WidgetAutoClose.clearAutoClose());
