import AbstractStringOperation from "~/chat/ts/service/autoActions/operations/AbstractStringOperation";
import ConsoleWrapper from "~/ts/library/Console";
//import {ACTION_FILTER_PREVIOUS_URL, ACTION_FILTER_URL} from "~/chat/ts/data/AutoActions";

export default class OperationRegexpMatch extends AbstractStringOperation {
    /*
    protected prepareCheckValue(filterName: string, checkValue: any[]): any[] {
        if ([ACTION_FILTER_URL, ACTION_FILTER_PREVIOUS_URL].indexOf(filterName) > -1) {
            for (let i = 0; i < checkValue.length; i++) {
                checkValue[i] = encodeURI(decodeURI(checkValue[i]));
            }
        }
        return checkValue;
    }
    */

    protected checkItem(item: string, currentValue: string): boolean {
        try {
            let regexp = new RegExp(item);
            let matchResult = currentValue.match(regexp) != null;
            if (matchResult) {
                return true;
            }
        } catch (e) {
            if (window.console) {
                ConsoleWrapper.log("Regexp exception", item, e);
            }
        }
        return false;
    }
}