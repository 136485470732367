import {Intervals} from "~/ts/library/delay/Intervals";
import {isInternationalVersionUsed} from "~/chat/ts/CommonConstants";
import {ConfigStore} from "~/chat/ts/store/Config";
import {ClientStore} from "~/chat/ts/store/Client";
import {AbstractPlatform} from "~/chat/ts/data/IntergrationPlatforms";

let platforms = AbstractPlatform.getDictionary();
export default class IntegrationLinks {

    private static disposeInterval: () => void = null;

    public static init(): void {
        if (this.disposeInterval) {
            this.disposeInterval();
        }
        if (ConfigStore.siteParams.value.params.siteChatOptions.isFillMessengerLinkContext) {
            this.disposeInterval = Intervals.setDisposable(() => {
                this.prepareLinks();
            }, 1000);
            this.prepareLinks();
        }
    }

    private static prepareLinks(): void {
        for (let platformId in platforms) {
            if (platforms.hasOwnProperty(platformId)) {
                let selectors = platforms[platformId].linkSelectors;
                if (selectors.length) {
                    let links = document.querySelectorAll(selectors.join(","));

                    for (let i = 0; i < links.length; i++) {
                        let link: HTMLLinkElement = links[i] as any;
                        if (link.href) {
                            link.href = this.makeLink(platformId, link.href);
                        }

                    }
                }
            }
        }
    }

    public static makeLink(platformId: string, url: string) {
        if (!isInternationalVersionUsed() && !url.includes("m.sitehelp.me")) {
            let siteId = encodeURIComponent(ConfigStore.siteId.value);
            let clientId = encodeURIComponent(ClientStore.id.value);
            url = encodeURIComponent(url);
            url = `https://m.sitehelp.me/${platformId}?siteId=${siteId}&clientId=${clientId}&url=${url}`;
        }
        return url;
    }
}