import AbstractListenChannel from "./AbstractListenChannel";
import WebSocketChannel from "./channels/WebSocketChannel";
import LongPollingChannel from "./channels/LongPollingChannel";
import EventManager, {IAnyEvent} from "../EventManager";
import ListenerMessage from "./ListenerMessage";
import Random from "../Random";
import CrossTabLock from "../crossTabCommunication/CrossTabLock";
import CacheChannel from "./channels/CacheChannel";
import {StorageInstance} from "../storage/StorageFactory";
import LocalStorage from "../storage/LocalStorage";
import {Intervals} from "~/ts/library/delay/Intervals";
import Delay from "~/ts/library/Delay";
import AbstractListenerAction from "~/ts/library/listen/AbstractListenerAction";
import CometTimestampMismatchPayloadInterface from "~/ts/library/listen/CometTimestampMismatchPayloadInterface";

const PREVIOUS_TIMESTAMP_MISMATCH = "previousTimestampMismatch";
const CONNECTING = "connecting";
const CONNECTED = "connected";
const ERROR = "error";

export default class Listener {
    private channel?: AbstractListenChannel;
    private events = new EventManager();
    private locker = new CrossTabLock("Comet");
    private uid: string;
    private websocketUrl: string;
    private longPollUrl: string;
    private cacheChannel?: CacheChannel;
    private destroyed: boolean = false;
    private cacheChannelAvailable: boolean = true;

    setCacheChannelAvailable(value: boolean) {
        this.cacheChannelAvailable = value;
        return this;
    }

    get isCacheChannelAvailable() {
        return this.cacheChannelAvailable;
    }


    private async createChannel(rewrite: boolean = false, currentCometTime?: number): Promise<AbstractListenChannel> {
        let result: AbstractListenChannel;

        if ((!this.channel || rewrite) && !this.destroyed) {
            let lock = await this.locker.lock();
            this.events.emit(CONNECTING);
            let newChannel = true;
            if (lock || !(StorageInstance instanceof LocalStorage) || !this.cacheChannelAvailable) {
                if (WebSocketChannel.isAvailable() && this.websocketUrl) {
                    result = new WebSocketChannel(this.uid, this.websocketUrl, currentCometTime);
                } else {
                    result = new LongPollingChannel(this.uid, this.longPollUrl, currentCometTime);
                }
            } else {
                if (this.cacheChannel == null) {
                    this.cacheChannel = new CacheChannel(this.uid);
                    let cacheChannelCloseInterval = Intervals.set(async () => {
                        let cacheChannel = this.cacheChannel;
                        if (cacheChannel && cacheChannel == this.channel) {
                            let newChannel = await this.createChannel(true);
                            if (newChannel) {
                                if (cacheChannel != newChannel) {
                                    this.cacheChannel = null;
                                    cacheChannel.close(true);
                                    Intervals.clear(cacheChannelCloseInterval);
                                }
                            }
                        }
                    }, 2000);
                } else {
                    newChannel = false;
                }
                result = this.cacheChannel;
            }
            this.channel = result;
            this.channel.onPreviousTimestampMismatch((payload: CometTimestampMismatchPayloadInterface) => {
                this.events.emit(PREVIOUS_TIMESTAMP_MISMATCH, payload);
            });

            if (newChannel) {
                result.onClose((error) => {
                    if (this.channel == result) {
                        this.onChannelClose(error);
                    }
                });

                result.onMessage((message: ListenerMessage) => {
                    this.errorCount = 0;
                    if (this.channel == result) {
                        this.events.emit(Listener.getActionEventName(message.getAction()), message);
                    }
                });
                try {
                    await result.startListen();
                } catch (e) {
                    await Delay.make(Random.randomNumber(5000, 15000));
                    return await this.createChannel();
                }
            }
            this.events.emit(CONNECTED);
        }

        return result;
    }


    public onPreviousTimestampMismatch(callback: (payload: CometTimestampMismatchPayloadInterface) => void) {
        this.events.addEventListener(PREVIOUS_TIMESTAMP_MISMATCH, callback);
    }

    public onConnecting(callback: () => void) {
        this.events.addEventListener(CONNECTING, callback);
    }

    public onConnected(callback: () => void) {
        this.events.addEventListener(CONNECTED, callback);
    }

    public onError(callback: (error: string) => void) {

        this.events.addEventListener(ERROR, callback);
    }

    public isCacheChannel(): boolean {
        return this.channel == this.cacheChannel;
    }

    public get channelTypeId(): string {
        return this.channel ? this.channel.typeId : null;
    }

    public getChannel(): AbstractListenChannel | null {
        return this.channel;
    }

    private static getActionEventName(action: string) {
        return "action-" + action;
    }

    public onAction(action: string, callback: (message: ListenerMessage) => void): Function {
        return this.events.addEventListener(Listener.getActionEventName(action), callback);
    }

    public onAnyAction(callback: (message: IAnyEvent) => void): Function {
        return this.events.addAnyEventListener(callback);
    }

    public async start(uid: string, websocketUrl: string, longPollUrl: string, currentCometTime?: number, restoreDestroyed?: boolean) {
        if (restoreDestroyed) {
            this.destroyed = false;
        }
        if (!this.channel && !this.destroyed) {
            this.uid = uid;
            this.websocketUrl = websocketUrl;
            this.longPollUrl = longPollUrl;

            await this.createChannel(null, currentCometTime);
        }
    }

    public async destroy() {
        this.destroyed = true;
        if (this.channel) {
            this.channel.destroy();
            this.channel = null;
        }
    }

    private errorCount = 0;

    private async onChannelClose(error: boolean) {
        if (error) {
            this.events.emit(ERROR, error);
        }
        this.channel = null;
        if (!this.destroyed) {
            if (error) {
                this.errorCount++;
            }

            //если ошибка впервые,то пробуем переподключиться сразу же (возможно - не работает вебсокет)
            if (this.errorCount > 1) {
                let delay = error ? Random.randomNumber(5000, 15000) : 200;
                try {
                    await Delay.make(delay);
                } catch (e) {

                }
            }

            this.start(this.uid, this.websocketUrl, this.longPollUrl);
        }
    }

    public listenAction(action: AbstractListenerAction) {
        this.disposableListenAction(action);
        return this;
    }

    public disposableListenAction(action: AbstractListenerAction): Function {
        return this.onAction(action.getAction(), payload => action.onAction(payload.originalMessage, payload));
    }

}