import AbstractListenerAction from "~/ts/library/listen/AbstractListenerAction";
import MessageStorage from "~/chat/ts/service/messages/MessageStorage";

export default class MessageRead extends AbstractListenerAction {
    public getAction(): string {
        return "messageRead";
    }

    public onAction(payload: { id: number }): void {
        MessageStorage.setMessageReaded(payload.id, false);
    }
}