import {StorageInstance} from "~/ts/library/storage/StorageFactory";
import IMessage, {MESSAGE_TYPE, MESSAGE_TYPE_AUTO_MESSAGE, MESSAGE_TYPE_TAB} from "~/chat/ts/data/chat/IMessage";
import Filters from "~/chat/ts/service/autoActions/Filters";
import {
    ACTION_FILTER_CLIENT_MESSAGES_COUNT_AT_VISIT,
    ACTION_FILTER_LAST_MESSAGE_SENDER,
    ACTION_FILTER_MESSAGES_COUNT_AT_VISIT,
    ACTION_FILTER_NEW_MESSAGE_FROM_CLIENT,
    ACTION_FILTER_NEW_MESSAGE_FROM_OPERATOR,
    ACTION_FILTER_OPERATOR_MESSAGES_COUNT_AT_VISIT,
    ACTION_FILTER_TIME_FROM_LAST_MESSAGE,
    ACTION_FILTER_TIME_FROM_LAST_MESSAGE_FROM_CLIENT,
    ACTION_FILTER_TIME_FROM_LAST_MESSAGE_FROM_OPERATOR
} from "~/chat/ts/data/AutoActions";
import MessageWrapper from "~/chat/ts/data/chat/MessageWrapper";
import {OperatorsStore} from "~/chat/ts/store/Operators";
import {IOperator} from "~/chat/ts/data/IOperator";
import {ConfigStore} from "~/chat/ts/store/Config";
import ConsoleWrapper from "~/ts/library/Console";
import {toggleArrayElement} from "~/ts/library/ToggleArrayElement";
import ChatNodeRequest from "~/chat/ts/service/ChatNodeRequest";
import ArrayHelper from "~/ts/library/ArrayHelper";
import {ListenerInstance} from "~/chat/ts/service/ListenerInstance";
import Delay from "~/ts/library/Delay";

const MESSAGE_ID_LIST_KEY = "MessageIdList";

export default class MessageStorage {
    private static getMessageKey(messageId: number): string {
        return "Message/" + messageId;
    }

    private static getUnreadedMessageIdsKey(): string {
        return "UnreadedMessages";
    }

    static get messageIdList(): number[] {
        let ttl = ConfigStore.siteParams.value.params.siteChatOptions.forgetHistory;
        let list = StorageInstance.getex(MESSAGE_ID_LIST_KEY);
        let currentTtl = StorageInstance.getCurrentTtl(MESSAGE_ID_LIST_KEY);
        let set = list == null || (ttl && (!currentTtl || currentTtl / 1000 > ttl));

        if (list == null) {
            list = [];
        }
        if (set) {
            this.messageIdList = list;
            return this.messageIdList;
        }
        return list;
    }

    static set messageIdList(list: number[]) {
        let ttl = ConfigStore.siteParams.value.params.siteChatOptions.forgetHistory;
        let sortedList = [...list]
            .sort((a, b) => {
                if (a > 0 && b > 0) {
                    return Math.abs(a) > Math.abs(b) ? 1 : -1
                } else {
                    let message1 = this.getMessage(a);
                    let message2 = this.getMessage(b);
                    if (message1 && message2) {
                        return message1.dt > message2.dt ? 1 : -1;
                    } else {
                        return 0;
                    }
                }
            });
        if (JSON.stringify(sortedList) != JSON.stringify(list)) {
            list = sortedList;
        }
        list = ArrayHelper.unique(list);
        if (ttl > 0) {
            StorageInstance.setex(MESSAGE_ID_LIST_KEY, list, ttl * 1000);
        } else {
            StorageInstance.set(MESSAGE_ID_LIST_KEY, list);
        }
    }

    private static removePreviousMessageWithSuggests(): boolean {
        let lastMessageId = this.lastMessageId;
        if (typeof lastMessageId == "number") {
            let lastMessage = this.getMessage(lastMessageId);
            if (lastMessage) {
                let lastMessageWrapper = new MessageWrapper(lastMessage);
                let buttons = lastMessageWrapper.buttons;
                if (buttons.length) {
                    if (lastMessageWrapper.isHiddenText) {
                        this.removeMessage(lastMessageId);
                        return true;
                    } else {
                        buttons.splice(0, buttons.length);
                        this.saveMessage(lastMessage);
                    }

                }
            }
        }
        return false;
    }

    public static get typingOperator(): IOperator | null {
        let payload: OperatorTypingPayloadInterface = StorageInstance.getex(OPERATOR_TYPING);
        let result: IOperator;
        if (payload) {
            let operatorId = payload.operatorId;
            if (operatorId) {
                result = OperatorsStore.getOperatorById(operatorId);
            } else if (payload.operatorName) {
                result = payload.isBot ? OperatorsStore.getBotOperator() : OperatorsStore.getVirtualOperator(payload.operatorName, ConfigStore.siteParams.value.params.operators.balance.smart.avatar);
            }
        }
        return result;
    }

    public static markAsUnreaded(message: IMessage) {
        let wrapper = new MessageWrapper(message);
        if (wrapper.isNeedMarkAsUnreaded) {
            let list = this.getUnreadedMessageIds();
            if (list.indexOf(wrapper.id) == -1) {
                list.push(wrapper.id);
                this.saveUnreadedMessageIds(list);
            }
        }
    }

    public static markAsReaded(message: IMessage) {
        let wrapper = new MessageWrapper(message);
        if (!wrapper.isFromClient) {
            let list = this.getUnreadedMessageIds();
            let count = list.length;
            list = list.filter(id => id > message.id);
            if (list.length != count) {
                this.saveUnreadedMessageIds(list);
            }
        }
    }


    private static getUnreadedMessageIds(): number[] {
        let result: number[] = StorageInstance.get(this.getUnreadedMessageIdsKey());
        if (result == null) {
            result = [];
            this.saveUnreadedMessageIds(result);
        }
        let changed = false;
        for (let i = 0; i < result.length; i++) {
            let id = result[i];
            let message = this.getMessage(id);
            if (!message || (new MessageWrapper(message)).isFromClient) {
                result.splice(i, 1);
                i--;
                changed = true;
            }
        }
        if (changed) {
            this.saveUnreadedMessageIds(result);
        }
        return result;
    }

    public static getUnreadedMessages(): IMessage[] {
        let result: IMessage[] = [];
        for (let id of this.getUnreadedMessageIds()) {
            let message = this.getMessage(id);
            if (message) {
                result.push(message);
            }
        }
        return result;
    }

    public static isMessageUnreaded(message: IMessage): boolean {
        return !!this.getUnreadedMessages().find(item => item.id === message.id);
    }

    public static getMessagesForNotifications(): IMessage[] {
        return this.getUnreadedMessages().filter(message => {
            return !message.hideNotification;
        });
    }

    public static hideMessageFromNotification(message: IMessage) {
        message.hideNotification = true;
        this.saveMessage(message);
    }

    public static getUnreadedMessageCount(): number {
        return this.getUnreadedMessageIds().length;
    }

    public static getMessageCountForBadge(): number {
        return MessageStorage.getUnreadedMessages().filter(message => !message.hideBadge).length;
    }

    public static markAllMessagesAsReaded() {
        this.saveUnreadedMessageIds([]);
    }

    private static saveUnreadedMessageIds(list: number[]) {
        StorageInstance.set(this.getUnreadedMessageIdsKey(), list);
    }

    private static clearUnreadedMessageIds(lastReadedMessageId: number) {
        let unreadedMessages = this.getUnreadedMessages();
        let newUnreadedMessageIds = [];
        for (let message of unreadedMessages) {
            if (message.id <= lastReadedMessageId) {
                delete message.hideNotification;
                this.saveMessage(message);
            } else {
                newUnreadedMessageIds.push(message.id);
            }
        }
        this.saveUnreadedMessageIds(newUnreadedMessageIds);
    }

    static getMessage(messageId: number): IMessage | null {
        let key = this.getMessageKey(messageId);
        return StorageInstance.getex(key);
    }

    static get lastMessageId(): number | null {
        return this.messageIdList.slice(-1)[0];
    }

    static get lastClientMessageId(): number | null {
        let messageIdList = this.messageIdList;
        for (let i = messageIdList.length - 1; i >= 0; i--) {
            let message = this.getMessage(messageIdList[i]);
            if (message && (new MessageWrapper(message)).isFromClient) {
                return message.id;
            }
        }
        return null;
    }

    static get lastMessage() {
        let lastMessageId = this.lastMessageId;
        if (lastMessageId) {
            return this.getMessage(lastMessageId);
        }
        return null;
    }

    static updateMessageId(message: IMessage, newId: number) {
        this.replaceMessageId(message.id, newId);
        this.removeMessage(message.id);
        let messageInStorage = this.getMessage(newId);
        if (!messageInStorage) {
            message.id = newId;
            this.saveMessage(message);
        } else {
            messageInStorage.key = message.key;
            this.saveMessage(messageInStorage);
        }
    }

    static removeMessage(messageId: number) {
        this.replaceMessageId(messageId);
        StorageInstance.remove(this.getMessageKey(messageId));
    }

    static removeByTabId(tabId: string) {
        let list = this.messageIdList;
        for (let messageId of list) {
            let message = this.getMessage(messageId);
            if (message) {
                if (message.messageType == MESSAGE_TYPE_TAB) {
                    if (message.other?.tab?.id === tabId) {
                        this.removeMessage(messageId);
                    }
                }
            }
        }
    }

    static saveMessage(message: IMessage) {
        if (!message.id) {
            let id = this.maxId;
            if (!id) {
                id = 0;
            }
            id += 0.0003;
            message.id = id;
        }
        if (!message.visitId) {
            message.visitId = ConfigStore.visitId.value;
        }

        let messageKey = this.getMessageKey(message.id);
        let ttlSeconds = message.other?.ttlSeconds;
        if (ttlSeconds > 0) {
            StorageInstance.setex(messageKey, message, ttlSeconds * 1000);
        } else {
            StorageInstance.set(messageKey, message);
        }

        let list = this.messageIdList;
        let wrapper = new MessageWrapper(message);

        if (list.indexOf(message.id) == -1) {
            let temporaryId = wrapper.temporaryId;
            if (!temporaryId || list.indexOf(temporaryId) == -1) {
                if (this.lastMessageId < message.id) {
                    if (this.removePreviousMessageWithSuggests()) {
                        //Получаем список заново на случай, если удалили сообщение с саджестом
                        list = this.messageIdList;
                    }
                }
                if (list.indexOf(message.id) == -1) {
                    list.push(message.id);
                    this.messageIdList = list;
                    if (!message.messageType) {
                        if (!wrapper.isFromClient || wrapper.isOffline) {
                            this.afterNewRealMessage(wrapper);
                        }
                    }
                }
            }
        }

        if (!wrapper.isFromClient) {
            MessageStorage.setOperatorTyping({});
        }
    }

    private static replaceMessageId(prevId: number, newId?: number) {
        let list = this.messageIdList;
        toggleArrayElement(list, prevId, newId != null, newId);
        this.messageIdList = list;
    }


    static removeByText(text: string, messageType: MESSAGE_TYPE) {
        let toRemove: number[] = [];
        if (ConfigStore.siteParams.value.params.siteChatOptions.removeSimilarMessages) {
            let list = this.messageIdList;
            for (let messageId of list) {
                let message = this.getMessage(messageId);
                if (message) {
                    if ((new MessageWrapper(message)).isFromClient) {
                        toRemove = [];
                    } else if (messageType == message.messageType && message.text == text) {
                        toRemove.push(messageId);
                    }
                }
            }
            if (toRemove) {
                for (let messageId of toRemove) {
                    this.removeMessage(messageId);
                }
            }
        }
        return toRemove.length;
    }

    private static sendMessageStatus(id: number, status: "readed" | "delivered") {
        var message = this.getMessage(id);
        if (message && message.messageType != MESSAGE_TYPE_AUTO_MESSAGE) {
            (async () => {
                try {
                    let body: CometWidget.CometSetMessageStatusByClientBodyOpenApiModel = {
                        ...ChatNodeRequest.getClientCredentials(),
                        id,
                        status
                    }
                    await (new ChatNodeRequest("/setMessageStatusByClient"))
                        .setBody(body)
                        .send();
                } catch (e) {
                    ConsoleWrapper.log("Error on send message status", e);
                }
            })();


        }
    }

    public static setMessageDelivered(id: number) {
        return this.sendMessageStatus(id, "delivered");
    }

    public static async setMessageReaded(messageId?: number, send: boolean = true) {

        if (!messageId) {
            let unreaded = this.getUnreadedMessageIds();
            if (unreaded.length) {
                messageId = Math.max(...unreaded);
            }
        }

        if (messageId) {
            //Вставил задержку, чтобы сообщение не прочитывалось моментально и не срабатывал скролл до низа виджета.
            try {
                await Delay.make(400, `setMessageReaded_${messageId}`, false);
                if (send) {
                    this.sendMessageStatus(messageId, "readed");
                }
                this.clearUnreadedMessageIds(messageId);
            } catch (e) {

            }
        }
    }

    public static get maxId(): number | null {
        let idList = this.messageIdList;
        return idList.length ? Math.max(...idList) : null;
    }

    public static get messagesCount(): number {
        let idList = this.messageIdList;
        return idList.length ? idList.length : 0;
    }

    private static async updateFiltersOnNewRealMessage(message: MessageWrapper) {
        let whoSend = message.whoSend;
        Filters.set(ACTION_FILTER_LAST_MESSAGE_SENDER, whoSend);
        Filters.set(ACTION_FILTER_TIME_FROM_LAST_MESSAGE);
        Filters.set(message.isFromClient ? ACTION_FILTER_TIME_FROM_LAST_MESSAGE_FROM_CLIENT : ACTION_FILTER_TIME_FROM_LAST_MESSAGE_FROM_OPERATOR);
        Filters.incr(message.isFromClient ? ACTION_FILTER_CLIENT_MESSAGES_COUNT_AT_VISIT : ACTION_FILTER_OPERATOR_MESSAGES_COUNT_AT_VISIT);
        Filters.incr(ACTION_FILTER_MESSAGES_COUNT_AT_VISIT);
        let filterId = message.isFromClient ? ACTION_FILTER_NEW_MESSAGE_FROM_CLIENT : ACTION_FILTER_NEW_MESSAGE_FROM_OPERATOR;
        Filters.set(filterId, message.text, 2000, null);
    }

    public static async setOperatorTyping(payload: OperatorTypingPayloadInterface) {
        if (!ConfigStore.siteParams.value.params.siteChatOptions.hideOperatorTyping) {
            if (payload.timeout == null || payload.timeout < 1) {
                payload.timeout = 5000;
            }
            StorageInstance.setex(OPERATOR_TYPING, payload, payload.timeout);
        }
    }

    public static get isOperatorTyping() {
        return this.typingOperator != null;
    }

    public static afterNewRealMessage(wrapper: MessageWrapper) {

        this.updateFiltersOnNewRealMessage(wrapper);

        let operatorId = wrapper.operatorId;
        if (operatorId) {
            let operator = OperatorsStore.getOperatorById(operatorId);
            if (operator) {
                OperatorsStore.setSelectedOperator(operator);
                let timestamp = wrapper.rawMessage.timestamp;
                let ttlMinus = 0;
                if (timestamp) {
                    timestamp *= 1000;
                    let serverNow = ListenerInstance.getChannel().serverNow;
                    ttlMinus += serverNow - timestamp;
                }
                OperatorsStore.setOperatorTemporaryVisible(operator, ttlMinus);
            }
        }
    }
}

const OPERATOR_TYPING = "operatorTyping";

interface OperatorTypingPayloadInterface {
    operatorId?: string;
    operatorName?: string;
    timeout?: number;
    isBot?: boolean;
}