import AbstractActionFilterOperation from "~/chat/ts/service/autoActions/AbstractActionFilterOperation";

export default abstract class AbstractStringOperation extends AbstractActionFilterOperation {
    protected check(filterName: string, filterType: string, currentValue: any, checkValue: any[]): boolean {
        let $currentStringValue = this.valueToString(currentValue);
        for (let item of checkValue) {
            if (this.checkItem(item, $currentStringValue)) {
                return true;
            }
        }
        return false;
    }

    protected abstract checkItem(item: string, currentValue: string): boolean;

    private valueToString(value: any) {
        return value == null ? "" : value.toString();
    }
}