import InlineContentWatcher from "~/chat/ts/service/InlineContentWatcher";
import Vue from "vue";
import {ConfigStore} from "~/chat/ts/store/Config";

export default class InlineTabWatcher extends InlineContentWatcher {
    protected async getComponent(container: HTMLElement): Promise<Vue> {
        let tabId = this.attr(container, "id");
        let tab = ConfigStore.tabsDictionary.value[tabId];
        if (tab) {
            let ContentTab = (await (() => import(/* webpackChunkName: "widget" */ '~/chat/vue/widget/tabs/ContentTabInner.vue'))() as any).default;

            return new Vue(
                {
                    ...ContentTab,
                    propsData: {
                        tab: tab
                    }
                });
        } else {
            throw new Error("Вкладка не найдена");
        }
    }

    protected get selector(): string {
        return ".meTalkForm, .online-chat-inline-tab";
    }

}