import NodeRequest from "~/ts/library/listen/NodeRequest";
import {ListenerInstance} from "~/chat/ts/service/ListenerInstance";
import {ConfigStore} from "~/chat/ts/store/Config";
import {ClientStore} from "~/chat/ts/store/Client";
import Dictionary from "~/ts/library/Dictionary";

export default class ChatNodeRequest extends NodeRequest {
    constructor(url: string) {
        super(ListenerInstance, url, ConfigStore.apiHost.value);
    }

    public static getClientCredentials(): CometWidget.CometClientCredentialsOpenApiModel {
        let siteParams = ConfigStore.siteParams.value;
        return {
            orgId: siteParams.orgID,
            siteId: siteParams.id,
            clientId: ClientStore.id.value
        };
    }

    public static getAction(actionId: string, params: Dictionary<any> = {}): CometWidget.CometClientPushToActivityListBodyOpenApiModel {
        return {
            ...this.getClientCredentials(),
            url: window.location.href,
            title: document.title,
            referrer: document.referrer,
            pageId: ConfigStore.pageId.value,
            action: actionId,
            ...params
        }
    }
}