import ListenerMessage from "../ListenerMessage";
import {StorageInstance} from "../../storage/StorageFactory";
import {Intervals} from "../../delay/Intervals";
import {Timeouts} from "../../delay/Timeouts";
import RealChannel from "./RealChannel";
import Device from "~/ts/library/Device";

//const WEBSOCKET_WORKS = "WebSocket/Works";
const WEBSOCKET_DISABLED = "WebSocket/Disabled";

let websocketWorks = false;

class WebSocketChannel extends RealChannel {
    private ws: WebSocket;
    private pingInterval: number;
    private pongTimeout: number;
    private pongTime: number = 0;

    get typeId(): string {
        return "websocket";
    }


    async startListen(): Promise<void> {
        let url = this.url;
        let time = this.lastMessageTimestamp.toString();

        url += ((url.indexOf("?") === -1) ? "?" : "&") + `time=${time}`;

        return new Promise<void>((resolve, reject) => {
            let resolved = false;
            try {
                this.ws = new WebSocket(url);
            } catch (e) {
                this.closeErrorHandler();
                reject("error while create websocket");
                return;
            }

            this.ws.onmessage = (e: MessageEvent) => {
                WebSocketChannel.setWebsocketWorks();
                this.pong();

                if (e.data != "pong") {
                    this.pong();

                    let data = JSON.parse(e.data);

                    let previousStoredTime = data.previousStoredTime ? parseInt(data.previousStoredTime) : null;
                    let serverTime = data.serverTime;
                    if (data.channel && data.time) {
                        if (data.stored) {
                            this.setLastMessageTimeStamp(data.channel, parseInt(data.time), data, previousStoredTime);
                        }
                    }
                    let text = data.text;
                    if (text && text.action) {
                        this.receiveMessage(
                            new ListenerMessage(
                                text,
                                data.time ? parseInt(data.time) : 0,
                                previousStoredTime,
                                serverTime ? parseInt(serverTime) : null,
                            )
                        );
                    }
                }
            };

            var onClose = () => {
                //console.log("websocket closed", e);
                if (!resolved) {
                    reject("connection closed before established");
                }
                this.closeErrorHandler();
            }

            this.ws.onclose = onClose;

            this.ws.onerror = () => {
                if (!resolved) {
                    onClose();
                }
                this.destroy();
            };

            this.ws.onopen = () => {
                resolved = true;
                resolve();
            };


            this.initPing();
        });
    }

    private closeErrorHandler() {
        WebSocketChannel.disallowWebSocket();
        this.stopPing();
        this.close(true);
    }

    async destroy(): Promise<any> {
        if (this.ws) {
            this.ws.close();
        }
    }


    public static isAvailable(): boolean {
        return (window as any).WebSocket && Device.desktop() && !StorageInstance.getex(WEBSOCKET_DISABLED);
    }

    private static setWebsocketWorks() {
        websocketWorks = true;
        //StorageInstance.set(WEBSOCKET_WORKS, 1);
    }


    private static isWebSocketWorks(): boolean {
        return websocketWorks;
    }

    private static disallowWebSocket() {
        if (!this.isWebSocketWorks()) {
            StorageInstance.setex(WEBSOCKET_DISABLED, 1, 60000);
        }
    }

    private stopPing() {
        Intervals.clear(this.pingInterval);
        Timeouts.clear(this.pongTimeout);
    }

    private initPing() {
        this.pingInterval = Intervals.set(() => {
            if (this.ws.readyState === 1) {
                this.pongTime = 0;
                this.send('{"action":"ping"}');
                //that.ws.send('{"action":"ping"}');
                this.pongTimeout = Timeouts.set(() => {
                    if (!this.pongTime) {
                        this.destroy();
                    }
                }, 25000);
            } else {
                this.destroy();
            }
        }, 30000);
    }

    private pong() {
        this.pongTime = (new Date()).getTime();
    }

    public send(data: string): boolean {
        try {
            this.ws.send(data);
            return true;
        } catch (e) {
            return false;
        }
    }
}

export default WebSocketChannel;