class RequestResult {
    private xhr: XMLHttpRequest;

    constructor(xhr: XMLHttpRequest) {
        this.xhr = xhr;
    }

    public getRawResponse(): string {
        return this.xhr.responseText;
    }

    public getJsonResponse(): any {
        try {
            return JSON.parse(this.getRawResponse());
        } catch (e) {
            return null;
        }
    }

    public getXhr(): XMLHttpRequest {
        return this.xhr;
    }
}

export default RequestResult;