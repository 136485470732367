import {
    IMenuItem,
    ITriggerMenuDevice,
    ITriggerMenuTypeId,
    ITriggerMenuTypeOptions,
    ITriggerOffset,
    ITriggerParams,
    TRIGGER_MENU_ITEM_TYPE_REVIEWS,
    TRIGGER_MENU_ITEM_TYPE_TAB
} from "~/chat/ts/data/ISiteParams";
import Dictionary from "~/ts/library/Dictionary";
import {ConfigStore} from "~/chat/ts/store/Config";
import {OperatorsStore} from "~/chat/ts/store/Operators";
import {CHAT_OPEN_WIDGET} from "~/chat/ts/Constants";
import {WidgetStore} from "~/chat/ts/store/Widget";
import {TRIGGER_TYPES} from "~/cabinet/vue/client/online/config/design/tabs/trigger/Interfaces";
import {ChatEventManagerInstance} from "~/chat/ts/ChatEventManager";
import {toggleArrayElement} from "~/ts/library/ToggleArrayElement";
import {computed, ref} from "vue";
import Filters from "~/chat/ts/service/autoActions/Filters";
import DateHelper from "~/ts/library/Date";
import ObjectHelper from "~/ts/library/ObjectHelper";


class Trigger {
    isDefaultTextClosed = ref(false);
    public hideTriggerReasons = ref<string[]>([]);
    public mobileTriggerMenuDisplayed = ref(false);
    readonly isTriggerHidden = computed(() => {
        return this.hideTriggerReasons.value.length > 0;
    });
    readonly menu = computed(() => {
        return ConfigStore.siteParams.value.params.trigger.menu;
    });
    readonly deviceMenu = computed(() => {
        return (this.menu.value.devices as Dictionary<ITriggerMenuDevice>)[ConfigStore.device.value];
    });
    readonly triggerParams = computed<ITriggerParams>(() => {
        let siteParams = ConfigStore.siteParams.value;
        return siteParams ? ((siteParams.params.trigger as any) as Dictionary<ITriggerParams>)[ConfigStore.device.value] : null;
    });
    readonly triggerMenuDisplayTypeId = computed<ITriggerMenuTypeId>(() => {
        if (this.triggerParams.value) {
            let triggerTypeId = this.triggerParams.value.triggerType;
            let triggerType = TRIGGER_TYPES.find(type => type.id == triggerTypeId);
            if (triggerType) {
                let availableTypes = triggerType.availableMenuType[ConfigStore.device.value];
                if (availableTypes.length) {
                    let displayType = this.deviceMenu.value.displayType;
                    if (availableTypes.indexOf(displayType as string) == -1) {
                        displayType = availableTypes[0] as ITriggerMenuTypeId;
                    }
                    return displayType;
                }
            }
        }
        return null;
    });

    hideTrigger(reason: string) {
        this.TOGGLE_HIDE_TRIGGER_REASON({reason, value: true});
    }

    showTrigger(reason: string) {
        this.TOGGLE_HIDE_TRIGGER_REASON({reason, value: false});
    }

    readonly triggerMenuItems = computed<IMenuItem[]>(() => {
        let siteParams = ConfigStore.siteParams.value;
        if (siteParams) {
            let menu = siteParams.params.trigger.menu;
            //let deviceId = ConfigStore.device.value;
            let result = menu.list.filter(item => {
                let result = true;//!item.devices || !!item.devices[deviceId];
                let isTabType = item.type == TRIGGER_MENU_ITEM_TYPE_TAB;
                let isNeedTabCheck = isTabType || item.type == TRIGGER_MENU_ITEM_TYPE_REVIEWS;

                if (isNeedTabCheck) {
                    let tab = isTabType ? ConfigStore.tabsDictionary.value[item.params.link] : WidgetStore.reviewsTab.value;
                    if (!tab || (tab.hideWhenOffline && OperatorsStore.isOffline.value) || !tab.visible) {
                        result = false;
                    }
                }
                if (result) {
                    if (item.filters && ObjectHelper.hasKeys(item.filters)) {
                        if (DateHelper.nowEverySecond) {
                            result = Filters.checkFilters(item.filters).isSuccess;
                        }
                    }
                }

                return result;
            }).map(item => WidgetStore.modifyMenuItemAppearance(item));
            if (result.length) {
                return result;
            }
        }
        return null;
    });
    readonly hasMenu = computed(() => {
        return this.deviceMenu.value?.enabled && this.triggerMenuDisplayOptions.value != null && this.triggerMenuDisplayTypeId.value && this.triggerMenuItems.value.length > 0;
    });
    readonly triggerMenuForDevice = computed<ITriggerMenuDevice>(() => {
        if (this.triggerMenuItems.value) {
            let siteParams = ConfigStore.siteParams.value;
            if (siteParams) {
                let menu = siteParams.params.trigger.menu;
                let result = (menu.devices as Dictionary<ITriggerMenuDevice>)[ConfigStore.device.value];
                if (result.enabled) {
                    return result;
                }
            }
        }
        return null;
    });
    readonly triggerMenuDisplayOptions = computed<ITriggerMenuTypeOptions>(() => {
        let p = this.triggerMenuForDevice.value;
        if (p) {
            let displayType = this.triggerMenuDisplayTypeId.value;
            if (displayType) {
                return (p as any as Dictionary<ITriggerMenuTypeOptions>)[displayType]
            }
        }
        return null;
    });
    private triggerMenuOpened = ref(false);
    readonly isTriggerMenuOpened = computed(() => {
        return this.triggerMenuForDevice.value != null && this.triggerMenuOpened.value;
    });

    public SET_TRIGGER_MENU_OPENED(value: boolean) {
        this.triggerMenuOpened.value = value;
    }

    public CLOSE_DEFAULT_TEXT() {
        this.isDefaultTextClosed.value = true;
    }

    public SET_MOBILE_TRIGGER_MENU_DISPLAYED(value: boolean) {
        this.mobileTriggerMenuDisplayed.value = value;
    }

    SET_TRIGGER_OFFSET(payload: { offsetType: "secondary" | "primary", value: ITriggerOffset }) {
        this.triggerParams.value.offset[payload.offsetType] = payload.value;
    }

    private TOGGLE_HIDE_TRIGGER_REASON(payload: { reason: string, value: boolean }) {
        toggleArrayElement(this.hideTriggerReasons.value, payload.reason, payload.value);
    }
}


export const TriggerStore = new Trigger();

ChatEventManagerInstance.addEventListener(CHAT_OPEN_WIDGET, () => {
    TriggerStore.SET_TRIGGER_MENU_OPENED(false);
});