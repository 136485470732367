export const SENDER_TYPE_ANY = "any";
export const SENDER_TYPE_ONLY_OPERATOR = "onlyOperator";
export const SENDER_TYPE_PRIORITY_OPERATOR = "priorityOperator";
export const SENDER_TYPE_ONLY_GROUP = "onlyGroup";
export const SENDER_TYPE_PRIORITY_GROUP = "priorityGroup";
export const SENDER_TYPE_VIRTUAL = "virtual";

export const SILENT_DESKTOP = "silentDesktop";
export const SILENT_MOBILE = "silentMobile";
export const NO_BADGE_DESKTOP = "noBadgeDesktop";
export const NO_BADGE_MOBILE = "noBadgeMobile";
