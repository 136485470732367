import {ExtractPropTypes} from "vue";
import {TriggerStore} from "~/chat/ts/store/Trigger";

export function useTriggerMenuProps() {
    return {
        triggerWidth: {
            type: Number
        }
    }
}

export function useTriggerMenu(props: Readonly<ExtractPropTypes<ReturnType<typeof useTriggerMenuProps>>>) {
    let menuItems = TriggerStore.triggerMenuItems;

    return {
        menuItems
    };
}