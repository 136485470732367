import Random from "~/ts/library/Random";
import {INSTANCE_ID} from "~/ts/library/InstanceId";

class CrossTabMessage {
    action: string;
    from: string;
    payload: any;
    callbackId: number;
    callbackTimeout: number | null;

    constructor(action: string, payload: any, callbackTimeout?: number) {
        this.action = action;
        this.payload = payload;
        this.callbackId = Random.randomNumber(1, 1000000000);
        this.callbackTimeout = callbackTimeout;
        this.from = INSTANCE_ID;
    }
}

export default CrossTabMessage;