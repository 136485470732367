import {computed} from 'vue';
import MessageStorage from "~/chat/ts/service/messages/MessageStorage";
import MessageWrapper from "~/chat/ts/data/chat/MessageWrapper";
import {IOperator} from "~/chat/ts/data/IOperator";
import {__} from "~/ts/library/Translate";
import {ConfigStore} from "~/chat/ts/store/Config";
import {MESSAGE_TYPE} from "~/chat/ts/data/chat/IMessage";
import {WidgetStore} from "~/chat/ts/store/Widget";
import {ChatTabStore} from "~/chat/ts/store/ChatTab";

export function useNotification() {

    function getMessages(): AbstractNotificationItem[] {
        let result: AbstractNotificationItem[] = [];

        if (!ConfigStore.openChatOnNewMessage.value) {
            let messagesForNotifications = MessageStorage.getMessagesForNotifications();
            if (messagesForNotifications.length) {
                for (let message of messagesForNotifications) {
                    let wrapper = new MessageWrapper(message);
                    let text = wrapper.notificationText;
                    if (text != null) {
                        let hasSame = !!result.find(item => item.messageType && item.messageType == message.messageType && item.text == text);
                        if (!hasSame) {
                            result.push({
                                id: message.id,
                                type: "text",
                                text: text,
                                operator: wrapper.operator,
                                messageType: message.messageType,
                                originalMessageWrapper: wrapper,
                                isHiddenText: wrapper.isHiddenText
                            });
                        }
                    }
                }
            }

            if (MessageStorage.isOperatorTyping) {
                result.push({
                    id: "typing",
                    type: "typing",
                    text: __("TYPING_ANSWER"),
                    operator: MessageStorage.typingOperator,
                });
            }

        }


        return result;
    }

    let items = computed<AbstractNotificationItem[]>(() => {
        return getMessages();
    });


    function close() {
        for (let message of MessageStorage.getUnreadedMessages()) {
            MessageStorage.hideMessageFromNotification(message);
        }
        //MessageStorage.setMessageReaded();
    }


    function open(openChatHistory: boolean = true) {
        close();
        if (openChatHistory) {
            ChatTabStore.toggleOfflineForm(false);
        }
        WidgetStore.openChatTab();
    }

    return {
        open,
        close,
        items,
        getMessages
    }
}

export interface AbstractNotificationItem {
    type: "text" | "typing",
    id: number | string,
    text: string,
    operator?: IOperator,
    messageType?: MESSAGE_TYPE,
    originalMessageWrapper?: MessageWrapper,
    isHiddenText?: boolean
}