import {
    IconStyle,
    NewMesageNotificationAction,
    TRIGGER_MENU_TYPE_DROPDOWN,
    TRIGGER_MENU_TYPE_MOBILE,
    TRIGGER_MENU_TYPE_ROUND,
    TRIGGER_TYPE_CLASSIC,
    TRIGGER_TYPE_DISABLE,
    TRIGGER_TYPE_HIDDEN,
    TRIGGER_TYPE_IMAGE,
    TRIGGER_TYPE_ROUND
} from "~/chat/ts/data/ISiteParams";
import {DEVICE_DESKTOP, DEVICE_MOBILE, DEVICE_TABLET} from "~/ts/library/Device";
import Dictionary from "~/ts/library/Dictionary";
import {IField} from "~/cabinet/vue/interface/form/elements/Interfaces";

export interface ITriggerType {
    id: string,
    label: string,
    isShowPosition?: boolean,
    isCanPrimaryOffset?: boolean,
    isShowTitle?: boolean,
    hasIcon?: boolean;
    isTitleCanBeDisabled?: boolean,
    isIconCanBeDisabled?: boolean,
    availableMenuType: Dictionary<string[]>;
}

export interface TriggerMenuTypeInterface {
    id: string,
    label: string,
    iconColorAsTextAvailable?: boolean
}

export const TRIGGER_MENU_TYPES: TriggerMenuTypeInterface[] = [
    {id: TRIGGER_MENU_TYPE_DROPDOWN, label: "Выпадающий список", iconColorAsTextAvailable: true},
    {id: TRIGGER_MENU_TYPE_ROUND, label: "Круглое"},
    {id: TRIGGER_MENU_TYPE_MOBILE, label: "Мобильное"}
];

export const TRIGGER_TYPES: ITriggerType[] = [
    {
        id: TRIGGER_TYPE_ROUND,
        label: "Круглый",
        isShowTitle: true,
        isTitleCanBeDisabled: true,
        hasIcon: true,
        isShowPosition: true,
        isCanPrimaryOffset: true,
        availableMenuType: {
            [DEVICE_DESKTOP]: [
                TRIGGER_MENU_TYPE_ROUND,
                TRIGGER_MENU_TYPE_DROPDOWN
            ],
            [DEVICE_TABLET]: [
                TRIGGER_MENU_TYPE_MOBILE,
                TRIGGER_MENU_TYPE_ROUND,
                TRIGGER_MENU_TYPE_DROPDOWN
            ],
            [DEVICE_MOBILE]: [
                TRIGGER_MENU_TYPE_MOBILE,
                TRIGGER_MENU_TYPE_ROUND,
                TRIGGER_MENU_TYPE_DROPDOWN
            ]
        }
    },
    {
        id: TRIGGER_TYPE_CLASSIC,
        label: "Текст",
        isShowTitle: true,
        hasIcon: true,
        isTitleCanBeDisabled: false,
        isIconCanBeDisabled: true,
        isShowPosition: true,
        availableMenuType: {
            [DEVICE_DESKTOP]: [
                TRIGGER_MENU_TYPE_DROPDOWN
            ],
            [DEVICE_TABLET]: [
                TRIGGER_MENU_TYPE_DROPDOWN,
                TRIGGER_MENU_TYPE_MOBILE
            ],
            [DEVICE_MOBILE]: [
                TRIGGER_MENU_TYPE_MOBILE,
                TRIGGER_MENU_TYPE_DROPDOWN
            ]
        }
    },
    {
        id: TRIGGER_TYPE_IMAGE,
        label: "Своё изображение",
        isShowTitle: true,
        isTitleCanBeDisabled: true,
        isShowPosition: true,
        isCanPrimaryOffset: true,
        availableMenuType: {
            [DEVICE_DESKTOP]: [
                TRIGGER_MENU_TYPE_ROUND,
                TRIGGER_MENU_TYPE_DROPDOWN
            ],
            [DEVICE_TABLET]: [
                TRIGGER_MENU_TYPE_ROUND,
                TRIGGER_MENU_TYPE_DROPDOWN,
                TRIGGER_MENU_TYPE_MOBILE
            ],
            [DEVICE_MOBILE]: [
                TRIGGER_MENU_TYPE_MOBILE,
                TRIGGER_MENU_TYPE_ROUND,
                TRIGGER_MENU_TYPE_DROPDOWN,
            ]
        }
    },
    {
        id: TRIGGER_TYPE_HIDDEN,
        label: "Спрятать ярлык (будете использовать API)",
        isShowPosition: true,
        isCanPrimaryOffset: true,
        availableMenuType: {}
    },
    {
        id: TRIGGER_TYPE_DISABLE,
        label: "Не загружать модуль",
        availableMenuType: {

        }
    }
];

export const DEVICE_LIST: {id: string, label: string, icon: string}[] = [
    {
        id: DEVICE_DESKTOP,
        label: "ПК",
        icon: "desktop"
    },
    {
        id: DEVICE_MOBILE,
        label: "Смартфон",
        icon: "mobile"
    },
    {
        id: DEVICE_TABLET,
        label: "Планшет",
        icon: "tablet"
    }
];

for (let device of DEVICE_LIST) {
    for (let type of TRIGGER_TYPES) {
        if (!type.availableMenuType[device.id]) {
            type.availableMenuType[device.id] = [];
        }
    }
}

/*
export interface ITriggerMenuItemType {
    id: string,
    descr: string,
    icon: string,
    color: string
}

 */

export interface ITriggerMenuItemType {
    id: string,
    static: boolean
    descr: string
    descr_online?: string
    descr_offline?: string
    descrFromLink: boolean
    params: Dictionary<IField>
    icon: {default: string, color1: string, color2: string}
    defaultDescr: string

}

export interface IWidgetConfigTab {
    id: string,
    title: string,
    icon: string,
    iconStyle: IconStyle,
    iconColor: string,
    type: string,
    visible: boolean,
    weight: number
}

export interface INotificationSound {
    id: string,
    descr: string;
}

export interface INotificationType {
    id: NewMesageNotificationAction,
    descr: string;
    isShowMessageButtonDisplayConfig: boolean
}