export const CLIENT_ID_COOKIE = "supportOnlineTalkID";

//export const CHAT_EVENT_OPERATOR_SELECTED = "operatorInChatSelected";
export const CHAT_EVENT_OPERATOR_SELECTED_MANUALY = "operatorInChatSelectedManualy";
export const CLIENT_CAPTCHA_SOLVED = "clientCaptchaSolved";
export const CHAT_EVENT_CONTACTS_PROVIDED = "contactsInChatProvided";

export const QUESTION_CATEGORY_FIELD_NAME = "questionCategory";

export const FORM_TYPE_BEFORE_CHAT_START = "formBeforeChatStart";
export const FORM_TYPE_AFTER_CHAT_START = "formAfterChatStart";
export const FORM_TYPE_LEAD_GEN = "leadGen";
export const FORM_TYPE_QUESTION_CATEGORY = "questionCategory";

export const CHAT_SEND_MESSAGE_BY_CLIENT = "sendMessageByClient";
export const CHAT_OPEN_WIDGET = "openWidget";
export const CHAT_EVENT_MOUSE_MOVE_ON_WIDGET_INNER_ROOT = "mouseMoveOnWidgetInnerRoot";

export const MESSAGE_BLOCK_PREFIX = "%block%";
export const MESSAGE_HTML_PREFIX = "%html%";
export const MESSAGE_HTML_BLOCK_PREFIX = MESSAGE_HTML_PREFIX + MESSAGE_BLOCK_PREFIX;

export const OFFLINE_FORM_TAB_ID = "offlineForm";
export const TOGGLE_TRIGGER_API_REASON = "byApi";

export const REVIEWS_TRIGGER_MENU_FORM = "form";
export const CHAT_ROOT_CLASS = "online-chat-root";
export const CHAT_INLINE_COMPONENT_CLASS = "online-chat-inline-component";
export const CHAT_INLINE_MODE_CLASS = "inline-mode";

export const CHAT_ROOT_CLASS_INHERIT = "inherit";

export const REVIEW_PAGINATION_EXPAND = "expand";
export const REVIEW_PAGINATION_CLASSIC = "classic";

/*
import config from "./config/Config";

export const CONFIG = config;

export const MENU_TYPE_MOBILE = "mobile";
export const MENU_TYPE_ROUND = "round";

export const DEVICE_MOBILE = "mobile";
export const DEVICE_DESKTOP = "desktop";
export const DEVICE_TABLET = "mobile";
*/