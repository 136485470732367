import WebStorage from "./WebStorage";

class SessionStorage extends WebStorage {
    protected getStorage(): Storage {
        return window.sessionStorage;
    }

    public isPersistent(): boolean {
        return false;
    }

    getType(): string {
        return "sessionStorage";
    }
}

export default SessionStorage;