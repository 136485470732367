import AbstractActionFilterOperation from "~/chat/ts/service/autoActions/AbstractActionFilterOperation";

export default class OperationNotEmpty extends AbstractActionFilterOperation {
    protected check(filterName: string, filterType: string, currentValue: any, checkValue: any[]): boolean {
        return currentValue != null && currentValue != false && currentValue.toString().length > 0;
    }

    get returnFalseOnFirstMismatch(): boolean {
        return true;
    }

}