import AbstractListenerAction from "~/ts/library/listen/AbstractListenerAction";
import Filters from "~/chat/ts/service/autoActions/Filters";

export default class SetActionFilterValue extends AbstractListenerAction {
    public getAction(): string {
        return "setActionFilterValue";
    }

    public onAction(payload: { name: string, value: any }): void {
        Filters.set(payload.name, payload.value);
    }
}