import Dictionary from "~/ts/library/Dictionary";
import {StorageInstance} from "~/ts/library/storage/StorageFactory";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {ChatEventManagerInstance} from "~/chat/ts/ChatEventManager";
import {ConfigStore} from "~/chat/ts/store/Config";
import StringHelper from "~/ts/library/StringHelper";
import {__} from "~/ts/library/Translate";
import {computed, ref} from "vue";
import {OperatorsStore} from "~/chat/ts/store/Operators";

class CobrowseOuter {
    readonly isDisabled = computed(() => {
        return this.disallowed.value || (ConfigStore.siteParams && !ConfigStore.siteParams.value.params.siteChatOptions.cobrowse.enabled);
    });
    private disallowed = ref(false);
    public readonly isCobrowseWorkingNow = computed<boolean>(() => {
        return !this.isDisabled.value && this.operatorsLogins.value.length > 0 && !ConfigStore.siteParams.value.free;
    });

    private operatorsKey = "cobrowseOperators";
    private timeout = 90000;
    private readonly storedOperators = computed<Dictionary<OperatorItem>>(() => {
        let res = StorageInstance.getex(this.operatorsKey);
        let time = Date.now();
        if (!res) {
            res = {};
        }
        for (let channel in res) {
            if (res.hasOwnProperty(channel)) {
                if (res[channel].time < (time - this.timeout)) {
                    delete res[channel];
                }
            }
        }
        return res;
    });

    public readonly operatorsLogins = computed<string[]>(() => {
        return ObjectHelper.getKeys(this.storedOperators.value);
    });

    public DISABLE_COBROWSE() {
        this.disallowed.value = true;
    }

    public addOperator(channel: string, loginHash: string, forceLoad?: boolean) {
        if (!this.isDisabled.value) {
            let params = ConfigStore.siteParams.value.params.siteChatOptions.cobrowse;
            let list = this.storedOperators.value;
            let isNew = list[channel] == null;

            if (params.needConfirmation && isNew) {
                let operator = OperatorsStore.getOperatorByLoginHash(loginHash) ?? OperatorsStore.getOperatorByLogin(channel);
                let text = StringHelper.replaceVariables(
                    params.confirmationText,
                    {
                        name: operator ? operator.fio : __("OPERATOR")
                    });
                let disallowKey = `cobrowseDisallow_${channel}`;
                if (StorageInstance.getex(disallowKey)) {
                    return;
                }
                if (!confirm(text)) {
                    StorageInstance.setex(disallowKey, 1, 10000);
                    return;
                }
            }

            let time = Date.now();
            list[channel] = {
                time: time
            };
            this.saveOperatorsList(list);
            if (isNew || forceLoad) {
                ChatEventManagerInstance.emit(COBROWSE_STORE_NEW_OPERATOR_EVENT);
            }
        }
    }

    public removeOperator(login: string){
        let list = this.storedOperators.value;
        delete list[login];
        this.saveOperatorsList(list);
    }

    private saveOperatorsList(list: Dictionary<OperatorItem>){
        StorageInstance.setex(this.operatorsKey, list, this.timeout);
    }
}

export const CobrowseOuterStore = new CobrowseOuter();


interface OperatorItem {
    time: number
}

export const COBROWSE_STORE_NEW_OPERATOR_EVENT = "newCobrowseOperator";