import MessageStorage from "~/chat/ts/service/messages/MessageStorage";
import AbstractListenerAction from "~/ts/library/listen/AbstractListenerAction";

export default class DeleteMessageListener extends AbstractListenerAction {
    public getAction(): string {
        return "deleteMessage";
    }

    public onAction(payload: { id: number, clientSideId?: string }): void {
        if (payload.clientSideId) {
            for (let id of MessageStorage.messageIdList) {
                let message = MessageStorage.getMessage(id);
                if (message && message.other && message.other.clientSideId === payload.clientSideId) {
                    payload.id = id;
                    break;
                }
            }
        }
        MessageStorage.removeMessage(payload.id);
    }
}
/*
{"id":1,"channel":"14755221258159fdb93bdc95487328cc4cc83208","text":{"action":"deleteMessage","id":184744097},"time":"1561050255359","tag":0}
 */