import {IWidgetTabParams, TAB_TYPE_CHAT, TAB_TYPE_REVIEWS} from "~/chat/ts/data/ITab";
import {ConfigStore} from "~/chat/ts/store/Config";
import {CHAT_OPEN_WIDGET, CLIENT_CAPTCHA_SOLVED} from "~/chat/ts/Constants";
import Filters from "~/chat/ts/service/autoActions/Filters";
import {
    ACTION_FILTER_COUNTRY,
    ACTION_FILTER_WIDGET_STATE,
    ACTION_FILTER_WIDGET_STATE_CLOSED,
    ACTION_FILTER_WIDGET_STATE_OPENED,
    OPERATION_EQUAL
} from "~/chat/ts/data/AutoActions";
import {ChatTabStore} from "~/chat/ts/store/ChatTab";
import {OperatorsStore} from "~/chat/ts/store/Operators";
import Dictionary from "~/ts/library/Dictionary";
import {Vue} from "~/node_modules/vue/types/vue";
import ChatModalHelper from "~/chat/vue/widget/ChatModalHelper";
import ApiCallback, {
    API_CALLBACK_CLOSE_SUPPORT,
    API_CALLBACK_OPEN_SUPPORT
} from "~/chat/ts/service/api/methods/ApiCallback";
import {ChatEventManagerInstance} from "~/chat/ts/ChatEventManager";
import {
    IMenuItem,
    TRIGGER_MENU_ITEM_TYPE_CHAT,
    TRIGGER_MENU_ITEM_TYPE_REVIEWS,
    TRIGGER_MENU_ITEM_TYPE_TAB
} from "~/chat/ts/data/ISiteParams";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {computed, ref, watch} from "vue";
import {StorageInstance} from "~/ts/library/storage/StorageFactory";


class Widget {
    left = ref(50);
    top = ref(50);
    _width = ref(300);
    _height = ref(400);
    modalMessage = ref<IModalMessagePayload>();
    sendReviewOpened = ref(false);
    positionChangedManualy = ref(false);
    triggerPositionInitialized = ref(false);
    isOpenedOnce = ref(false);
    tabParamsFromOperator = ref<Dictionary<any>>({});
    inlineMode = ref(false);
    closeToOnlineChatAllowed = ref(false);
    sendReviewDefaultRate = ref<string>();
    showClientCaptcha = ref(false);

    readonly widgetInnerContainerId = computed(() => {
        return "widget-inner-" + ConfigStore.instanceId.value;
    });
    readonly widgetRootContainerId = computed(() => {
        return "widget-root-" + ConfigStore.instanceId.value;
    });

    readonly visibleTabs = computed(() => {
        let tabs = ConfigStore.tabs.value;

        return tabs.filter(tab => {
            if (!tab.visible) {
                return false;
            } else if (tab.hideWhenOffline && OperatorsStore.isOffline.value) {
                return false;
            } else if (tab.type == TAB_TYPE_CHAT && ChatTabStore.isWidgetMustBeHiddenBecauseOfOffline.value) {
                return false;
            }
            return true;
        }).sort((a, b) => {
            let menu = ConfigStore.siteParams.value.params.siteChatOptions.widgetMenu.list
                .map(item => this.getTabIdByWidgetMenuItem(item))
                .filter(id => typeof id == "string");
            let aIndex = menu.indexOf(a.id);
            let bIndex = menu.indexOf(b.id);
            if (aIndex == bIndex) {
                return 0;
            }
            if (aIndex == -1) {
                return 1;
            } else if (bIndex == -1) {
                return -1;
            } else {
                return aIndex < bIndex ? -1 : 1;
            }
        });
    });

    private getTabIdByWidgetMenuItem(item: IMenuItem) {
        let result: string;
        if (item.type == TRIGGER_MENU_ITEM_TYPE_TAB) {
            result = item.params.link;
        } else if (item.type == TRIGGER_MENU_ITEM_TYPE_CHAT) {
            result = this.chatTab.value?.id;
        } else if (item.type == TRIGGER_MENU_ITEM_TYPE_REVIEWS) {
            result = this.reviewsTab.value?.id;
        }
        return result;
    }
    readonly isEulaEnabled = computed(() => {
        return ConfigStore.siteParams.value.params.siteChatOptions.eula.isEnabled && (ConfigStore.language.value == "ru" || Filters.check(ACTION_FILTER_COUNTRY, OPERATION_EQUAL, ["Russia"]).isSuccess)
    });
    readonly chatTab = computed<IWidgetTabParams>(() => {
        return this.getTabByType(TAB_TYPE_CHAT);
    });
    readonly reviewsTab = computed<IWidgetTabParams>(() => {
        return this.getTabByType(TAB_TYPE_REVIEWS);
    });
    readonly overridedSize = computed(() => {
        let tab = this.currentTab.value;
        if (tab && tab.changeWidgetSize && tab.changeWidgetSize.enabled) {
            return tab.changeWidgetSize;
        }
        return null;
    });
    readonly width = computed(() => {
        return this.overridedSize.value && this.overridedSize.value.width ? this.overridedSize.value.width : this._width.value;
    });
    readonly height = computed(() => {
        return this.overridedSize.value && this.overridedSize.value.height ? this.overridedSize.value.height : this._height.value;
    });

    private get opened(): boolean {
        if (!ConfigStore.isMobile.value) {
            return this.openedLocal.value || (StorageInstance.get("isOpened") && this.triggerPositionInitialized.value && ConfigStore.siteParams.value.params.siteChatOptions.desktopSyncOpenState);
        }
        return this.openedLocal.value;
    }

    private set opened(value: boolean) {
        if (!ConfigStore.isMobile.value) {
            StorageInstance.set("isOpened", value);
        }
        this.openedLocal.value = value;
    }

    private openedLocal = ref(false);
    readonly isOpened = computed(() => {
        return (this.opened || this.inlineMode.value) && !!this.currentTab.value && !ChatTabStore.isWidgetMustBeHiddenBecauseOfOffline.value;
    });
    private sideMenuOpened = ref(false);
    readonly isSideMenuOpened = computed(() => {
        return this.sideMenuOpened.value;
    });
    private selectedTabId = ref<string>();
    readonly currentTab = computed(() => {
        if (!this.selectedTabId.value) {
            let chatId = this.chatTab.value?.id;
            if (chatId) {
                this.selectedTabId.value = chatId;
            }
        }
        let result = this.selectedTabId.value ? ConfigStore.tabs.value.find(tab => tab.id == this.selectedTabId.value) : null;
        if (result && result.type == TAB_TYPE_CHAT && this.visibleTabs.value.indexOf(result) == -1) {
            result = null;
        }
        if (!result) {
            result = this.visibleTabs.value[0];
        }
        return result;
    });
    private recaptchaReady = ref(false);
    readonly isRecaptchaReady = computed(() => {
        return (window as any).grecaptcha != null || this.recaptchaReady.value;
    });

    updatePosition(payload: { type: "left" | "top", value: number }) {
        payload.type == "left" ? this.SET_LEFT(payload.value) : this.SET_TOP(payload.value);
    }

    updateSize(payload: { type: "height" | "width", value: number }) {
        payload.type == "height" ? this.SET_HEIGHT(payload.value) : this.SET_WIDTH(payload.value);
    }

    public SET_RECAPTCHA_READY() {
        this.recaptchaReady.value = true;
    }

    public SET_INLINE_MODE(value: boolean) {
        this.inlineMode.value = value;
    }

    public SET_POSITION_CHANGED_MANUALY() {
        this.positionChangedManualy.value = true;
    }

    openTab(tab: IWidgetTabParams) {
        this.SET_SELECTED_TAB(tab);
        if (!this.isOpened.value) {
            this.open();
        } else {
            this.SET_CLOSE_TO_ONLINE_CHAT_ALLOWED(true);
        }
    }

    open() {
        this.TOGGLE_OPENED(true);
        ChatEventManagerInstance.emit(CHAT_OPEN_WIDGET);
    }

    selectChatTab() {
        if (this.chatTab.value) {
            this.SET_SELECTED_TAB(this.chatTab.value);
        }
    }

    setWidgetStateFilter() {
        Filters.set(ACTION_FILTER_WIDGET_STATE, this.isOpened.value ? ACTION_FILTER_WIDGET_STATE_OPENED : ACTION_FILTER_WIDGET_STATE_CLOSED);
    }

    SET_TAB_PARAMS_FROM_OPERATOR(payload: { tab: IWidgetTabParams, params: any }) {
        this.tabParamsFromOperator.value[payload.tab.id] = payload.params;
    }


    openChatTab() {
        this.openByType({type: TAB_TYPE_CHAT});
    }

    close() {
        this.TOGGLE_OPENED(false);
    }

    modifyMenuItemAppearance(item: IMenuItem): IMenuItem {
        if (item.appearanceFromTab) {
            let tab: IWidgetTabParams;
            if (item.type == TRIGGER_MENU_ITEM_TYPE_CHAT) {
                tab = this.chatTab.value;
            } else if (item.type == TRIGGER_MENU_ITEM_TYPE_REVIEWS) {
                tab = this.reviewsTab.value;
            } else if (item.type == TRIGGER_MENU_ITEM_TYPE_TAB) {
                tab = ConfigStore.tabsDictionary.value[item.params.link];
            }

            if (tab) {
                item = ObjectHelper.jsonClone(item);
                item.descr = tab.title;
                item.subDescr = tab.subTitle;
                item.color1 = tab.iconColor;
                if (tab.iconColor2) {
                    item.color2 = tab.iconColor2;
                }
                item.iconStyle = tab.iconStyle;
                item.iconName = tab.icon;
            }
        }
        if (item.type == TRIGGER_MENU_ITEM_TYPE_CHAT) {
            this.modifyMenuChatItemAppearance(item);
        }
        return item;
    }

    modifyMenuChatItemAppearance(item: IMenuItem): IMenuItem {
        let tab = this.chatTab.value;
        item.descr = tab.title;
        item.subDescr = tab.subTitle;
        if (ChatTabStore.chatTabMode.value.useOfflineTabTitle) {
            item.descr = ConfigStore.siteParams.value.params.leadGen.tabTitle;
            item.subDescr = null;
        }
        return item;
    }

    openSideMenu() {
        this.TOGGLE_SIDE_MENU(true);
    }

    closeSideMenu() {
        this.TOGGLE_SIDE_MENU(false);
    }

    showModalMessage(payload: IModalMessagePayload) {
        this.SET_MODAL_MESSAGE_TEXT(payload);
    }

    openSendReview(payload?: { value?: string }) {
        if (payload && payload.value) {
            this.SET_DEFAULT_REVIEW_VALUE(payload.value);
        }
        this.TOGGLE_SEND_REVIEW(true);
    }

    hideModalMessage() {
        this.SET_MODAL_MESSAGE_TEXT();
    }

    closeSendReview() {
        this.TOGGLE_SEND_REVIEW(false);
    }

    setInitialSize() {
        let siteParams = ConfigStore.siteParams.value;

        if (siteParams) {
            let widget = ConfigStore.design.value.widget;
            this.updateSize({
                type: "height",
                value: widget.minHeight
            });
            this.updateSize({
                type: "width",
                value: widget.minWidth
            });
        }
    }

    private SET_CLOSE_TO_ONLINE_CHAT_ALLOWED(value: boolean) {
        this.closeToOnlineChatAllowed.value = value;
    }

    private SET_LEFT(value: number) {
        this.left.value = value;
    }

    openByType(payload: { type: string }) {
        let tab = ConfigStore.tabs.value.find(tab => tab.type == payload.type);
        if (tab) {
            this.openTab(tab);
        }
    }

    private SET_TOP(value: number) {
        this.top.value = value;
    }

    private SET_WIDTH(value: number) {
        this._width.value = value;

    }

    public getTabById(id: string) {
        return ConfigStore.tabs.value.find(tab => tab.id == id)
    }

    openById(payload: { id: string, params?: Dictionary<any> }) {
        let tab = this.getTabById(payload.id);
        if (tab) {
            if (payload.params) {
                if (tab.type == TAB_TYPE_REVIEWS) {
                    if (payload.params.showReviewForm) {
                        this.open();
                        this.openSendReview();
                        return;
                    }
                }
                this.SET_TAB_PARAMS_FROM_OPERATOR({
                    tab,
                    params: payload.params
                });
            }
            this.openTab(tab);
        }
    }

    private SET_HEIGHT(value: number) {
        this._height.value = value;
    }

    private TOGGLE_OPENED(value: boolean) {
        let prevIsOpened = this.isOpened.value;
        this.opened = value;
        if (value) {
            this.isOpenedOnce.value = true;
        }

        if (prevIsOpened != this.isOpened.value) {
            this.SET_CLOSE_TO_ONLINE_CHAT_ALLOWED(false);
        }
    }

    private TOGGLE_SIDE_MENU(value: boolean) {
        this.sideMenuOpened.value = value;
    }

    private TOGGLE_SEND_REVIEW(value: boolean) {
        this.sendReviewOpened.value = value;
    }

    private SET_DEFAULT_REVIEW_VALUE(value?: string) {
        if (!ConfigStore.siteParams.value.params.rate.isDefaultValueDisallowed) {
            this.sendReviewDefaultRate.value = value;
        }
    }

    private SET_SELECTED_TAB(tab: IWidgetTabParams) {
        let tabId = tab.id;
        if (this.selectedTabId.value != tabId) {
            this.selectedTabId.value = tabId;
        }
    }

    private SET_MODAL_MESSAGE_TEXT(payload?: IModalMessagePayload) {
        if (payload) {
            if (payload.escape == null) {
                payload.escape = true;
            }
            if (!this.isOpened.value) {
                payload.appendToBody = true;
                payload.vue = null;
            }
            if (payload.vue != null && payload.appendToBody == null) {
                payload.appendToBody = ChatModalHelper.isMustToBeInBody(payload.vue.$el as HTMLElement);
            }
        } else {
            if (this.modalMessage.value?.onClose) {
                this.modalMessage.value.onClose();
            }
        }
        this.modalMessage.value = payload;
    }

    private getTabByType(typeId: string): IWidgetTabParams | null {
        return ConfigStore.tabs.value.find(tab => tab.type == typeId);
    }

    public async requestClientCaptchaSolve() {
        this.showClientCaptcha.value = true;
        return await new Promise(resolve => {
            ChatEventManagerInstance.once(CLIENT_CAPTCHA_SOLVED, data => {
                this.showClientCaptcha.value = false;
                resolve(data);
            });
        });
    }


}


interface IModalMessagePayload {
    text: string;
    title?: string;
    escape?: boolean;
    bbcode?: boolean;
    nl2br?: boolean;
    vue: Vue;
    appendToBody?: boolean;
    onClose?: () => void;
}


export const WidgetStore = new Widget();
watch(WidgetStore.isOpened, () => {
    ApiCallback.emit(WidgetStore.isOpened.value ? API_CALLBACK_OPEN_SUPPORT : API_CALLBACK_CLOSE_SUPPORT);
    WidgetStore.setWidgetStateFilter();
});