import {OperatorsStore} from "~/chat/ts/store/Operators";
import MessageStorage from "~/chat/ts/service/messages/MessageStorage";
import Delay from "~/ts/library/Delay";
import ChatApiRequest from "~/chat/ts/service/request/ChatApiRequest";
import {MESSAGE_TYPE_SUPPORT_API} from "~/chat/ts/data/chat/IMessage";
import Random from "~/ts/library/Random";

interface ReceiveMessagePayloadInterface {
    text: string;
    operator: string;
    typingDuration: number;
    disableChatSimulation: boolean;
    allowOfflineOperator: boolean;
    callback: (err?: string) => {}
}

export default async function receiveMessage(p: ReceiveMessagePayloadInterface | string, ...args: any[]) {
    if (typeof p != "object") {
        p = {
            text: p,
            operator: args[0],
            typingDuration: args[1],
            disableChatSimulation: args[2],
            allowOfflineOperator: args[3],
            callback: undefined
        };
    }
    p = {
        ...{
            simulateTyping: 2000,
            disableChatSimulation: false,
            allowOfflineOperator: false
        },
        ...p
    };

    let error = "Not enough params";


//text: string, operatorLoginOrId?: string, simulateTyping: number = 2000, disableChatSimulation: boolean = false, allowOfflineOperator: boolean = false
    if (p.text) {
        let operator = p.operator ? OperatorsStore.getOperatorByIdOrLogin(p.operator) : null;
        if (!operator || (!OperatorsStore.isOperatorOnline(operator) && !p.allowOfflineOperator)) {
            operator = OperatorsStore.selected.value.operator;
            if (!operator) {
                operator = Random.randomArrayElement(OperatorsStore.visibleOperators.value);
                if (!operator && p.allowOfflineOperator) {
                    operator =  Random.randomArrayElement(
                        OperatorsStore.operatorsList.value
                            .filter(operator => !OperatorsStore.isOperatorHidden(operator))
                    );
                }
            }
        }
        if (operator) {
            if (typeof p.typingDuration == "number" && p.typingDuration > 0) {
                MessageStorage.setOperatorTyping({operatorId: operator.id, timeout: p.typingDuration});
                await Delay.make(p.typingDuration);
            }

            MessageStorage.removeByText(p.text, MESSAGE_TYPE_SUPPORT_API);

            let result = await ChatApiRequest.make({
                method: "sendMessageToClientBySupportAPI",
                p: {
                    operatorId: operator.id,
                    text: p.text,
                    silent: p.typingDuration == -1,
                    disableChatSimulation: p.disableChatSimulation
                }
            });
            if (result.isSuccess()) {
                error = null;
            } else {
                error = result.getDescr();
            }
        } else {
            error = "Operator not available";
        }
    }
    if (p.callback) {
        p.callback(error);
    }

}