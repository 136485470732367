import AbstractReceiveMessageListener from "~/chat/ts/service/listen/AbstractReceiveMessageListener";
import IMessage, {MESSAGE_TYPE_TAB} from "~/chat/ts/data/chat/IMessage";
import {ListenerInstance} from "~/chat/ts/service/ListenerInstance";
import MessageStorage from "~/chat/ts/service/messages/MessageStorage";
import TabMessageWrapper from "~/chat/ts/data/chat/TabMessageWrapper";
import AnalyticCounters from "~/chat/ts/service/AnalyticCounters";
import {__} from "~/ts/library/Translate";
import ApiCallback, {API_CALLBACK_OPERATOR_SEND_MESSAGE} from "~/chat/ts/service/api/methods/ApiCallback";
import {WidgetStore} from "~/chat/ts/store/Widget";

export default abstract class AbstractReceiveOperatorMessageListener extends AbstractReceiveMessageListener {
    protected abstract isMarkAsUnreaded(): boolean;

    afterReceive(message: IMessage) {
        super.afterReceive(message);

        if (this.isMarkAsUnreaded()) {
            if (!ListenerInstance.isCacheChannel()) {
                MessageStorage.setMessageDelivered(message.id);
            }

            if (message.messageType == MESSAGE_TYPE_TAB) {
                let tabMessageWrapper = new TabMessageWrapper(message);
                let tab = tabMessageWrapper.tab;
                if (!ListenerInstance.isCacheChannel() && tab) {
                    AnalyticCounters.send("Tab sended by operator", __("EVENT_OPERATOR_SEND_TAB"), `Tab sended by operator ${tab.id}`);
                }
                if (!tabMessageWrapper.autoOpenTabIfNeed()) {
                    if (!MessageStorage.getUnreadedMessageCount()) {
                        WidgetStore.openChatTab();
                    }
                }
            } else {
                MessageStorage.markAsUnreaded(message);
            }
            ApiCallback.emit(API_CALLBACK_OPERATOR_SEND_MESSAGE, {
                text: message.text,
                operatorId: message.from,
                id: message.id
            });
        }
        if (message.status == "readed") {
            MessageStorage.markAsReaded(message);
        }
    }
}