import {ConfigStore} from "~/chat/ts/store/Config";
import AbstractListenerAction from "~/ts/library/listen/AbstractListenerAction";

export default class SetAttachedOperatorsListener extends AbstractListenerAction {
    public getAction(): string {
        return "setAttachedOperators";
    }

    public onAction(payload: {
        operatorIds: string[],
        vacationReplacementIds: string[],
        onlyIfOnline: boolean
    }): void {
        ConfigStore.setAttachedOperators(payload);
    }
}