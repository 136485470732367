import {AutoActionModel} from "~/chat/ts/data/AutoActions";

export default abstract class AbstractAutoAction<T extends AutoActionModel> {
    private _params: T;

    constructor(params: T) {
        this._params = params;
    }

    protected get params(): T {
        return this._params;
    }

    public get id() {
        return this._params.id;
    }

    public abstract run(): Promise<IAutoActionSuccessRunResult[]>;

    public abstract isCanRunNow(): boolean;

    public isNeedSendResultToServer(): boolean {
        return false;
    }

    public abstract get isNoize(): boolean;

    public initParams() {

    }
}

export interface IAutoActionSuccessRunResult {
    timeout?: number,
}

