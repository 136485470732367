import AbstractActionFilterOperation from "~/chat/ts/service/autoActions/AbstractActionFilterOperation";
import {ACTION_FILTER_SEARCH_KEYWORD, FILTER_TYPE_TIMERANGE} from "~/chat/ts/data/AutoActions";
//import SearchKeyword from "~/chat/ts/service/SearchKeyword";

export default class OperationEqual extends AbstractActionFilterOperation {
    protected check(filterName: string, filterType: string, currentValue: any, checkValue: any[]): boolean {
        if (filterType == FILTER_TYPE_TIMERANGE) {
            for (let item of checkValue) {
                if (Array.isArray(item) && Array.isArray(item[0]) && Array.isArray(item[1])) {
                    let $currentValuePrepared = this.timeToInt(currentValue[3], currentValue[4], currentValue[5]);
                    let $checkValuePrepared1 = this.timeToInt(item[0][0], item[0][1], item[0][2]);
                    let $checkValuePrepared2 = this.timeToInt(item[1][0], item[1][1], item[1][2]);
                    if ($checkValuePrepared2 > $checkValuePrepared1) {
                        if ($currentValuePrepared >= $checkValuePrepared1 && $currentValuePrepared <= $checkValuePrepared2) {
                            return true;
                        }
                    } else if ($checkValuePrepared1 > $checkValuePrepared2) {
                        let $time2359 = this.timeToInt(23, 59, 59);
                        let $time0 = 0;
                        if (
                            ($currentValuePrepared >= $checkValuePrepared1 && $currentValuePrepared <= $time2359)
                            ||
                            ($currentValuePrepared >= $time0 && $currentValuePrepared <= $checkValuePrepared2)
                        ) {
                            return true;
                        }
                    }
                }
            }
        } else if (filterName == ACTION_FILTER_SEARCH_KEYWORD) {
            return false;
            /*
            if (SearchKeyword.check(currentValue)) {
                return true;
            }
            */
        } else if (checkValue.indexOf(currentValue) != -1 || checkValue.indexOf(currentValue?.toString()) != -1) {
            return true;
        }
        return false;
    }

    private timeToInt(h: number, m: number, s: number) {
        return h * 3600 + m * 60 + s;
    }
}