import {StorageInstance} from "~/ts/library/storage/StorageFactory";
import Filters from "~/chat/ts/service/autoActions/Filters";
import {ACTION_FILTER_IS_TARGET_CLIENT} from "~/chat/ts/data/AutoActions";
import {ConfigStore} from "~/chat/ts/store/Config";
import ConsoleWrapper from "~/ts/library/Console";
import Delay from "~/ts/library/Delay";
import ChatNodeRequest from "~/chat/ts/service/ChatNodeRequest";

export default class TargetClient {
    private static reasonsToSend: ITargetClientReason[] = [];

    public static send(reason: string, check: boolean = false, notifyDestination: string[] = ["application"], repeatOnNewVisit = false): boolean {
        if (!ConfigStore.siteParams.value.free && StorageInstance.isPersistent()) {
            let currentVisitId = ConfigStore.visitId.value;

            let reasonObject: ITargetClientReason = {
                reason,
                repeatOnNewVisit,
                notifyDestination,
                visitId: currentVisitId
            };

            let prevVisitStorageKey = `target/ex/${reason}`;
            let prevVisitId = StorageInstance.getex(prevVisitStorageKey);
            if (!prevVisitId || (repeatOnNewVisit && prevVisitId != currentVisitId)) {
                if (!check) {
                    StorageInstance.setex(prevVisitStorageKey, currentVisitId, 1200000);
                    this.reasonsToSend.push(reasonObject);
                    Filters.set(ACTION_FILTER_IS_TARGET_CLIENT, 1);
                    this.makeSend();
                }
                return true;
            }
        }
        return false;
    }

    private static async makeSend() {
        try {
            await Delay.make(1000, "sendTargetReason", true);
            try {
                if (this.reasonsToSend.length) {
                    let params = {
                        reasons: this.reasonsToSend
                    };
                    this.reasonsToSend = [];
                    await (new ChatNodeRequest("/pushToActivityList"))
                        .setBody(ChatNodeRequest.getAction("sendTargetClientNotification", params))
                        .send();
                }

            } catch (e) {
                ConsoleWrapper.error("Error on send target notify", e);
            }
        } catch (e) {
        }
    }
}

interface ITargetClientReason {
    reason: string,
    notifyDestination: string[],
    repeatOnNewVisit: boolean,
    visitId: string
}