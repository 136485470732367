import ReactiveStorage from "./ReactiveStorage";

/*
import ChatSentryLogger from "~/chat/ts/service/ChatSentryLogger";
import {Severity} from "@sentry/types";
*/

abstract class WebStorage extends ReactiveStorage {

    protected abstract getStorage(): Storage;

    protected addOurPrefix(key: string): string {
        return this.prefix + key;
    }

    protected removeOurPrefix(key: string): string {
        if (this.isOurKey(key)) {
            key = key.substring(this.prefix.length);
        }
        return key;
    }

    protected isOurKey(key: string): boolean {
        return key.indexOf(this.prefix) === 0;
    }

    protected getFromStorage(key: string): any | null {
        let result: any;
        try {
            result = this.getStorage().getItem(this.addOurPrefix(key));
            if (result != null) {
                result = JSON.parse(result);
            }
        } catch (e) {
            this.addBreadcrumb("get", key, e);
            result = null;
        }

        return result;
    }


    private addBreadcrumb(type: string, key: string, e: Error) {
        //Это зря используется в общей либе, надо выносить отдельно в чат
        /*
        ChatSentryLogger.addBreadcrumb({
            level: Severity.Error,
            category: "storage",
            message: `${type} ${key} error: ${e.message}`
        });*/
    }

    protected setToStorage(key: string, value: any, throwError: boolean = true): void {
        try {
            this.getStorage().setItem(this.addOurPrefix(key), JSON.stringify(value));
        } catch (e) {
            this.addBreadcrumb("set", key, e);
            if (throwError) {
                throw e;
            }
        }
    }

    protected removeFromStorage(key: string): void {
        try {
            this.getStorage().removeItem(this.addOurPrefix(key));
        } catch (e) {
            this.addBreadcrumb("remove", key, e);
        }
    }

    public isAvailable(): boolean {
        let key = this.addOurPrefix("webStorageTest");
        let result = false;
        let value = 1;
        try {
            this.setToStorage(key, value);
            result = this.getFromStorage(key) === value;
            this.removeFromStorage(key);
        } catch (e) {
        }
        return result;
    }

    public abstract isPersistent(): boolean;
}

export default WebStorage;