import IMessage, {IMessageAttachment, IMessageButton, MESSAGE_TYPE_AUTO_MESSAGE} from "~/chat/ts/data/chat/IMessage";
import {ConfigStore} from "~/chat/ts/store/Config";
import {OperatorsStore} from "~/chat/ts/store/Operators";
import {IOperator} from "~/chat/ts/data/IOperator";
import {MESSAGE_BLOCK_PREFIX, MESSAGE_HTML_BLOCK_PREFIX, MESSAGE_HTML_PREFIX} from "~/chat/ts/Constants";
import ChatDateHelper from "~/chat/ts/service/ChatDateHelper";
import {__} from "~/ts/library/Translate";
import {ChatTabStore} from "~/chat/ts/store/ChatTab";
import StringHelper from "~/ts/library/StringHelper";

export const MESSAGE_WHO_SEND_CLIENT = "client";
export const MESSAGE_WHO_SEND_OPERATOR = "operator";


export default class MessageWrapper {
    private message: IMessage;
    private previousMessage?: MessageWrapper;
    private nextMessage?: MessageWrapper;

    constructor(message?: IMessage) {
        this.message = message ? message : {};
    }

    get isDateShowNeeded():boolean {
        return (!this.previousMessage || this.previousMessage.dt.toMysqlFormatDate() != this.dt.toMysqlFormatDate()) && !this.isBlockMessage;
    }

    get isFirstMessageOf(): boolean {
        return this.isDiffSender(this.previousMessage);
    }

    get isLastMessageOf(): boolean {
        return this.isDiffSender(this.nextMessage);
    }

    private isDiffSender(message?: MessageWrapper): boolean {
        let rawMessage = message ? message.rawMessage : null;
        return !rawMessage || this.rawMessage.from !== rawMessage.from || this.isHiddenText != message.isHiddenText || this.isBlockMessage != message.isBlockMessage;
    }

    public setPreviousMessage(message?: MessageWrapper) {
        this.previousMessage = message;
        return this;
    }

    public setNextMessage(message?: MessageWrapper) {
        this.nextMessage = message;
        return this;
    }

    set text(text: string | null) {
        this.message.text = text != null ? text.trim() : null;
    }

    get whoSend() {
        return this.isFromClient ? MESSAGE_WHO_SEND_CLIENT : MESSAGE_WHO_SEND_OPERATOR;
    }

    public addAttachment(file: IMessageAttachment) {
        let list = this.attachments;
        list.push(file);
        this.addParam("attachments", list);
    }

    public setReplyToMessage(message: IMessage) {
        if (message) {
            this.message.replyToMessage = message;
        }
        return this;
    }

    get knowledgeBaseArticles() {
        return this.rawMessage.knowledgeBaseArticles ? this.rawMessage.knowledgeBaseArticles : [];
    }

    get attachments(): IMessageAttachment[] {
        let result = this.getParam("attachments", []);
        if (!Array.isArray(result)) {
            result = [];
        }
        if (!result.length) {
            //TODO: это костыль, написан из-за смены форматов. Сделан 13 августа 2020. Спустя несколько месяцев можно вырезать, я думаю.
            result = this.getParam("attach", []);
            for (let item of result) {
                item.fileName = item.name;
            }
        }
        return result;
    }

    get buttons(): IMessageButton[] {
        let result: IMessageButton[] = !this.isFromClient ? this.getParam("buttons", []) : [];
        if (!Array.isArray(result)) {
            result = [];
        }
        if (result.length) {
            if (this.message.isBotMessage/* && this.message.cascadeMessageId*/) {
                if (!ChatTabStore.isBotRunning.value /*|| this.message.cascadeMessageId != ChatTabStore.cascadeMessageId.value*/) {
                    result = [];
                }
            }
        }
        return result;
    }

    public buttonClicked() {
        this.message.other.buttons = [];
        return this;
    }

    get isTextInputBlocked() {
        return this.buttons.length > 0 && !!this.message.other?.blockTextInput;
    }

    private addParam(key: string, value: any) {
        if (!this.message.other) {
            this.message.other = {};
        }
        (this.message.other as any)[key] = value;
    }

    private getParam(key: string, def: any = null) {
        if (!this.message.other || !this.message.other.hasOwnProperty(key)) {
            return def;
        } else {
            return (this.message.other as any)[key];
        }
    }

    get temporaryId(): number | null {
        let other = this.message.other;
        return other ? other.temporaryId : null;
    }

    get id() {
        return this.message.id;
    }

    get isAutoMessage() {
        return this.message.messageType === MESSAGE_TYPE_AUTO_MESSAGE;
    }

    set id(id: number) {
        this.message.id = id;
    }

    get key(): number | string {
        return this.message.key ? this.message.key : this.message.id;
    }

    get text(): string | null {
        return this.message.text;
    }


    get textForDisplay() {
        let text = this.message.text;
        if (text != null && text.length) {
            if (this.isHtmlMessage) {
                text = text.substr(6);
            }
            if (this.isBlockMessage) {
                text = text.substr(7);
            }
        } else {
            text = null;
        }

        return text;
    }

    get textForReplyPreview() {
        let result = this.textForDisplay;
        if (typeof result != "string" || !result.length || this.isHtmlMessage) {
            result = __("EMPTY_TEXT");
        }
        return result;
    }

    get isHtmlMessage(): boolean {
        return !this.isFromClient && this.message.text.indexOf(MESSAGE_HTML_PREFIX) == 0;
    }

    get isBlockMessage(): boolean {
        let text = this.text;
        return !this.isFromClient && (text.indexOf(MESSAGE_BLOCK_PREFIX) == 0 || text.indexOf(MESSAGE_HTML_BLOCK_PREFIX) == 0);
    }

    get isHideOperator(): boolean {
        return !!this.rawMessage.isHideOperator;
    }

    get notificationText(): string | null {

        let text = this.isHtmlMessage ? __("NEW_MESSAGE_TO_YOU") : this.textForDisplay;

        if (text) {
            let maxLength = ConfigStore.siteParams.value.params.newMessageNotification.maxLength;
            //text = StringHelper.stripBbCode(text);
            text = StringHelper.htmlToText(StringHelper.bbcodeToHtml(text));
            if (text.length > maxLength) {
                text = text.substring(0, maxLength) + "...";
            }
        }
        return text;
    }

    get isFromClient(): boolean {
        return this.message.from == null;
    }

    get isOffline(): boolean {
        return !!(this.message.other && this.message.other.offline);
    }

    get isHiddenText(): boolean {
        return this.message.other && this.message.other.hiddenText;
    }

    get rawMessage() {
        return this.message;
    }

    get replyToMessage(): MessageWrapper | null {
        if (this.rawMessage.replyToMessage) {
            return new MessageWrapper(this.rawMessage.replyToMessage);
        }
        return null;
    }

    get stickerUrl(): string | null {
        return this.rawMessage.other?.sticker?.url;
    }

    get dt() {
        let dt = this.message.dt ? this.message.dt : "1989-06-05 00:00:00";
        return new ChatDateHelper(dt);
    }

    get operatorId(): string | null {
        return this.isFromClient ? this.message.to : this.message.from;
    }

    get operator(): IOperator | null {
        let result: IOperator
        if (this.operatorId) {
            result = OperatorsStore.getOperatorById(this.operatorId);
        }
        if (!result) {
            if (this.rawMessage.isBotMessage) {
                result = OperatorsStore.getBotOperator();
            } else {
                result = OperatorsStore.getVirtualOperator(this.rawMessage.operatorDescr ?? __('OPERATOR'), null);
            }
        }
        return result;

    }

    get isNeedMarkAsUnreaded() {
        let result = false;
        if (!this.isFromClient/* && !this.isHiddenText*/) {
            //isHiddenText юзается в сообщениях-саджестах. Не нужно показывать про них счетчик непрочитанных
            result = true;
        }
        return result;
    }
}
