import MessageWrapper from "~/chat/ts/data/chat/MessageWrapper";
import {MESSAGE_TAB_SHOW_TYPE_SHOW} from "~/chat/ts/data/chat/IMessage";
import {WidgetStore} from "~/chat/ts/store/Widget";
import {ConfigStore} from "~/chat/ts/store/Config";
import {IWidgetTabParams} from "~/chat/ts/data/ITab";

export default class TabMessageWrapper extends MessageWrapper {
    private getTabParams() {
        return this.rawMessage.other.tab;
    }
    openTab() {
        let params = this.getTabParams();
        WidgetStore.openById({id: params.id, params});
    }

    get tab(): IWidgetTabParams | null {
        let tabId = this.getTabParams().id;
        return ConfigStore.tabsDictionary.value[tabId];
    }

    autoOpenTabIfNeed() {
        let result = false;
        let params = this.getTabParams();
        if (params && params.showType == MESSAGE_TAB_SHOW_TYPE_SHOW) {
            this.openTab();
            result = true;
        }
        return result;
    }
}