import { render, staticRenderFns } from "./ChatTrigger.vue?vue&type=template&id=278982da&scoped=true&"
import script from "./ChatTrigger.vue?vue&type=script&lang=ts&setup=true&"
export * from "./ChatTrigger.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./ChatTrigger.vue?vue&type=style&index=0&id=278982da&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "278982da",
  null
  
)

export default component.exports