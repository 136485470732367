import {StorageInstance} from "~/ts/library/storage/StorageFactory";
import {Intervals} from "~/ts/library/delay/Intervals";

export default class TrackIsOnTab {
    public static get(): boolean {
        return !!StorageInstance.getex("isOnTab");
    }

    private static lastCurrent: boolean;

    private static set() {
        StorageInstance.setex("isOnTab", 1, 750);
    }

    private static getPrevSendedValue() {
        return StorageInstance.getex("isOnTabPrev");
    }

    private static setPrevSendedValue(value: number) {
        return StorageInstance.setex("isOnTabPrev", value, 3600000);
    }

    private static interval: number;

    public static start(onChange: (value: boolean) => void) {
        Intervals.clear(this.interval);
        var value = this.isOnTab();
        if (value) {
            this.set();
        }

        var prevValue = this.get();
        this.interval = Intervals.set(() => {
            var value = this.isOnTab();
            if (value != this.lastCurrent) {
                this.lastCurrent = value;
            }
            if (value) {
                this.set();
            }
            var curValue = this.get();
            if (curValue != prevValue) {
                var prevSendedValue = this.getPrevSendedValue();
                if (prevSendedValue !== null || prevSendedValue != curValue) {
                    var int = curValue ? 1 : 0;
                    this.setPrevSendedValue(int);
                    onChange(!!int);
                }
            }
            prevValue = curValue;
        }, 500);
    }

    public static isOnTab() {
        return "visibilityState" in document ? "visible" === document.visibilityState : "webkitVisibilityState" in document && "visible" === (document as any).webkitVisibilityState
    }
}
