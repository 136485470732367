import {Intervals} from "~/ts/library/delay/Intervals";
import {Timeouts} from "~/ts/library/delay/Timeouts";
import {ListenerInstance} from "~/chat/ts/service/ListenerInstance";
import Events from "~/ts/library/Events";
import InitVue from "~/chat/ts/initialize/InitVue";
import InlineContentWatcher from "~/chat/ts/service/InlineContentWatcher";
import {ConfigStore} from "~/chat/ts/store/Config";
import {AlreadyInitializedChecker} from "~/chat/ts/initialize/AlreadyInitializedChecker";

export default async function destroyWidget(removeStyles: boolean = true) {
    delete (window as any)[ConfigStore.setup.value.apiMethod];
    if (removeStyles) {
        let rootStyle = document.querySelectorAll("#online-chat-root-styles");
        if (rootStyle.length == 1) {
            rootStyle[0].remove();
        }
    }
    await InitVue.destroy();
    await InlineContentWatcher.destroyAll();
    await ListenerInstance.destroy();
    await Events.removeAllEventListeners();
    Intervals.clearAll();
    Timeouts.clearAll();
    AlreadyInitializedChecker.setInitialized(ConfigStore.setup.value.apiMethod, false);
    //EventManager.
}