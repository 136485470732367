import Listener from "~/ts/library/listen/Listener";
import ListenerMessage from "~/ts/library/listen/ListenerMessage";

export default abstract class AbstractListenerAction {

    public abstract getAction(): string;

    public abstract onAction(payload: any, message: ListenerMessage): void;

    public attachTo(listener: Listener) {
        listener.onAction(this.getAction(), payload => this.onAction(payload.originalMessage, payload));
    }
}