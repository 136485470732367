let staticServer = "static.me-talk.ru";
let widgetServer = "widget.me-talk.ru";
let defaultStaticServer = "static.me-talk.ru";
if (staticServer.indexOf(".") == -1) {
    staticServer = defaultStaticServer;
}

export const STATIC_SERVER = "https://" + staticServer;
export const WIDGET_SERVER = `https://${widgetServer}`
export const CHAT_CONFIG_QUEUE_KEY = "OnlineChatConfigQueue";
export const DEFAULT_BOT_AVATAR = "/cabinet/assets/images/chat/bot.png";

export function replaceUrlForChatIfNeeded(url: string) {
    return url
        .split("//" + defaultStaticServer)
        .join("//" + staticServer);
}

export function isInternationalVersionUsed() {
    return staticServer != defaultStaticServer;
}