import AbstractActionFilterOperation from "~/chat/ts/service/autoActions/AbstractActionFilterOperation";

export default class OperationGreaterThan extends AbstractActionFilterOperation {
    protected check(filterName: string, filterType: string, currentValue: any, checkValue: any[]): boolean {
        for (let item of checkValue) {
            if (currentValue > item) {
                return true;
            }
        }
        return false;
    }
}