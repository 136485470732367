import WindowHelper from "~/ts/library/WindowHelper";
import Random from "~/ts/library/Random";
import {ConfigStore} from "~/chat/ts/store/Config";
import {OperatorsStore} from "~/chat/ts/store/Operators";
import {Intervals} from "~/ts/library/delay/Intervals";
import {ACTION_FILTER_COUNTRY, ACTION_FILTER_IP} from "~/chat/ts/data/AutoActions";
import Filters from "~/chat/ts/service/autoActions/Filters";
import {StorageInstance} from "~/ts/library/storage/StorageFactory";
import Events from "~/ts/library/Events";
import CobrowseInstance from "~/chat/ts/service/cobrowse/Cobrowse";
import InitializeFilters from "~/chat/ts/service/autoActions/InitializeFilters";
import MessageStorage from "~/chat/ts/service/messages/MessageStorage";
import ChatDateHelper from "~/chat/ts/service/ChatDateHelper";
import CrossTabLock from "~/ts/library/crossTabCommunication/CrossTabLock";
import AnalyticCounters from "~/chat/ts/service/AnalyticCounters";
import ConsoleWrapper from "~/ts/library/Console";
import Dictionary from "~/ts/library/Dictionary";
import TrackIsOnTab from "~/ts/library/crossTabCommunication/TrackIsOnTab";
import ChatNodeRequest from "~/chat/ts/service/ChatNodeRequest";
import Cookie from "~/ts/library/Cookie";

const CURRENT_PAGE = "currentPage";
const LAST_ONLINE = "lastOnline";

function setIsCurrentPage() {
    if (StorageInstance.get(CURRENT_PAGE) != ConfigStore.pageId.value) {
        StorageInstance.set(CURRENT_PAGE, ConfigStore.pageId.value);
    }
}


class ClientActivity {
    private updateOnline() {
        StorageInstance.setex(LAST_ONLINE, 1, 120000);
    }

    private wasOnline() {
        return !!StorageInstance.getex(LAST_ONLINE);
    }

    public get isCurrentPage() {
        return StorageInstance.get(CURRENT_PAGE) == ConfigStore.pageId.value;
    }

    public async initVisitId(): Promise<boolean> {
        let locker = new CrossTabLock("initVisitId");
        await locker.lock(true);
        let newVisit = this.isNewVisit();
        await locker.unlock();
        return newVisit;
    }


    private get googleAnalytics() {
        let gaParams: { clientId: string, trackingId: string };
        try {
            let tracker = (window as any).ga.getAll()[0];

            gaParams = {clientId: tracker.get('clientId'), trackingId: tracker.get('trackingId')};

        } catch (e) {
            try {
                let gaGlobal = (window as any).gaGlobal;
                gaParams = {
                    clientId: gaGlobal.vid,
                    trackingId: "unknown"
                };
            } catch (e) {

            }
        }

        return gaParams && gaParams.clientId && gaParams.trackingId ? gaParams : null;
    }

    private get roistat() {
        let visitId = AnalyticCounters.getRoistatVisit();
        return visitId ? {
            visitId
        } : null;
    }

    private get dataGo() {
        let clientId = AnalyticCounters.getDataGoClientId();
        return clientId ? {
            clientId
        } : null;
    }

    private get yandexMetrika() {
        try {
            let yandex = AnalyticCounters.yandexMetrika;
            if (yandex && yandex[0]) {
                let yaParams = {
                    counterId: yandex[0].counterId,
                    clientId: yandex[0].api.getClientID()
                };
                if (yaParams.clientId) {
                    return yaParams;
                }
            }
        } catch (e) {
        }
        return null;
    }

    private get calltouch() {
        try {
            let result = Cookie.get("_ct_session_id");
            return result ? {
                sessionId: result
            } : null;
        } catch (e) {
        }
        return null;
    }

    private trackIsOnTabStarted = false;

    public async start(newVisit: boolean) {
        ConfigStore.SET_PAGE_ID(Random.uid());
        setIsCurrentPage();

        let request = new ChatNodeRequest("/pushToActivityList");
        /*let newVisit = */
        //let newVisit = this.isNewVisit();
        let windowSize = WindowHelper.getSize();
        let lastMessageId = MessageStorage.maxId;
        if (!lastMessageId && newVisit && ConfigStore.isTalkIdFromSetup.value) {
            lastMessageId = 1;
        }

        let ip = Filters.get(ACTION_FILTER_IP);


        let body = ChatNodeRequest.getAction(
            "startActivity",
            {
                lastMessageID: lastMessageId,//MessageStorage.maxId,//newVisit ? 0 : 0, //TODO: newVisit ? chat.lastReceiveMessageID.get() : 0,
                newVisit: newVisit ? 1 : 0,
                lastKnownIp: ip ? ip : "",
                device: ConfigStore.device.value,
                wW: windowSize.width,
                wH: windowSize.height,
                ga: this.googleAnalytics,
                ya: this.yandexMetrika,
                now: (new ChatDateHelper(new Date())).toMysqlFormat(),
                navigatorLanguage: navigator.language,
                dataProvidersClientIds: this.dataProvidesClientIds,
                h1: document.querySelector("h1")?.innerText
            }
        )

        body.needGeoip = !Filters.get(ACTION_FILTER_COUNTRY);

        request.setBody(this.addRoistat(body));
        try {
            OperatorsStore.setStatusFromNodeResponse(await request.send());
            //this.update();
            CobrowseInstance.init();

        } catch (e) {
            ConsoleWrapper.error("Cant start activity", e);
        }

        if (!this.trackIsOnTabStarted) {
            this.trackIsOnTabStarted = true;
            TrackIsOnTab.start(async (value: boolean) => {
                try {
                    let body: CometWidget.CometClientPushToActivityListBodyOpenApiModel = ChatNodeRequest.getAction(
                        "isOnTabChanged", {
                            value: value,
                            lastMessageId: MessageStorage.maxId
                        }
                    );
                    await (new ChatNodeRequest("/pushToActivityList"))
                        .setBody(body)
                        .send();
                } catch (e) {
                }
            });
        }
    }

    public async update() {
        var pageId = ConfigStore.pageId.value;
        if (pageId != null) {
            try {
                let request = new ChatNodeRequest("/pushToUpdatePageActivity");
                let body: CometWidget.CometClientPushToUpdatePageActivityListBodyOpenApiModel = {
                    ...ChatNodeRequest.getClientCredentials(),
                    pageId: ConfigStore.pageId.value,
                    dataProvidersClientIds: this.dataProvidesClientIds
                }
                request.setBody(this.addRoistat(body));
                OperatorsStore.setStatusFromNodeResponse(await request.send());
            } catch (e) {
                //ConsoleWrapper.error("Cant update activity", e);
            }
        }
    }

    private get dataProvidesClientIds(): Dictionary<Dictionary<any>> {
        return {
            yandexMetrika: this.yandexMetrika,
            googleAnalytics: this.googleAnalytics,
            calltouch: this.calltouch,
            roistat: this.roistat,
            dataGo: this.dataGo,
            widget: {
                storage: {
                    isPersistent: StorageInstance.isPersistent()
                }
            }
        };
    }

    private addRoistat(params: Dictionary<any>) {
        let roistat = AnalyticCounters.getRoistatVisit();
        if (roistat) {
            params.roistatVisitId = roistat;
        }
        return params;
    }

    private isNewVisit() {
        let wasOnline = this.wasOnline();

        Intervals.set(() => this.updateOnline(), 1000);

        if (!wasOnline) {
            InitializeFilters.resetOnNewVisit();
        }
        if (!wasOnline || !ConfigStore.visitId.value) {
            ConfigStore.initVisitId();
        }
        this.updateOnline();
        return !wasOnline;
    }
}

const ClientActivityInstance = new ClientActivity();

Events.addEventListener("mousemove keyup touchstart", document, setIsCurrentPage);

Intervals.set(() => {
    ClientActivityInstance.update();
}, 50000);


export default ClientActivityInstance;

