import AbstractStringOperation from "~/chat/ts/service/autoActions/operations/AbstractStringOperation";

export default class OperationLike extends AbstractStringOperation {
    protected checkItem(item: string, currentValue: string): boolean {
        return currentValue.indexOf(item) > -1;
        /*
        Отключил из-за неявности работы. Возможно, стоит сделать это в виде отдельной операции, типа "содержит в любом порядке"
        let like = 0;
        let checkValueSplit = item.split(" ");
        for (let splitItem of checkValueSplit) {
            if (currentValue.indexOf(splitItem) != -1) {
                like++;
            }
        }
        return like === checkValueSplit.length;
         */
    }

}