import MessageStorage from "~/chat/ts/service/messages/MessageStorage";
import ObjectHelper from "~/ts/library/ObjectHelper";
import IMessage from "~/chat/ts/data/chat/IMessage";
import ChatDateHelper from "~/chat/ts/service/ChatDateHelper";
import ConsoleWrapper from "~/ts/library/Console";
import AbstractListenerAction from "~/ts/library/listen/AbstractListenerAction";
import {ChatTabStore} from "~/chat/ts/store/ChatTab";

export default abstract class AbstractReceiveMessageListener extends AbstractListenerAction {
    public onAction(payload: IMessage): void {
        let message = ObjectHelper.jsonClone(payload);
        if (message.dt != null && message.id != null) {
            message.dt = (new ChatDateHelper(message.dt)).convertFromServer().toMysqlFormat();
            let storedMessage = MessageStorage.getMessage(message.id);
            if (storedMessage?.key) {
                message.key = storedMessage.key;
            }
            MessageStorage.saveMessage(message);
            this.afterReceive(message);
            ChatTabStore.showRateRequestAfterNewMessage(MessageStorage.lastMessageId ?? 0);
        } else {
            ConsoleWrapper.error("Not enough params in message", payload);
        }
    }

    afterReceive(message:IMessage) {

    }

}