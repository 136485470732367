import ReactiveStorage from "./ReactiveStorage";

class FallbackStorage extends ReactiveStorage {
    protected getFromStorage(key: string): any | null {
        return null;
    }

    protected removeFromStorage(key: string): void {
    }

    protected setToStorage(key: string, value: any, throwError: boolean): void {
    }

    isAvailable(): boolean {
        return true;
    }

    isPersistent(): boolean {
        return false;
    }

    protected get isForceReloadAvailable(): boolean {
        return false;
    }

    getType(): string {
        return "fallback";
    }
}

export default FallbackStorage;