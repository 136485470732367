var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"rootElement",class:{
        'trigger-popup': true,
    },style:({
        maxWidth: _setup.maxWidth
    }),on:{"click":function($event){return _setup.emits('click')}}},[_c('div',{class:{
        inner: true,
        'with-arrow': _vm.arrow
    }},[(_vm.closable)?_c('div',{class:['close', _setup.isTouch ? 'touch' : null]},[_c(_setup.CloseIcon,{on:{"click":function($event){return _setup.emits('close')}},nativeOn:{"click":function($event){$event.stopPropagation();}}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"slot"},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }