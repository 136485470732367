import Dictionary from "~/ts/library/Dictionary";

export const FILTER_TYPE_TEXT = "text";
export const FILTER_TYPE_INT = "int";
export const FILTER_TYPE_SECONDS = "seconds";
export const FILTER_TYPE_DATE = "date";
export const FILTER_TYPE_SELECT = "select";
export const FILTER_TYPE_TIMERANGE = "timeRange";

export const AUTO_ACTION_REPEAT_NEW_VISIT = "newVisit";
export const AUTO_ACTION_REPEAT_NEVER = "never";
export const AUTO_ACTION_REPEAT_AFTER_TIME = "afterTime";
export const AUTO_ACTION_REPEAT_NEW_PAGE = "newPage";

export const OPERATION_REGEXP_MATCH = "regexpMatch";
export const OPERATION_REGEXP_NOT_MATCH = "regexpNotMatch";
export const OPERATION_EQUAL = "=";
export const OPERATION_NOT_EQUAL = "!=";
export const OPERATION_LIKE = "like";
export const OPERATION_NOT_LIKE = "not like";
export const OPERATION_LIKE_IN_ANY_ORDER = "likeInAnyOrder";
export const OPERATION_EMPTY = "empty";
export const OPERATION_NOT_EMPTY = "not empty";
export const OPERATION_GREATER_THAN = ">";
export const OPERATION_LESS_THAN = "<";
export const OPERATION_BEGIN_WITH = "beginWith";
export const OPERATION_NOT_BEGIN_WITH = "notBeginWith";
export const OPERATION_END_WITH = "endWith";
export const OPERATION_NOT_END_WITH = "notEndWith";

export const ACTION_FILTER_CLOSE_WIDGET = "closeWidget";
export const ACTION_FILTER_URL = "url";
export const ACTION_FILTER_PREVIOUS_URL = "previousUrl";
export const ACTION_FILTER_DAY_OF_WEEK = "dayOfWeek";
export const ACTION_FILTER_TIME_RANGE = "timeRange";
export const ACTION_FILTER_TIME_UTC = "timeUTC";
export const ACTION_FILTER_DATE = "date";
export const ACTION_FILTER_WINDOW_WIDTH = "windowWidth";
export const ACTION_FILTER_WINDOW_HEIGHT = "windowHeight";
export const ACTION_FILTER_ELEMENT_ON_PAGE = "elementOnPage";
export const ACTION_FILTER_CLICK_ON_ELEMENT = "clickOnElement";
export const ACTION_FILTER_SCROLL_PX = "scrollPX";
export const ACTION_FILTER_SCROLL_PERCENTAGE = "scrollPercentage";
export const ACTION_FILTER_SCROLL_TO_ELEMENT = "scrollToElement";
export const ACTION_FILTER_ZERO = "zero";
export const ACTION_FILTER_TIME_FROM_LAST_MESSAGE = "timeFromLastMessage";
export const ACTION_FILTER_LAST_MESSAGE_SENDER = "lastMessageSender";
export const ACTION_FILTER_TIME_FROM_LAST_MESSAGE_FROM_OPERATOR = "timeFromLastMessageFromOperator";
export const ACTION_FILTER_TIME_FROM_LAST_MESSAGE_FROM_CLIENT = "timeFromLastMessageFromClient";

export const ACTION_FILTER_CC = "cc";
export const ACTION_FILTER_COUNTRY = "country";
export const ACTION_FILTER_REGION = "region";
export const ACTION_FILTER_CITY = "city";
export const ACTION_FILTER_IP = "ip";
export const ACTION_FILTER_BOT_RUNNING = "botRunning";
export const ACTION_FILTER_DIALOG_OPENED = "dialogOpened";
export const ACTION_FILTER_COUNTRY_CODE = "countryCode";
export const ACTION_FILTER_CITY_LANG = "city_lang";
export const ACTION_FILTER_SEARCH_KEYWORD = "searchKeyword";
export const ACTION_FILTER_VISIT_START_TIME = "visitStartTime";
export const ACTION_FILTER_AUTO_MESSAGE_TO_CLIENT = "autoMessageToClientCount";
export const ACTION_FILTER_AUTO_MESSAGE_TO_CLIENT_AT_VISIT = "autoMessageToClientCountAtVisit";
export const ACTION_FILTER_MESSAGES_COUNT_AT_VISIT = "messagesCountAtVisit";
export const ACTION_FILTER_PAGES_AT_VISIT = "pagesAtVisit";
export const ACTION_FILTER_CLIENT_MESSAGES_COUNT_AT_VISIT = "clientMessagesCountAtVisit";
export const ACTION_FILTER_CLIENT_MESSAGES_COUNT_AT_ALL_TIME = "clientMessagesCountAtAllTime";
export const ACTION_FILTER_OPERATOR_MESSAGES_COUNT_AT_VISIT = "operatorMessagesCountAtVisit";
export const ACTION_FILTER_TIME_FROM_LAST_AUTO_MESSAGE_TO_CLIENT = "timeFromLastAutoMessageToClient";
export const ACTION_FILTER_VISITS = "visits";
export const ACTION_FILTER_TIME_ON_PAGE = "timeOnPage";
export const ACTION_FILTER_TEST_GROUP = "testGroup";
export const ACTION_FILTER_IS_TARGET_CLIENT = "isTargetClient";
export const ACTION_FILTER_DEVICE = "device";
export const ACTION_FILTER_NEW_MESSAGE_FROM_CLIENT = "newMessageFromClient";
export const ACTION_FILTER_NEW_MESSAGE_FROM_OPERATOR = "newMessageFromOperator";
export const ACTION_FILTER_MOUSE_LEAVE_PAGE = "mouseLeavePage";
export const ACTION_FILTER_WIDGET_STATE = "widgetState";
export const ACTION_FILTER_COL_OPERATORS_ONLINE_AND_VISIBLE = "colOperatorsOnline";
export const ACTION_FILTER_COL_OPERATORS_ONLINE = "colOperatorsWithStatusOnline";
export const ACTION_FILTER_OPERATOR_ONLINE_AND_VISIBLE = "operatorOnline";
export const ACTION_FILTER_OPERATOR_ONLINE = "operatorWithStatusOnline";
export const ACTION_FILTER_REFERER = "referer";
export const ACTION_FILTER_CURRENT_PAGE_TITLE = "currentPageTitle";
export const ACTION_FILTER_PAGE_VISITED = "pageVisited";
export const ACTION_FILTER_UTM_CONTENT = "utm_content";
export const ACTION_FILTER_UTM_TERM = "utm_term";

export const ACTION_TYPE_SEND_TEXT_MESSAGE_TO_CLIENT = "sendTextMessageToClient";
export const ACTION_TYPE_SHOW_SUGGESTS = "showSuggests";
export const ACTION_TYPE_SHOW_TAB = "showTab";
export const ACTION_TYPE_SHOW_TRIGGER_MENU = "showTriggerMenu";
export const ACTION_TYPE_DESTROY_WIDGET = "destroyWidget";
export const ACTION_TYPE_SHOW_OPERATOR = "showOperator";
export const ACTION_TYPE_HIDE_OPERATOR = "hideOperator";
export const ACTION_TYPE_SHOW_OPERATORS_GROUP = "showOperatorsGroup";
export const ACTION_TYPE_HIDE_OPERATORS_GROUP = "hideOperatorsGroup";
export const ACTION_TYPE_SET_TAB_VISIBLE = "setTabVisible";
export const ACTION_TYPE_SET_TAB_INVISIBLE = "setTabInvisible";
export const ACTION_TYPE_SHOW_TRIGGER = "showTrigger";
export const ACTION_TYPE_HIDE_TRIGGER = "hideTrigger";
export const ACTION_TYPE_RUN_JAVASCRIPT = "runJavascript";
export const ACTION_TYPE_SEND_TO_ANALYTICS = "sendToAnalytics";
export const ACTION_TYPE_SEND_WEBHOOK = "sendWebhook";
export const ACTION_TYPE_SET_TARGET = "setTarget";
export const ACTION_TYPE_SET_FLAG = "setFlag";
export const ACTION_TYPE_START_BOT = "startBot";
export const ACTION_TYPE_FIRE_CUSTOM_EVENT = "fireCustomEvent";

export const ACTION_FILTER_WIDGET_STATE_OPENED = "opened";
export const ACTION_FILTER_WIDGET_STATE_CLOSED = "closed";


export type AutoActionFilterType = "text" | "int" | "seconds" | "date" | "select" | "timeRange";
export type IFilterValue = any[];
export type IAutoActionFilters = Dictionary<Dictionary<IFilterValue>> | false;
export type IAutoActionRepeatType = "newVisit" | "never" | "afterTime" | "newPage"
export type IAutoActionRepeatConfig = [IAutoActionRepeatType, IAutoActionRepeatTypeConfig?]

export type IAutoActionRepeatTypeConfig = {
    repeatSeconds?: IAutoActionTimeValue
};

export type IAutoActionTimeValue = {
    seconds: number,
    multiply: number
};

export type AutoActionModel = {
    id: string
    active: 0 | 1
} | Dictionary<any>;

export interface IAutoActionType {
    actions: AutoActionModel[],
    id: string,
    repeat?: IAutoActionRepeatConfig
}

export interface IActionSubfilter {
    filters: IAutoActionFilters,
    id: string,
    actionTypes: Dictionary<IAutoActionType>
    lastSuccessfulFilterCheckRunId?: string;
}

export interface IActionBlock {
    active: 0 | 1,
    descr: string;
    filters: IAutoActionFilters,
    id: string,
    index: number,
    subFilters: IActionSubfilter[]
}

export interface IAutoActionStatistic {
    send: number,
    receivers: number,
    sendsTarget: number,
    receiversTarget: number
}