import AbstractListenerAction from "~/ts/library/listen/AbstractListenerAction";
import {ChatTabStore, WidgetDataInterface} from "~/chat/ts/store/ChatTab";

export default class WidgetDataListener extends AbstractListenerAction {
    public getAction(): string {
        return "widgetData";
    }

    public onAction(p: WidgetDataInterface): void {
        ChatTabStore.setWidetData(p);
    }
}