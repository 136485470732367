import AbstractAutoAction, {
    IAutoActionSuccessRunResult
} from "~/chat/ts/service/autoActions/actions/AbstractAutoAction";
import {IShowSuggestsParams} from "~/chat/ts/service/autoActions/actions/Interfaces";
import {
    NO_BADGE_DESKTOP,
    NO_BADGE_MOBILE,
    SILENT_DESKTOP,
    SILENT_MOBILE
} from "~/chat/ts/service/autoActions/actions/Constants";
import SendTextMessageToClient from "~/chat/ts/service/autoActions/actions/SendTextMessageToClient";
import MessageStorage from "~/chat/ts/service/messages/MessageStorage";
import {ConfigStore} from "~/chat/ts/store/Config";


export default class ShowSuggests extends AbstractAutoAction<IShowSuggestsParams> {
    public isNeedSendResultToServer(): boolean {
        return true;
    }

    get isNoize(): boolean {
        return true;
    }

    async run(): Promise<IAutoActionSuccessRunResult[]> {
        await (new SendTextMessageToClient({
            ...this.params,
            notify: ConfigStore.isShowMessageButtonsInNotification.value ? [] : [SILENT_DESKTOP, SILENT_MOBILE, NO_BADGE_DESKTOP, NO_BADGE_MOBILE],
            senderType: "any",
            text: [{
                delay: 0,
                text: this.id,
                type: "text"
            }],
            typing: 0,
            hiddenText: true,
            removePreviousNotAnsweredAutoMessages: false
        }, false)).run();
        return [{}];
    }


    isCanRunNow(): boolean {
        let lastMessageId = MessageStorage.lastMessageId;
        if (lastMessageId) {
            let message = MessageStorage.getMessage(lastMessageId);
            if (message && message.text == this.id) {
                return false;
            }
        }
        return true;
    }


}

