import ConsoleWrapper from "~/ts/library/Console";

export default class SafeEval {
    public static make(code: string) {
        try {
            (function (code: string) {
                eval(code);
            }(code));
        } catch (e) {
            ConsoleWrapper.error("eval exception", e);
        }
    }
}