import {WidgetStore} from "~/chat/ts/store/Widget";
import {ChatTabStore} from "~/chat/ts/store/ChatTab";
import AbstractListenerAction from "~/ts/library/listen/AbstractListenerAction";
import MessageStorage from "~/chat/ts/service/messages/MessageStorage";

export default class RequestRateListener extends AbstractListenerAction {
    public getAction(): string {
        return "requestRate";
    }

    public onAction(): void {
        if (WidgetStore.reviewsTab.value) {
            WidgetStore.openChatTab();
            ChatTabStore.showRateRequest(MessageStorage.lastMessageId ?? 0);
        }
    }
}