import {OperatorsStore} from "~/chat/ts/store/Operators";
import {IOperator, IOperatorGroup} from "~/chat/ts/data/IOperator";
import AbstractListenerAction from "~/ts/library/listen/AbstractListenerAction";
import ListenerMessage from "~/ts/library/listen/ListenerMessage";
import {ListenerInstance} from "~/chat/ts/service/ListenerInstance";

export default class ChangeOperatorListener extends AbstractListenerAction {
    public getAction(): string {
        return "changeOperator";
    }

    public onAction(payload: {
        timestamp: number,
        /** @deprecated */
        operator?: string,
        operatorId: string,
        group?: string
    }, message: ListenerMessage): void {
        let operator: IOperator;
        let group: IOperatorGroup;
        let ttlMinus = ListenerInstance.getChannel().serverNow - payload.timestamp * 1000;
        if (payload.operator && !payload.operatorId) {
            payload.operatorId = OperatorsStore.getOperatorByLogin(payload.operator)?.id;
        }
        if (payload.operatorId) {
            operator = OperatorsStore.getOperatorById(payload.operatorId);
            if (operator) {
                OperatorsStore.setOperatorTemporaryVisible(operator, ttlMinus);
            }
        }
        if (payload.group) {
            group = OperatorsStore.groups.value[payload.group];
            if (group) {
                OperatorsStore.setOperatorGroupTemporaryVisible(group, ttlMinus);
                for (let operator of OperatorsStore.getOperatorsOfGroup(group)) {
                    OperatorsStore.setOperatorTemporaryVisible(operator, ttlMinus);
                }
            }
        }
        if (operator || group) {
            OperatorsStore.setSelectedOperator(operator, group);
        }

    }
}