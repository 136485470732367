import {ConfigStore} from "~/chat/ts/store/Config";
import {
    FIELD_AUTO_FILL_FROM_ATTRIBUTE,
    FIELD_AUTO_FILL_FROM_FIXED_TEXT,
    FIELD_AUTO_FILL_FROM_TEXT_CONTENT,
    FIELD_AUTO_FILL_SOURCE_TYPE_CLICK,
    FIELD_AUTO_FILL_SOURCE_TYPE_ELEMENT,
    FIELD_AUTO_FILL_SOURCE_TYPE_INPUT,
    IOnlineClientAutoFillClickParams,
    IOnlineClientAutoFillElementParams,
    IOnlineClientAutoFillInputParams,
    IOnlineClientField,
    IOnlineClientFieldAutoFill
} from "~/chat/ts/data/ISiteParams";
import {Intervals} from "~/ts/library/delay/Intervals";
import setClientInfo from "~/chat/ts/service/api/methods/SetClientInfo";
import AutoActionGroup from "~/chat/ts/service/autoActions/AutoActionGroup";
import Events from "~/ts/library/Events";
import {StorageInstance} from "~/ts/library/storage/StorageFactory";
import Delay from "~/ts/library/Delay";
import Filters from "~/chat/ts/service/autoActions/Filters";

export default class FieldAutoFiller {
    public static init() {
        let siteParams = ConfigStore.siteParams.value;
        if (!siteParams.free) {
            let formElements = siteParams.formElements;
            for (let key in formElements) {
                if (formElements.hasOwnProperty(key)) {
                    let field = formElements[key];
                    if (field.params && field.params.autoFill) {
                        if (Array.isArray(field.params.autoFill)) {
                            for (let item of field.params.autoFill) {
                                this.setup(field, item);
                            }
                        }
                    }
                }
            }
        }
    }

    private static getElement(selector: string): HTMLElement {
        try {
            return document.querySelector(selector);
        } catch (e) {
            return null;
        }
    }

    private static setup(field: IOnlineClientField, autoFill: IOnlineClientFieldAutoFill) {
        let type = autoFill.source.type;
        if (type == FIELD_AUTO_FILL_SOURCE_TYPE_ELEMENT) {
            this.setupElement(field, autoFill, autoFill.source.params as IOnlineClientAutoFillElementParams);
        } else if (type == FIELD_AUTO_FILL_SOURCE_TYPE_CLICK) {
            this.setupClick(field, autoFill, autoFill.source.params as IOnlineClientAutoFillClickParams);
        } else if (type == FIELD_AUTO_FILL_SOURCE_TYPE_INPUT) {
            this.setupInput(field, autoFill, autoFill.source.params as IOnlineClientAutoFillInputParams);
        }
    }

    private static setupElement(field: IOnlineClientField, autoFill: IOnlineClientFieldAutoFill, params: IOnlineClientAutoFillElementParams) {
        let callback = () => {
            let element = this.getElement(params.selector);
            if (element) {
                this.emitValue(field, autoFill, this.getValueFromElement(element, params));
            } else if (params.clearIfElementNotExist) {
                this.emitValue(field, autoFill, null, true);
            }
        };
        Intervals.set(() => callback(), 1000);
        callback();
    }

    private static setupClick(field: IOnlineClientField, autoFill: IOnlineClientFieldAutoFill, params: IOnlineClientAutoFillElementParams) {
        Events.addEventListener("click", document, e => {
            let element: HTMLElement = this.getElementByEvent(e, params.selector);
            if (element) {
                this.emitValue(field, autoFill, this.getValueFromElement(element, params));
            }
        }, {passive: true});
    }

    private static getElementByEvent(e: Event, selector: string): HTMLElement {
        let element: HTMLElement = e.target as any;
        while (element) {
            try {
                if (element.matches(selector)) {
                    return element;
                }
            } catch (e) {

            }
            element = element.parentElement;
        }
        return null;
    }

    private static setupInput(field: IOnlineClientField, autoFill: IOnlineClientFieldAutoFill, params: IOnlineClientAutoFillInputParams) {
        let callback = (e: Event) => {
            let element: HTMLElement = this.getElementByEvent(e, params.selector);
            if (element) {
                this.emitValue(field, autoFill, this.getValueFromInput(element));
            }
        };
        for (let event of ["click", "blur", "change"]) {
            document.addEventListener(event, callback, {passive: true});
        }
    }

    private static getValueFromElement(element: HTMLElement, params: IOnlineClientAutoFillElementParams): string {
        let value: string;
        if (params.from == FIELD_AUTO_FILL_FROM_TEXT_CONTENT) {
            value = element.textContent;
        } else if (params.from == FIELD_AUTO_FILL_FROM_ATTRIBUTE) {
            value = element.getAttribute(params.attributeName);
        } else if (params.from == FIELD_AUTO_FILL_FROM_FIXED_TEXT) {
            value = params.fixedText;
        }
        return value;
    }

    private static getValueFromInput(element: HTMLElement): string {
        return (element as HTMLInputElement).value;
    }

    private static async isSendAllowed(field: IOnlineClientField) {
        try {
            let key = `fieldAutoFillerLastTime_${field.name}`;
            let lastTime = StorageInstance.getex(key);
            let now = Date.now();
            const ttl = 10000;
            let wait = ttl - (now - lastTime);
            if (wait > 0) {
                await Delay.make(wait, key, true)
            }
            StorageInstance.setex(key, Date.now(), ttl);
            return true;
        } catch (e) {

        }
        return false;
    }

    private static async emitValue(field: IOnlineClientField, autoFill: IOnlineClientFieldAutoFill, value: string, allowNull: boolean = false) {
        if (typeof value == "string") {
            value = value.trim().substr(0, 1000);
        }
        if (value?.length || allowNull) {
            if (autoFill.filters) {
                if (!autoFill.preparedFilters) {
                    autoFill.preparedFilters = Filters.getPreparedFilterList(autoFill.filters);
                }
                if (!AutoActionGroup.isMatch(autoFill.preparedFilters).isSuccess) {
                    return;
                }
            }
            if (await this.isSendAllowed(field)) {
                let payload: any = {
                    custom: {
                        [field.name]: value
                    }
                };
                if (field.name == "descr") {
                    payload = {
                        descr: value
                    };
                } else if (field.name == "phone") {
                    payload = {
                        phone: value
                    }
                } else if (field.name == "email") {
                    payload = {
                        email: value
                    }
                }
                setClientInfo(payload);
            }
        }
    }
}