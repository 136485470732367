import OperationLike from "~/chat/ts/service/autoActions/operations/OperationLike";

export default class OperationNotLike extends OperationLike {
    protected check(filterName: string, filterType: string, currentValue: any, checkValue: any[]): boolean {
        return !super.check(filterName, filterType, currentValue, checkValue);
    }

    get returnFalseOnFirstMismatch(): boolean {
        return true;
    }

    get returnOnEmptyFilterValues(): boolean {
        return true;
    }
}