import {AutoActionPreparedFilter} from "~/chat/ts/service/autoActions/Filters";
import {ACTION_FILTER_ZERO} from "~/chat/ts/data/AutoActions";

export default class AutoActionPreparedFilters {
    mustMatch: AutoActionPreparedFilter[];
    mustNotMatch: AutoActionPreparedFilter[][];

    constructor(mustMatch: AutoActionPreparedFilter[], mustNotMatch: AutoActionPreparedFilter[][]) {
        this.mustMatch = mustMatch;
        this.mustNotMatch = mustNotMatch;
    }

    public getFilterIdList(): string[] {
        let result:string[] = [
            ACTION_FILTER_ZERO
        ];
        for (let preparedFilters of [[this.mustMatch], this.mustNotMatch]) {
            for (let items of preparedFilters) {
                for (let item of items) {
                    let filterId = item.filterId;
                    if (result.indexOf(filterId) == -1) {
                        result.push(filterId);
                    }
                }
            }
        }
        return result;
    }
}