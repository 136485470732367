import AbstractAutoAction, {IAutoActionSuccessRunResult} from "~/chat/ts/service/autoActions/actions/AbstractAutoAction";
import {AutoActionModel} from "~/chat/ts/data/AutoActions";
import AnalyticCounters from "~/chat/ts/service/AnalyticCounters";

export default class SendToAnalytics extends AbstractAutoAction<IRunSendToAnalyticsParams> {
    async run(): Promise<IAutoActionSuccessRunResult[]> {
        AnalyticCounters.send(this.params.eventId, null, null, true);
        return [{}];
    }

    get isNoize(): boolean {
        return true;
    }

    isNeedSendResultToServer(): boolean {
        return true;
    }

    isCanRunNow(): boolean {
        return true;
    }
}

type IRunSendToAnalyticsParams = AutoActionModel & {
    eventId: string
}