import Dictionary from "~/ts/library/Dictionary";
import Random from "~/ts/library/Random";
import Device, {DEVICE_DESKTOP, DeviceId, DEVICES, MOBILE_DEVICES} from "~/ts/library/Device";
import ISiteParams, {
    IOnlineClientFieldParams,
    ITriggerMenuDevice,
    ITriggerMenuTypeOptions,
    ITriggerParams,
    MESSAGE_NOTIFICATION_BUBBLE,
    MESSAGE_NOTIFICATION_OPEN,
    MESSAGE_NOTIFICATION_VK,
    NewMesageNotificationDeviceParams,
    TRIGGER_MENU_HIDE_CLICK,
    TRIGGER_MENU_SHOW_CLICK,
    TRIGGER_TYPE_CLASSIC,
    WIDGET_OFFLINE_MODE_HIDE
} from "~/chat/ts/data/ISiteParams";
import {
    CLIENT_CAPTCHA_FIELD_NAME,
    CLIENT_DATA_TYPE_CAPTCHA,
    CLIENT_DATA_TYPE_EMAIL,
    CLIENT_DATA_TYPE_EULA,
    CLIENT_DATA_TYPE_PHONE
} from "~/chat/ts/data/IClient";
import Vue, {computed, ref} from "vue";
import {StorageInstance} from "~/ts/library/storage/StorageFactory";
import {IWidgetTabParams, TAB_TYPE_CHAT} from "~/chat/ts/data/ITab";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {IOperator, IOperatorGroup} from "~/chat/ts/data/IOperator";
import {TRIGGER_MENU_TYPES} from "~/cabinet/vue/client/online/config/design/tabs/trigger/Interfaces";
import {STATIC_SERVER} from "~/chat/ts/CommonConstants";
import {ISetup} from "~/chat/ts/data/ISetup";
import ConsoleWrapper from "~/ts/library/Console";
import {OPERATOR_STATUS_OFFLINE, OPERATOR_STATUS_ONLINE} from "~/chat/ts/data/OperatorStatus";
import WindowHelper from "~/ts/library/WindowHelper";
import {ChatEventManagerInstance} from "~/chat/ts/ChatEventManager";
import GradientPickerHelper from "~/core-ui/vue/ui/gradient-picker/GradientPickerHelper";

export const GROUP_ID_FOR_OPERATORS_WITHOUT_GROUP: string = "noGroup";

function fixConfig(p: ISiteParams) {
    for (let login in p.operators) {
        if (p.operators.hasOwnProperty(login)) {
            let operator = p.operators[login];
            if (operator.avatar) {
                operator.avatar = STATIC_SERVER + operator.avatar;
            }
        }
    }
    let avatar = p.params.operators.balance.smart.avatar;
    if (avatar && !avatar.includes("://")) {
        if (!p.params.operators.balance.smart.isBase64) {
            p.params.operators.balance.smart.avatar = STATIC_SERVER + avatar;
        }
    }

    if (ConfigStore.setup.value.test) {
        ConsoleWrapper.warn("fixConfig надо выпилить после релиза нового виджета");
    }

    p.free = !!p.free;

    /*
    if (p.free) {
        p.params.operators.balance.smart.avatar = null;
    }
    */

    for (let login in p.operators) {
        if (p.operators.hasOwnProperty(login)) {
            let operator = p.operators[login];
            operator.canReceiveLinks = !!operator.canReceiveLinks;
            operator.canViewTyping = !!operator.canViewTyping;
            operator.hidden = !!operator.hidden;
            if (!operator.groupID) {
                operator.groupID = null;
            }
        }
    }
    if (Array.isArray(p.operatorsGroups)) {
        p.operatorsGroups = {};
    }

    let formElements = p.formElements;
    let formElementEmptyParams: IOnlineClientFieldParams = {
        sendChangedValueToClient: false,
        alwaysVisibleInApplication: false,
        autoFill: [],
        autoLinks: [],
        contactbookColumnId: null,
        fixedContactbookColumnId: null,
        visibleInOperatorApplication: false,
        contactbookTwoWaySync: false,
        isInClientDisplayDescrFields: false
    };
    formElements[CLIENT_DATA_TYPE_EULA] = {
        name: CLIENT_DATA_TYPE_EULA,
        type: CLIENT_DATA_TYPE_EULA,
        descr: null,
        params: formElementEmptyParams
    };
    formElements[CLIENT_CAPTCHA_FIELD_NAME] = {
        name: CLIENT_CAPTCHA_FIELD_NAME,
        type: CLIENT_DATA_TYPE_CAPTCHA,
        descr: null,
        params: formElementEmptyParams
    };

    if (formElements["phone"]) {
        formElements["phone"].type = CLIENT_DATA_TYPE_PHONE;
    }

    if (formElements["email"]) {
        formElements["email"].type = CLIENT_DATA_TYPE_EMAIL;
    }

    let questionCategories = p.params.leadGen.questionCategories;
    questionCategories.ask = !!questionCategories.ask;


    let groupsCount = 0;
    for (let groupId in p.operatorsGroups) {
        if (p.operatorsGroups.hasOwnProperty(groupId)) {
            groupsCount++;
            let group = p.operatorsGroups[groupId];
            group.hidden = !!group.hidden;
            if (typeof group.initialHidden == "undefined") {
                group.initialHidden = group.hidden;
            }
        }
    }

    let fakeGroup: IOperatorGroup = {
        id: GROUP_ID_FOR_OPERATORS_WITHOUT_GROUP,
        descr: "Online",
        hidden: false,
        questionCategories: [],
        virtual: true,
        initialHidden: false
    };
    let needFakeGroup = false;

    //Раньше hidden считался на сервере. Теперь это делается динамически в OperatorsStore
    for (let operatorLogin in p.operators) {
        if (p.operators.hasOwnProperty(operatorLogin)) {
            let operator = p.operators[operatorLogin];
            operator.hidden = false;
            if (!operator.groupID && groupsCount) {
                operator.groupID = [GROUP_ID_FOR_OPERATORS_WITHOUT_GROUP];
                needFakeGroup = true;
            }
        }
    }

    if (needFakeGroup) {
        p.operatorsGroups[GROUP_ID_FOR_OPERATORS_WITHOUT_GROUP] = fakeGroup;
    }


    let designParams = p.params.designParams;
    if (!designParams.badgeBackground || !designParams.badgeBackground.length || !designParams.badgeColor || !designParams.badgeColor.length) {
        designParams.badgeBackground = "#ff6c60";
        designParams.badgeColor = "#ffffff";
    }

    p.params.newMessageNotification.autoCloseNotify = parseInt(p.params.newMessageNotification.autoCloseNotify as any);
    let trigger: Dictionary<ITriggerParams> = p.params.trigger as any;
    for (let device of DEVICES) {
        if (trigger[device] != null) {
            var triggerConfig = trigger[device];
            triggerConfig.borderWidth = parseInt(triggerConfig.borderWidth as any);
            triggerConfig.fontSize = parseInt(triggerConfig.fontSize as any);
            triggerConfig.offlineImageHeight = parseInt(triggerConfig.offlineImageHeight as any);
            triggerConfig.offlineImageWidth = parseInt(triggerConfig.offlineImageWidth as any);
            triggerConfig.onlineImageHeight = parseInt(triggerConfig.onlineImageHeight as any);
            triggerConfig.onlineImageWidth = parseInt(triggerConfig.onlineImageWidth as any);
            triggerConfig.radius = parseInt(triggerConfig.radius as any);

            //А ВОТ ЭТО НЕЛЬЗЯ ВЫПИЛИВАТЬ
            if (triggerConfig.triggerType == TRIGGER_TYPE_CLASSIC) {
                triggerConfig.offset.primary.value = 0;
                /*
                if (device == DEVICE_DESKTOP) {
                    let menu = p.params.trigger.menu.devices.desktop;
                    if (menu.displayType == TRIGGER_MENU_TYPE_ROUND) {
                        menu.displayType = TRIGGER_MENU_TYPE_DROPDOWN;
                    }
                }
                 */
            }

        }
    }

    //А ВОТ ЭТО НЕЛЬЗЯ ВЫПИЛИВАТЬ
    for (let device of MOBILE_DEVICES) {
        let menu = (p.params.trigger.menu.devices as Dictionary<ITriggerMenuDevice>)[device] as any as Dictionary<ITriggerMenuTypeOptions>;
        if (menu) {
            //menu.displayType = TRIGGER_MENU_TYPE_MOBILE;
            for (let triggerMenuType of TRIGGER_MENU_TYPES) {
                menu[triggerMenuType.id] = {
                    show: TRIGGER_MENU_SHOW_CLICK,
                    hide: TRIGGER_MENU_HIDE_CLICK
                };
            }
        }
    }

    for (let tab of p.tabs) {
        if (!tab.iconColor) {
            tab.iconColor = GradientPickerHelper.getOneColor(p.params.designParams.triggerBackground);
        }
        tab.visible = 1;
    }
    //Внедрили сортировку из-за того, что у колхоза вкладка отзывов оказалась выше чата, и в оффлайне показывались отзывы
    p.tabs.sort((a, b) => {
        if (a.type == TAB_TYPE_CHAT) {
            return -1;
        } else if (b.type == TAB_TYPE_CHAT) {
            return 1;
        }
        return 0;
    });

}

const VISIT_ID_KEY = "visitId";
const STORAGE_ATTACHED_OPERATORS = "attachedOperators";
const STORAGE_ATTACHED_OPERATORS_ONLY_IF_ONLINE = "attachedOperatorsOnlyIfOnline";

const STORAGE_ATTACHED_OPERATORS_REPLACEMENTS = "attachedOperatorsReplacements";


class Config {
    setup = ref<ISetup>();
    instanceId = ref<string>(Random.uid(10));
    config = ref<Dictionary<ISiteParams>>({});
    apiMethod = ref<string>();
    pageId = ref<string>();
    device = ref<DeviceId>(Device.type as DeviceId);
    readonly isMobile = computed(() => {
        return this.device.value != DEVICE_DESKTOP;
    });
    readonly rootClass = computed(() => {
        return `online-chat-root-${this.apiMethod.value}`;
    });
    readonly needScaleFix = computed(() => {
        return this.isMobile.value && WindowHelper.getVisibleViewport().scale != 1 && window.innerHeight != window.outerHeight;
    });
    readonly attachedOperatorIds = computed<string[]>(() => {
        let result: string[] = StorageInstance.get(STORAGE_ATTACHED_OPERATORS);
        return result && result.length ? result : null;
    });
    readonly attachOnlyOnlineOperator = computed<boolean>(() => {
        return !!StorageInstance.get(STORAGE_ATTACHED_OPERATORS_ONLY_IF_ONLINE);
    });
    readonly attachedVacationReplacementsOperatorIds = computed<string[]>(() => {
        let result: string[] = StorageInstance.get(STORAGE_ATTACHED_OPERATORS_REPLACEMENTS);
        return result && result.length ? result : null;
    });
    readonly tabsDictionary = computed<Dictionary<IWidgetTabParams>>(() => {
        return ObjectHelper.dictionaryFromArray(this.tabs.value);
    });
    readonly clientIdFromSetup = computed(() => {
        return this.setup.value.clientId ? this.setup.value.clientId.toString() : null;
    });
    readonly talkIdFromSetup = computed(() => {
        return this.setup.value.talkId ? this.setup.value.talkId.toString() : null;
    });
    readonly isTalkIdFromSetup = computed(() => {
        return this.clientIdFromSetup.value || this.talkIdFromSetup.value;
    });
    readonly design = computed(() => {
        return this.siteParams.value?.params.designParams;
    });
    readonly siteId = computed(() => {
        return this.siteParams.value?.id;
    });
    readonly orgId = computed(() => {
        return this.siteParams.value?.orgID;
    });
    readonly isIe = computed(() => {
        return /MSIE|Trident/.test(window.navigator.userAgent);
    });
    readonly apiHost = computed(() => {
        return this.setup.value.test && !this.isIe.value ? "https://dev.apibcknd.com" : "https://widget.me-talk.ru";
    });

    //TODO: удалить
    public setAttachedOperators(payload: {
        operatorIds: string[],
        vacationReplacementIds: string[],
        onlyIfOnline: boolean
    }) {
        let operators = payload.operatorIds;
        let vacationReplacements = payload.vacationReplacementIds;
        operators.length ? StorageInstance.set(STORAGE_ATTACHED_OPERATORS, operators) : StorageInstance.remove(STORAGE_ATTACHED_OPERATORS);
        vacationReplacements.length ? StorageInstance.set(STORAGE_ATTACHED_OPERATORS_REPLACEMENTS, vacationReplacements) : StorageInstance.remove(STORAGE_ATTACHED_OPERATORS_REPLACEMENTS);
        StorageInstance.set(STORAGE_ATTACHED_OPERATORS_ONLY_IF_ONLINE, payload.onlyIfOnline);
    }

    readonly language = computed<string>(() => {
        return this.setup.value.language;
    });
    readonly siteParams = computed(() => {
        let language = this.language.value;
        let config = this.config.value;
        if (language && config[language]) {
            return config[language];
        }
        return null;
    });
    readonly operators = computed<IOperator[]>(() => {
        let result = ObjectHelper.arrayFromDictionary(this.siteParams.value?.operators ?? {});
        let isAttachEnabled = ConfigStore.siteParams.value.params.operators.permanentAttachClientToOperators || ConfigStore.siteParams.value.params.leadGen.attachClientToGroupUntilHaveNoAnswerFinished;
        if (isAttachEnabled) {
            let attachedOperators = this.attachedOperatorIds.value;
            if (attachedOperators) {
                let replacements = this.attachedVacationReplacementsOperatorIds.value;
                if (replacements) {
                    attachedOperators = [...attachedOperators, ...replacements];
                }
                result = [...result];
                let newResult: IOperator[] = [];
                for (let operator of result) {
                    if (operator.virtual || attachedOperators.includes(operator.id)) {
                        newResult.push(operator);
                    }
                }
                if (this.attachOnlyOnlineOperator) {
                    if (!newResult.find(operator => operator.status == OPERATOR_STATUS_ONLINE)) {
                        newResult = result;
                    }
                }
                result = newResult;
            }
        }
        return result;
    });
    readonly isWidgetDependsOnOperatorState = computed(() => {
        if (this.siteParams.value.free) {
            return false;
        }
        return this.siteParams.value.params.leadGen.mode == WIDGET_OFFLINE_MODE_HIDE;
    });
    readonly tabs = computed(() => {
        return this.siteParams.value?.tabs ?? [];
    });
    readonly notificationParams = computed<NewMesageNotificationDeviceParams>(() => {
        let siteParams = this.siteParams.value;
        let result;
        if (siteParams) {
            let params = siteParams.params.newMessageNotification;
            result = this.isMobile.value ? params.mobile : params.desktop;
        }
        return result;
    });
    readonly openChatOnNewMessage = computed(() => {
        let params = this.notificationParams.value;
        return params && params.action == MESSAGE_NOTIFICATION_OPEN;
    });
    readonly bubbleOnNewMessage = computed(() => {
        let params = this.notificationParams.value;
        return params && params.action == MESSAGE_NOTIFICATION_BUBBLE;
    });
    readonly isShowMessageButtonsInNotification = computed(() => {
        return !!this.notificationParams.value?.showMessageButtons;
    });
    readonly popupNotifyOnNewMessage = computed(() => {
        let params = this.notificationParams.value;
        return params && [MESSAGE_NOTIFICATION_VK, MESSAGE_NOTIFICATION_OPEN].indexOf(params.action) > -1;
    });
    readonly referrer = computed(() => {
        let result = document.referrer;
        let storageKey = "referrer";
        if (result.length == 0 || result.indexOf("://" + window.location.host) > -1) {
            result = StorageInstance.get(storageKey);
        } else {
            StorageInstance.set(storageKey, result);
        }
        return result;
    });
    readonly visitId = computed<string>(() => {
        return StorageInstance.get(VISIT_ID_KEY);
    });
    widgetPaddingLeft = ref(1.5);
    readonly hasContentBorderRadius = computed(() => {
        return !!this.design.value?.content.border.top.round;
    });
    readonly replaceChatTab = computed(() => {
        if (this.siteParams.value) {
            let tabId = this.siteParams.value.params.leadGen.replaceTabId;
            return this.tabsDictionary.value[tabId];
        } else {
            return null;
        }
    });
    private readonly operatorsGroups = computed(() => {
        return this.siteParams.value?.operatorsGroups ?? {};
    });

    SET_API_METHOD(apiMethod: string) {
        this.apiMethod.value = apiMethod;
    }

    SET_FIELD_DESCR(payload: { name: string, descr: string }) {
        let field = this.siteParams.value.formElements[payload.name];
        if (field) {
            field.descr = payload.descr;
        }
    }

    public SET_PAGE_ID(pageId: string) {
        this.pageId.value = pageId;
    }

    public SET_DEVICE(device: DeviceId) {
        this.device.value = device;
        ChatEventManagerInstance.emit(ChatDeviceChangeEvent);
    }

    isAttachedToOperator(operatorId: string): boolean {
        let list = this.attachedOperatorIds.value;
        return list && list.indexOf(operatorId) > -1;
    }

    public TOGGLE_OPERATORS_GROUP_HIDDEN(payload: { groupId: string, value: boolean }) {
        let group = this.operatorsGroups.value[payload.groupId];
        if (group) {
            group.hidden = payload.value;
        }
    }

    public TOGGLE_TAB_HIDDEN(payload: { tabId: string, value: boolean }) {
        let tab = this.tabsDictionary.value[payload.tabId];
        if (tab) {
            tab.visible = payload.value ? 0 : 1;
        }
    }

    initVisitId() {
        let visitId = Random.uid(10);
        //console.log("придумываем новый visitId", visitId)

        StorageInstance.set(VISIT_ID_KEY, visitId);
    }

    initConfiguration(config: Dictionary<ISiteParams>) {
        this.SET_CONFIG(config);

        let defaultStatus = this.siteParams.value.free ? OPERATOR_STATUS_OFFLINE : null;

        for (let operator of this.operators.value) {
            Vue.set(operator, "status", defaultStatus);
        }
    }

    public SET_SETUP(setup: ISetup) {
        this.setup.value = setup;
    }

    private SET_CONFIG(config: Dictionary<ISiteParams>) {
        for (let languageId in config) {
            if (config.hasOwnProperty(languageId)) {
                fixConfig(config[languageId]);
            }
        }
        this.config.value = config;
        ChatEventManagerInstance.emit(ChatConfigReloadEvent);
    }

    get headerBackground() {
        return computed(() => {
            return GradientPickerHelper.getCssBackground(this.design.value.headerBackground);
        })
    }

    get triggerBackground() {
        return computed(() => {
            return GradientPickerHelper.getCssBackground(this.design.value.triggerBackground);
        })
    }
}

export const ConfigStore = new Config();

export const ChatConfigReloadEvent = "chatConfigReloadEvent";
export const ChatDeviceChangeEvent = "chatDeviceChangeEvent";