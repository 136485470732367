import {ChatEventManagerInstance} from "~/chat/ts/ChatEventManager";
import {COBROWSE_STORE_NEW_OPERATOR_EVENT, CobrowseOuterStore} from "~/chat/ts/store/CobrowseOuter";


class Cobrowse {
    constructor() {
        ChatEventManagerInstance.addEventListener(COBROWSE_STORE_NEW_OPERATOR_EVENT, () => {
            this.init();
        });
    }

    public async init() {
        if (CobrowseOuterStore.isCobrowseWorkingNow.value) {
            let CobrowseSender = await (() => import(/* webpackChunkName: "cobrowse" */ '~/chat/ts/service/cobrowse/CobrowseSender'))();
            CobrowseSender.default.sendInitialHTML();
        }
    }
}

const CobrowseInstance = new Cobrowse();

export default CobrowseInstance;

