import AbstractAutoAction, {
    IAutoActionSuccessRunResult
} from "~/chat/ts/service/autoActions/actions/AbstractAutoAction";
import {AutoActionModel} from "~/chat/ts/data/AutoActions";
import {TriggerStore} from "~/chat/ts/store/Trigger";

export default abstract class AbstractToggleTriggerAction extends AbstractAutoAction<AutoActionModel> {
    private reason = "autoAction";

    async run(): Promise<IAutoActionSuccessRunResult[]> {
        this.value ? TriggerStore.showTrigger(this.reason) : TriggerStore.hideTrigger(this.reason);
        return [{}];
    }

    get isNoize(): boolean {
        return false;
    }


    isCanRunNow(): boolean {
        let indexOf = TriggerStore.hideTriggerReasons.value.indexOf(this.reason);
        if (this.value) {
            return indexOf > -1;
        } else {
            return indexOf == -1;
        }
    }

    protected abstract get value(): boolean;
}