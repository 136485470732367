import AbstractAutoAction, {IAutoActionSuccessRunResult} from "~/chat/ts/service/autoActions/actions/AbstractAutoAction";
import {AutoActionModel} from "~/chat/ts/data/AutoActions";
import ConsoleWrapper from "~/ts/library/Console";
import ChatNodeRequest from "~/chat/ts/service/ChatNodeRequest";

export default class Webhook extends AbstractAutoAction<IWebhookParams> {
    async run(): Promise<IAutoActionSuccessRunResult[]> {
        (async () => {
            try {
                await (new ChatNodeRequest("/pushToActivityList"))
                    .setBody(
                        ChatNodeRequest.getAction("sendAutoActionWebhook", {
                            webhook: this.params.webhook
                        })
                    )
                    .send();
            } catch (e) {
                ConsoleWrapper.log("Error on send auto action webhook");
            }
        })();


        return [{}];
    }

    get isNoize(): boolean {
        return true;
    }

    isNeedSendResultToServer(): boolean {
        return true;
    }

    isCanRunNow(): boolean {
        return true;
    }
}

type IWebhookParams = AutoActionModel & {
    webhook: string;
}