import {OperatorsStore} from "~/chat/ts/store/Operators";

export default function getOperatorGroups(callback: (data: any) => void) {
    callback(OperatorsStore.groupList.value.map(group => ({
        id: group.id,
        descr: group.descr,
        isHidden: group.hidden,
        operators: OperatorsStore.getOperatorsOfGroup(group).map(operator => ({
            id: operator.id,
            name: operator.fio
        }))
    })));
}