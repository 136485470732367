import OperationNotEmpty from "~/chat/ts/service/autoActions/operations/OperationNotEmpty";

export default class OperationEmpty extends OperationNotEmpty {
    protected check(filterName: string, filterType: string, currentValue: any, checkValue: any[]): boolean {
        return !super.check(filterName, filterType, currentValue, checkValue);
    }

    get returnOnEmptyFilterValues(): boolean {
        return true;
    }
}