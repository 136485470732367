import AbstractListenerAction from "~/ts/library/listen/AbstractListenerAction";
import Filters from "~/chat/ts/service/autoActions/Filters";
import {ACTION_FILTER_IS_TARGET_CLIENT} from "~/chat/ts/data/AutoActions";

export default class IsTargetChangedListener extends AbstractListenerAction {
    public getAction(): string {
        return "isTargetChanged";
    }

    public onAction(payload: { isTarget: boolean }): void {
        IsTargetChangedListener.setFilter(payload.isTarget);
    }

    public static setFilter(value: boolean) {
        Filters.set(ACTION_FILTER_IS_TARGET_CLIENT, value ? 1 : 0)
    }
}