import {REVIEW_PAGINATION_EXPAND} from "~/chat/ts/Constants";
import InlineContentWatcher from "~/chat/ts/service/InlineContentWatcher";
import Vue from "vue";


export default class ReviewsOnSite extends InlineContentWatcher {
    protected async getComponent(container: HTMLElement): Promise<Vue> {
        let ReviewsList = (await (() => import(/* webpackChunkName: "widget" */ '~/chat/vue/widget/tabs/reviews/ReviewsList.vue'))() as any).default;
        let onPage = parseInt(this.attr(container, "limit", "10"));
        let withReviewsDescription = this.attr(container, "with-reviews-description", undefined);
        let description = this.attr(container, "description", "1") === "1";
        return new Vue({
            ...ReviewsList,
            propsData: {
                allSites: this.attr(container, "all-sites", "0") === "1",
                maxLength: parseInt(this.attr(container, "max-length", "0")),
                onPage: isNaN(onPage) || onPage < 0 || onPage > 100 ? 10 : onPage,
                pagination: this.attr(container, "pagination", "1") === "1",
                paginationType: this.attr(container, "pagination-type", REVIEW_PAGINATION_EXPAND),
                displayName: this.attr(container, "display-names", "1") === "1",
                autoWidth: this.attr(container, "auto-width", "0") === "1",
                displayCity: this.attr(container, "display-city", "1") === "1",
                displayDate: this.attr(container, "display-date", "1") === "1",
                displayRateValue: this.attr(container, "display-rate-value", "1") === "1",
                description: this.attr(container, "description", "1") === "1",
                withReviewsDescription: this.attr(container, "with-reviews-description", undefined),
                withoutReviewsDescription: this.attr(container, "without-reviews-description", undefined),
                inline: true,
                showTotal: !withReviewsDescription && description
            }
        });
    }

    protected get selector(): string {
        return "#onlineSupportReviews";
    }
}