import AbstractAutoAction, {IAutoActionSuccessRunResult} from "~/chat/ts/service/autoActions/actions/AbstractAutoAction";
import SafeEval from "~/ts/library/SafeEval";
import {AutoActionModel} from "~/chat/ts/data/AutoActions";

export default class RunJavascript extends AbstractAutoAction<IRunJavascriptParams> {
    async run(): Promise<IAutoActionSuccessRunResult[]> {
        SafeEval.make(this.params.script);
        return [{}];
    }

    get isNoize(): boolean {
        return true;
    }

    isNeedSendResultToServer(): boolean {
        return true;
    }

    isCanRunNow(): boolean {
        return true;
    }
}

type IRunJavascriptParams = AutoActionModel & {
    script: string
}