//import axios from "axios";
import ChatApiResult from "~/chat/ts/service/request/ChatApiResult";
import {ConfigStore} from "~/chat/ts/store/Config";
import {ClientStore} from "~/chat/ts/store/Client";
import PostRequest from "~/ts/library/xhr/PostRequest";
import {__} from "~/ts/library/Translate";
import ConsoleWrapper from "~/ts/library/Console";
import Random from "~/ts/library/Random";
import {ChatTabStore} from "~/chat/ts/store/ChatTab";
import {WidgetStore} from "~/chat/ts/store/Widget";

interface ChatApiRequestConfig {
    method: string;
    p?: any;

}

export {ChatApiRequestConfig};


export default class ChatApiRequest {
    /*
    private constructor() {

    }


    private static onRequestEnd(options: ChatApiRequestConfig) {

    }

     */

    public static async make(options: ChatApiRequestConfig): Promise<ChatApiResult> {
        let url = ConfigStore.apiHost.value + "/LAPI/me-talk/api.php";
        var p = options.p;
        p.requestId = Random.uid();
        p.orgID = ConfigStore.orgId.value;
        p.siteID = ConfigStore.siteId.value;
        p.talkID = ClientStore.id.value;
        p.instanceId = ConfigStore.instanceId.value;

        try {
            let body = JSON.stringify({
                method: options.method,
                p: p,
                lang: ConfigStore.language.value
            });

            let result = await (new PostRequest(url))
                .setData(body)
                .addHeaders({
                    'Content-Type': 'application/json'
                })
                .send();


            /*
            let result = await axios.post(url, body, {
                responseType: "json",
                headers: {
                    'Content-Type': 'application/json'
                }
            });

             */
            try {
                let data = result.getJsonResponse();
                if (typeof data == "object" && data) {
                    if ((data as any)['needClientCaptcha']) {
                        await WidgetStore.requestClientCaptchaSolve();
                        return await this.make(options);
                    }
                    if (typeof data.info == "object") {
                        ClientStore.update(data.info);
                    }

                    if (data.widgetData) {
                        ChatTabStore.setWidetData(data.widgetData);
                    }
                }
            } catch (e) {

            }

            //ChatApiRequest.onRequestEnd(options);
            return (new ChatApiResult(result.getJsonResponse()));
        } catch (error) {
            ConsoleWrapper.error(error);
            //ChatApiRequest.onRequestEnd(options);
            return new ChatApiResult({
                code: 0,
                descr: __("REQUEST_ERROR")
            })
        }
    }

}

