export class AlreadyInitializedChecker {
    private static getKey(apiMethod: string) {
        return apiMethod + "IsInitialized";
    }

    public static isInitialized(apiMethod: string): boolean {
        return !!(window as any)[this.getKey(apiMethod)];
    }

    public static setInitialized(apiMethod: string, value: boolean) {
        (window as any)[this.getKey(apiMethod)] = value;
    }
}